import React from 'react';
import styles from './style.module.css';
import LayoutDashboard from 'layouts/layout-dashboard-collapse';
import { Pie, Line } from 'react-chartjs-2';
import { Row, Col, Card, Table, ProgressBar } from 'react-bootstrap';

// Fungsi untuk membuat data dummy dengan persentase perubahan dan nilai hari kemarin
const generateData = (currentValue) => {
    const percentageChange = (Math.random() * 20 - 10).toFixed(2); // Persentase acak antara -10% hingga 10%
    const previousValue = (currentValue / (1 + percentageChange / 100)).toFixed(2);
    const isIncrease = percentageChange > 0;
    return {
        percentageChange,
        previousValue,
        isIncrease
    };
};

const DashboardReservation = () => {

    const guestSatisfactionData = {
        labels: ['Available Rooms', 'Sold Rooms', 'Blocked Rooms', 'Complimentary Rooms'],
        datasets: [
            {
                data: [5, 10, 20, 15],
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
            },
        ],
    };

    // Dummy data for charts
    const occupancyRateData = {
        labels: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7', 'Day 8', 'Day 9', 'Day 10'],
        datasets: [
            {
                label: 'Occupancy Rate (%)',
                data: [60, 65, 70, 75, 80, 85, 90, 92, 88, 85],
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                fill: true,
            },
        ],
    };

    // Data dummy untuk berbagai kartu
    const totalRevenue = 12000;       // Total revenue dummy data
    const averageDailyRate = 150;     // Average daily rate dummy data
    const bookingLeadTime = 5;        // Booking lead time dummy data (in days)
    const averageLengthOfStay = 3;    // Average length of stay dummy data (in days)

    // Generate data untuk hari kemarin
    const totalRevenueData = generateData(totalRevenue);
    const averageDailyRateData = generateData(averageDailyRate);
    const bookingLeadTimeData = generateData(bookingLeadTime);
    const averageLengthOfStayData = generateData(averageLengthOfStay);

    return (
        <LayoutDashboard>
            <div className={`container-fluid mt-4`}>
                <div className="row">
                    {/* Left Sidebar */}
                    <div className="col-md-3">
                        <Card className="mb-3 shadow-sm" style={{ borderRadius: '10px', border: 'none' }}>
                            <Card.Body>
                                <Card.Title className="text-muted">Dashboard</Card.Title>
                                <div className="mt-4">
                                    <div className={`${styles.activityCard} text-center`}>
                                        <p className={`mb-0 ${styles.textMuted}`}>Monday</p>
                                        <h2 className={styles.fontWeightBold}>14</h2>
                                        <p className={styles.textMuted}>October</p>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className="mb-3 shadow-sm" style={{ borderRadius: '10px', border: 'none' }}>
                            <Card.Body>
                                <Card.Title className="text-muted">Inventory Statistics</Card.Title>
                                <Pie data={guestSatisfactionData} />
                            </Card.Body>
                        </Card>
                    </div>

                    {/* Main Content */}
                    <div className="col-md-9">
                        <Card className={`mb-3 shadow-sm ${styles.cardTable}`} style={{ borderRadius: '10px', border: 'none' }}>
                            <Card.Body>
                                <Card.Title className="text-muted">Activity</Card.Title>
                                <div className={`${styles.activity} mt-3`}>
                                    <div className="row text-center">
                                        <div className="col-md-2">
                                            <div className={styles.activityCard}>
                                                <h5 className={styles.fontWeightBold}>0</h5>
                                                <p className={styles.textMuted}>Arrivals</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className={styles.activityCard}>
                                                <h5 className={styles.fontWeightBold}>0</h5>
                                                <p className={styles.textMuted}>Departures</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className={styles.activityCard}>
                                                <h5 className={styles.fontWeightBold}>8</h5>
                                                <p className={styles.textMuted}>In-house</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className={styles.activityCard}>
                                                <h5 className={styles.fontWeightBold}>3</h5>
                                                <p className={styles.textMuted}>Stayovers</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className={styles.activityCard}>
                                                <h5 className={styles.fontWeightBold}>0</h5>
                                                <p className={styles.textMuted}>Bookings</p>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className={styles.activityCard}>
                                                <h5 className={styles.fontWeightBold}>0</h5>
                                                <p className={styles.textMuted}>Cancellations</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-5 text-center">
                                    <p>
                                        <i className={`fas fa-check-circle ${styles.textSuccess}`}></i>{" "}
                                        There are no arrivals today
                                    </p>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                <Row>
                    <Col md={3}>
                        <Card className="mb-3 shadow-sm card-metric" style={{ borderRadius: '10px', border: 'none' }}>
                            <Card.Body>
                                <Card.Title className="text-muted">Total Revenue</Card.Title>
                                <h2 className="display-4">${totalRevenue}</h2>
                                <p className={totalRevenueData.isIncrease ? "text-success" : "text-danger"}>
                                    {totalRevenueData.isIncrease ? "+" : ""}{totalRevenueData.percentageChange}%
                                </p>
                                <p className="text-muted">vs yesterday: ${totalRevenueData.previousValue} USD</p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="mb-3 shadow-sm card-metric" style={{ borderRadius: '10px', border: 'none' }}>
                            <Card.Body>
                                <Card.Title className="text-muted">Average Daily Rate</Card.Title>
                                <h2 className="display-4">${averageDailyRate}</h2>
                                <p className={averageDailyRateData.isIncrease ? "text-success" : "text-danger"}>
                                    {averageDailyRateData.isIncrease ? "+" : ""}{averageDailyRateData.percentageChange}%
                                </p>
                                <p className="text-muted">vs yesterday: ${averageDailyRateData.previousValue} USD</p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="mb-3 shadow-sm card-metric" style={{ borderRadius: '10px', border: 'none' }}>
                            <Card.Body>
                                <Card.Title className="text-muted">Booking Lead Time</Card.Title>
                                <h2 className="display-4">{bookingLeadTime} days</h2>
                                <p className={bookingLeadTimeData.isIncrease ? "text-success" : "text-danger"}>
                                    {bookingLeadTimeData.isIncrease ? "+" : ""}{bookingLeadTimeData.percentageChange}%
                                </p>
                                <p className="text-muted">vs yesterday: {bookingLeadTimeData.previousValue} days</p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className="mb-3 shadow-sm card-metric" style={{ borderRadius: '10px', border: 'none' }}>
                            <Card.Body>
                                <Card.Title className="text-muted">Average Length of Stay</Card.Title>
                                <h2 className="display-4">{averageLengthOfStay} days</h2>
                                <p className={averageLengthOfStayData.isIncrease ? "text-success" : "text-danger"}>
                                    {averageLengthOfStayData.isIncrease ? "+" : ""}{averageLengthOfStayData.percentageChange}%
                                </p>
                                <p className="text-muted">vs yesterday: {averageLengthOfStayData.previousValue} days</p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <div className="row">
                    <Col md={12}>
                        <Card className="mb-3 shadow-sm" style={{ borderRadius: '10px', border: 'none' }}>
                            <Card.Body>
                                <Card.Title className="text-muted">Occupancy Rate</Card.Title>
                                <Line data={occupancyRateData} />
                            </Card.Body>
                        </Card>
                    </Col>
                </div>
            </div>
        </LayoutDashboard>
    );
};

export default DashboardReservation;
