import { useEffect, useState } from 'react';
import { Navbar, Nav, Button, Offcanvas, Dropdown, Form, FormControl, OverlayTrigger, Tooltip } from 'react-bootstrap';
import './sidebar.css';
import { Link, useNavigate } from 'react-router-dom';
import { NAV_LINKS, URLS } from 'utils/constants';
import { IconMenu2, IconX, IconLogout, IconBell, IconArrowDown, IconChevronDown } from '@tabler/icons-react';
import { useAuth } from 'hooks/useAuth';
import { IconCurrencyDollar, IconCurrencyEuro, IconCurrencyPound } from '@tabler/icons-react'; // Import additional Tabler icons
import { IconCalendar, IconPlus, IconClipboardList } from '@tabler/icons-react'; // Import Tabler icons for calendar and clipboard list
import { useCanvasHandler } from 'hooks/useCanvasHandler';
import CreateReservationPage from 'pages/reservation/reservation/components/create-reservation-page';
import SearchReservations from './search-reservation';
import { LogoURL } from 'components/logo';
import { useCreateData } from 'hooks/useApiHooks';
import { useReload } from 'contexts/ReloadContext';
import { useSubmitHandler } from 'hooks/useSubmitHandler';
import Swal from 'sweetalert2';
import Select from 'react-select';

// SidebarLink component
const SidebarLink = ({ link, index }) => {
    return (
        <div className={`sidebar-section ${index !== 0 ? 'mt-3' : ''}`}>
            <Nav.Item>
                <div className="section-title">{link.name}</div>
                {link.child ? (
                    link.child.map((child, index) => (
                        <Link key={index} to={child.href} className="nav-link d-flex align-items-center">
                            <span className="icon-circle">
                                <child.icon />
                            </span>
                            <span className="ml-2">{child.name}</span>
                        </Link>
                    ))
                ) : (
                    <Link to={link.href} className="nav-link d-flex align-items-center">
                        <span className="icon-circle">
                            <link.icon />
                        </span>
                        <span className="ml-2">{link.name}</span>
                    </Link>
                )}
            </Nav.Item>
        </div>
    );
};

const customStyles = {
    control: (provided) => ({
        ...provided,
        backgroundColor: '#FFFFFF', // Control background color
        border: 'none', // Remove border
        boxShadow: 'none', // Remove shadow on focus
        '&:hover': {
            border: 'none', // Ensure no border on hover
        },
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: '#495057', // Dropdown indicator color
    }),
    indicatorSeparator: () => ({
        display: 'none', // Hide the separator next to the dropdown indicator
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#007bff' : state.isFocused ? '#e9ecef' : null,
        color: state.isSelected ? 'white' : '#495057',
        padding: 10,
        '&:hover': {
            backgroundColor: '#e9ecef',
            color: '#007bff',
        },
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#6c757d',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#495057',
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 999,
    }),
};

const PropertySelect = () => {
    const { user } = useAuth();
    const [property, setProperty] = useState({ value: user?.active_property_id, label: user?.active_property });
    const navigate = useNavigate();

    const { createData: changeProperty } = useCreateData('/change-property');

    const { handleSuccess, handleError, showCanvasHandler } = useCanvasHandler();

    const handlePropertySelect = async (property) => {
        const result = await changeProperty({ property_id: property.value });

        if (result.success) {
            try {
                // Tampilkan SweetAlert dengan pesan sukses
                const result = await Swal.fire({
                    icon: 'success',
                    title: 'Property changed successfully',
                    text: 'The property has been updated successfully.',
                    confirmButtonText: 'OK',
                });

                // Jika pengguna menekan tombol OK, lakukan navigasi
                if (result.isConfirmed) {
                    navigate(0); // Refresh halaman
                }
            } catch (error) {
                handleError(true, 'Failed to change property');
            }
        } else {
            handleError(true, 'Failed to change property')
        }
    };

    // useEffect(() => {
    //     setProperty({
    //         value: user?.active_property,
    //         label: user?.active_property_id,
    //     })
    // }, [user]);

    return (
        <>
            <div className='d-flex align-items-center' style={{ width: '250px' }}>
                <img src={LogoURL} alt={user?.active_property} className="rounded-circle me-2" style={{ width: '30px', height: '30px' }} />
                <Select
                    styles={customStyles}
                    value={property}
                    options={Object.entries(user?.properties).map(([id, name]) => ({ value: id, label: name }))}
                    onChange={(e) => handlePropertySelect(e, e.value)}
                    className="w-full border-0"
                />
            </div>
        </>
    );
};

// Sidebar component
const Sidebar = ({ children }) => {
    const [show, setShow] = useState(false);
    const { logout, user } = useAuth();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleLogout = () => logout();

    const { handleSuccess, handleError, showCanvasHandler } = useCanvasHandler();

    const handleCreateReservation = (e) => {
        e.preventDefault();
        showCanvasHandler('Create Reservation',
            <CreateReservationPage
                onSuccess={handleSuccess}
                onError={handleError}
            />
        );
    };

    return (
        <>
            <Navbar bg="white" variant="dark" className="px-3 d-flex justify-content-between align-items-center border-bottom-0" style={{ boxShadow: '0 1px 3px 0 rgba(0,0,0,.1), 0 1px 2px -1px rgba(0,0,0,.1)' }}>
                <button className="btn border-0 shadow-none ps-0" onClick={handleShow}>
                    <IconMenu2 size={24} className='text-primary' />
                </button>

                <PropertySelect user={user} />

                <Form className="d-flex ms-3 me-1 align-items-center" style={{ flex: 1 }}>
                    {/* Search input */}
                    {/* <FormControl
                        type="search"
                        placeholder="Search reservation"
                        className="me-2"
                        aria-label="Search reservation"
                    /> */}

                    <SearchReservations />

                    {/* Primary action button with tooltip */}
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="tooltip-create-reservation">Create Reservation</Tooltip>}
                    >
                        <Button variant="primary" className="rounded-circle mx-2 p-2" onClick={handleCreateReservation}>
                            <IconPlus size={18} />
                        </Button>
                    </OverlayTrigger>

                    {/* Icons for reservation and calendar with tooltips */}
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="tooltip-reservation-list">Reservation List</Tooltip>}
                    >
                        <Link to={URLS.DASHBOARD_ADMIN_RESERVATION} className="me-2 p-2 btn btn-outline-primary">
                            <IconClipboardList size={18} />
                        </Link>
                    </OverlayTrigger>

                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="tooltip-calendar">Reservation Calendar</Tooltip>}
                    >
                        <Link to={URLS.DASHBOARD_ADMIN_RESERVATION_CALENDAR} className="me-2 p-2 btn btn-outline-primary">
                            <IconCalendar size={18} />
                        </Link>
                    </OverlayTrigger>
                </Form>


                <Nav className="d-flex align-items-center">
                    {/* Left border before the language dropdown */}
                    <div style={{ borderLeft: '1px solid #ccc', height: '40px', margin: '0 10px' }}></div>
                    {/* Language Dropdown with Flags */}
                    {/* <Dropdown className="p-0">
                        <Dropdown.Toggle variant="light" id="dropdown-language" className="d-flex align-items-center px-3">
                            <img src="https://flagcdn.com/us.svg" alt="English" style={{ width: '24px', height: '16px', marginRight: '5px' }} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item>
                                <img src="https://flagcdn.com/us.svg" alt="English" style={{ width: '24px', height: '16px', marginRight: '5px' }} />
                                English
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <img src="https://flagcdn.com/fr.svg" alt="French" style={{ width: '24px', height: '16px', marginRight: '5px' }} />
                                Français
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <img src="https://flagcdn.com/es.svg" alt="Spanish" style={{ width: '24px', height: '16px', marginRight: '5px' }} />
                                Español
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown> */}

                    {/* Currency Dropdown with Icons */}
                    {/* <Dropdown className="p-0">
                        <Dropdown.Toggle variant="light" id="dropdown-currency" className="d-flex align-items-center p-0">
                            USD
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item>
                                <IconCurrencyDollar className="me-1" />
                                USD
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <IconCurrencyEuro className="me-1" />
                                EUR
                            </Dropdown.Item>
                            <Dropdown.Item>
                                <IconCurrencyPound className="me-1" />
                                GBP
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown> */}

                    <Dropdown className="p-0 mx-3">
                        <Dropdown.Toggle variant="light" id="dropdown-user" className="d-flex align-items-center p-0">
                            <img src={user.avatar || 'https://i.pravatar.cc/150?img=1'} alt={user.name} className="rounded-circle me-2" style={{ width: '30px', height: '30px' }} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu align="end">
                            <Dropdown.Item as={Link} to="/profile">Profile</Dropdown.Item>
                            <Dropdown.Item as={Link} to="/change-password">Change Password</Dropdown.Item>
                            <Dropdown.Item as={Link} to="/my-property">My Property</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item as={Link} onClick={handleLogout}>Logout</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Nav>
            </Navbar>

            <Offcanvas show={show} onHide={handleClose} placement="start" className="sidebar-canvas">
                <Offcanvas.Header className='d-flex justify-content-between'>
                    <div className="d-flex lh-1 text-reset p-0">
                        <img src={user.avatar || 'https://via.placeholder.com/150x150.png?text=JD'} alt={user.name} className="rounded-circle" style={{ width: '30px', height: '30px' }} />
                        <div className="d-block ps-2">
                            <div>{user?.name}</div>
                            <div className="mt-1 small text-secondary">{user?.email}</div>
                        </div>
                    </div>
                    <a href='#'>
                        <IconX size={24} onClick={handleClose} className='text-primary' />
                    </a>
                </Offcanvas.Header>

                <Offcanvas.Body>
                    <Nav className="flex-column">
                        {NAV_LINKS.map((link, index) => (
                            <SidebarLink key={index} link={link} index={index} />
                        ))}
                    </Nav>
                </Offcanvas.Body>
            </Offcanvas>

            <main>{children}</main>
        </>
    );
};

export default Sidebar;
