import React from 'react';
import { Card } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';

const RevenueOverviewChart = ({ data }) => (
  <Card className="mb-3 shadow-sm flex-fill" style={{ borderRadius: '10px', border: 'none' }}>
    <Card.Body>
      <Card.Title className="text-muted">Revenue Overview</Card.Title>
      <Bar data={data} />
    </Card.Body>
  </Card>
);

export default RevenueOverviewChart;
