import React from "react";
import { Dropdown } from "react-bootstrap";
import LayoutDashboard from "layouts/layout-dashboard-collapse";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'; // Import react content support for SweetAlert2

// Custom Swal with react component support
const MySwal = withReactContent(Swal);

// Dummy data for reservations
const fetchData = {
    data: [
        {
            room_type: 'Single Room',
            child: [
                {
                    room_name: "Room 101",
                    room_condition: "Good",
                    room_status: "Available",
                    checkin_today: "Yes",
                    arrival_time: "14:00",
                    arrival_date: "2024-10-14",
                    departure_date: "2024-10-16",
                    frontdesk_status: "Checked In",
                    user: "John Doe",
                },
                {
                    room_name: "Room 102",
                    room_condition: "Fair",
                    room_status: "Occupied",
                    checkin_today: "No",
                    arrival_time: "12:30",
                    arrival_date: "2024-10-13",
                    departure_date: "2024-10-15",
                    frontdesk_status: "Checked In",
                    user: "Jane Smith",
                },
                {
                    room_name: "Room 103",
                    room_condition: "Excellent",
                    room_status: "Checked Out",
                    checkin_today: "No",
                    arrival_time: "10:00",
                    arrival_date: "2024-10-12",
                    departure_date: "2024-10-14",
                    frontdesk_status: "Checked Out",
                    user: "Alice Johnson",
                },
                {
                    room_name: "Room 104",
                    room_condition: "Poor",
                    room_status: "Under Maintenance",
                    checkin_today: "No",
                    arrival_time: "N/A",
                    arrival_date: "N/A",
                    departure_date: "N/A",
                    frontdesk_status: "N/A",
                    user: "Bob Brown",
                },
                {
                    room_name: "Room 105",
                    room_condition: "Good",
                    room_status: "Occupied",
                    checkin_today: "Yes",
                    arrival_time: "15:30",
                    arrival_date: "2024-10-10",
                    departure_date: "2024-10-12",
                    frontdesk_status: "Checked In",
                    user: "Charlie Davis",
                },
                {
                    room_name: "Room 106",
                    room_condition: "Fair",
                    room_status: "Available",
                    checkin_today: "No",
                    arrival_time: "N/A",
                    arrival_date: "N/A",
                    departure_date: "N/A",
                    frontdesk_status: "N/A",
                    user: "Emily Clark",
                },
                {
                    room_name: "Room 107",
                    room_condition: "Excellent",
                    room_status: "Checked Out",
                    checkin_today: "No",
                    arrival_time: "11:00",
                    arrival_date: "2024-10-08",
                    departure_date: "2024-10-10",
                    frontdesk_status: "Checked Out",
                    user: "David Wilson",
                },
            ]
        },
        {
            room_type: 'Double Room',
            child: [
                {
                    room_name: "Room 101",
                    room_condition: "Good",
                    room_status: "Available",
                    checkin_today: "Yes",
                    arrival_time: "14:00",
                    arrival_date: "2024-10-14",
                    departure_date: "2024-10-16",
                    frontdesk_status: "Checked In",
                    user: "John Doe",
                },
                {
                    room_name: "Room 102",
                    room_condition: "Fair",
                    room_status: "Occupied",
                    checkin_today: "No",
                    arrival_time: "12:30",
                    arrival_date: "2024-10-13",
                    departure_date: "2024-10-15",
                    frontdesk_status: "Checked In",
                    user: "Jane Smith",
                },
                {
                    room_name: "Room 103",
                    room_condition: "Excellent",
                    room_status: "Checked Out",
                    checkin_today: "No",
                    arrival_time: "10:00",
                    arrival_date: "2024-10-12",
                    departure_date: "2024-10-14",
                    frontdesk_status: "Checked Out",
                    user: "Alice Johnson",
                },
                {
                    room_name: "Room 104",
                    room_condition: "Poor",
                    room_status: "Under Maintenance",
                    checkin_today: "No",
                    arrival_time: "N/A",
                    arrival_date: "N/A",
                    departure_date: "N/A",
                    frontdesk_status: "N/A",
                    user: "Bob Brown",
                },
                {
                    room_name: "Room 105",
                    room_condition: "Good",
                    room_status: "Occupied",
                    checkin_today: "Yes",
                    arrival_time: "15:30",
                    arrival_date: "2024-10-10",
                    departure_date: "2024-10-12",
                    frontdesk_status: "Checked In",
                    user: "Charlie Davis",
                },
                {
                    room_name: "Room 106",
                    room_condition: "Fair",
                    room_status: "Available",
                    checkin_today: "No",
                    arrival_time: "N/A",
                    arrival_date: "N/A",
                    departure_date: "N/A",
                    frontdesk_status: "N/A",
                    user: "Emily Clark",
                },
                {
                    room_name: "Room 107",
                    room_condition: "Excellent",
                    room_status: "Checked Out",
                    checkin_today: "No",
                    arrival_time: "11:00",
                    arrival_date: "2024-10-08",
                    departure_date: "2024-10-10",
                    frontdesk_status: "Checked Out",
                    user: "David Wilson",
                },
            ]
        },
        {
            room_type: 'Suite Room',
            child: []
        }
    ],
};

const ReservationPage = () => {
    return (
        <LayoutDashboard>
            <div className="container-fluid mt-5">
                <div className="table-container">
                    <div className="search-container mb-3">
                        {/* Search input */}
                        <div className="input-icon">
                            <input type="text" className="form-control" placeholder="Search..." />
                            <span className="input-icon-addon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon icon-tabler icon-tabler-search"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <circle cx="10" cy="10" r="7" />
                                    <line x1="21" y1="21" x2="15" y2="15" />
                                </svg>
                            </span>
                        </div>

                        {/* Create button */}
                        <button className="btn btn-primary">Create</button>
                    </div>

                    {/* Table */}
                    <div className="table">
                        <table className="table table-hover table-vcenter">
                            <thead>
                                <tr>
                                    <th>Room Name</th>
                                    <th>Room Condition</th>
                                    <th>Room Status</th>
                                    <th>Checkin Today</th>
                                    <th>Arrival Time</th>
                                    <th>Arrival Date</th>
                                    <th>Departure Date</th>
                                    <th>Frontdesk Status</th>
                                    <th>User</th>
                                    <th className="text-end">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {fetchData.data.map((housekeeping, index) => (
                                    <React.Fragment key={index}>
                                        <tr>
                                            <td colSpan="10" className="text-center text-primary">
                                                {housekeeping.room_type}
                                            </td>
                                        </tr>
                                        {housekeeping.child.length > 0 && housekeeping.child.map((room, index) => (
                                            <tr key={index}>
                                                <td>{room.room_name}</td>
                                                <td>{room.room_condition}</td>
                                                <td>{room.room_status}</td>
                                                <td>{room.checkin_today}</td>
                                                <td>{room.arrival_time}</td>
                                                <td>{room.arrival_date}</td>
                                                <td>{room.departure_date}</td>
                                                <td>{room.frontdesk_status}</td>
                                                <td>{room.user}</td>
                                                <td className="text-end">
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="default" id="dropdownMenuButton1">
                                                            Actions
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item href="#">View</Dropdown.Item>
                                                            <Dropdown.Item href="#">Edit</Dropdown.Item>
                                                            <Dropdown.Item href="#">
                                                                Remove
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {/* Pagination */}
                    <div className="pagination-container">
                        <nav aria-label="Page navigation">
                            <ul className="pagination">
                                <li className="page-item active">
                                    <a className="page-link bg-primary" href="#">
                                        1
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </LayoutDashboard>
    );
};

export default ReservationPage;
