import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import styles from './AgentReport.module.css'; // Modular CSS untuk styling
import ReportsPage from '../../reports-page';

const AgentReport = () => {
    // State untuk properti dan tipe kamar yang dipilih
    const [selectedProperty, setSelectedProperty] = useState('VIENNA VILLAS SEMINYAK');
    const [selectedRooms, setSelectedRooms] = useState({
        'Vienna Villa 2BR': true,
        'Vienna Villa 4BR': true,
    });
    // State untuk mengelola pilihan "Based On"
    const [basedOn, setBasedOn] = useState('stay-dates');

    // Dummy data untuk laporan bulanan per agent
    const reportData = [
        { agent: 'Booking.com', january: 25, february: 20, march: 30, april: 22, may: 18, june: 28, july: 35, august: 29, september: 27, october: 30, november: 26, december: 32 },
        { agent: 'Airbnb', january: 15, february: 10, march: 12, april: 20, may: 14, june: 16, july: 18, august: 25, september: 23, october: 20, november: 22, december: 18 },
        { agent: 'Freelance', january: 10, february: 5, march: 8, april: 12, may: 9, june: 14, july: 15, august: 17, september: 16, october: 12, november: 14, december: 10 },
        { agent: 'Travel Agent', january: 12, february: 14, march: 20, april: 18, may: 22, june: 19, july: 24, august: 20, september: 18, october: 15, november: 17, december: 21 },
        { agent: 'Direct Booking', january: 18, february: 17, march: 22, april: 21, may: 19, june: 23, july: 25, august: 28, september: 26, october: 22, november: 24, december: 27 }
    ];

    // State untuk data yang ditampilkan (sesuai filter)
    const [filteredData, setFilteredData] = useState(reportData);

    // Fungsi untuk handle perubahan property
    const handlePropertyChange = (event) => {
        setSelectedProperty(event.target.value);
        filterData(event.target.value, selectedRooms);
    };

    // Fungsi untuk handle perubahan tipe kamar
    const handleRoomChange = (roomType) => {
        const updatedRooms = { ...selectedRooms, [roomType]: !selectedRooms[roomType] };
        setSelectedRooms(updatedRooms);
        filterData(selectedProperty, updatedRooms);
    };

    // Fungsi untuk filter data berdasarkan properti dan tipe kamar
    const filterData = (property, rooms) => {
        const filtered = reportData.filter(item => {
            return item.property === property && rooms[item.roomType];
        });
        setFilteredData(filtered);
    };

    // Fungsi Dummy untuk ekspor PDF
    const handleExportPDF = () => {
        alert('Laporan telah diekspor dalam format PDF!');
    };

    // Fungsi Dummy untuk ekspor Excel
    const handleExportExcel = () => {
        alert('Laporan telah diekspor dalam format Excel!');
        const header = ["Agent Name", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const rows = reportData.map(row => [
            row.agent,
            row.january,
            row.february,
            row.march,
            row.april,
            row.may,
            row.june,
            row.july,
            row.august,
            row.september,
            row.october,
            row.november,
            row.december
        ]);

        // Membuat CSV
        let csvContent = "data:text/csv;charset=utf-8,"
            + [header, ...rows].map(e => e.join(",")).join("\n");

        // Mengkonversi CSV ke URL
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "occupancy_report.csv");
        document.body.appendChild(link); // Diperlukan untuk Firefox

        link.click(); // Mengklik link untuk mengunduh file
    };

    // Handle perubahan pada radio button
    const handleBasedOnChange = (e) => {
        setBasedOn(e.target.value);
    };

    return (
        <ReportsPage>
            <div className={styles.container}>
                {/* Bagian Filter */}
                <div className="row mb-4 align-items-center">
                    <div className="col-md-4">
                        <label htmlFor="select-property" className="form-label">Select Property:</label>
                        <select className="form-select" id="select-property" value={selectedProperty} onChange={handlePropertyChange}>
                            <option value="VIENNA VILLAS SEMINYAK">VIENNA VILLAS SEMINYAK</option>
                            {/* Tambahkan properti lainnya jika perlu */}
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="select-year" className="form-label">Select Year:</label>
                        <select className="form-select" id="select-year">
                            <option>2024</option>
                            {/* Tambahkan tahun lainnya jika perlu */}
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="date-range" className="form-label">Select Range:</label>
                        <div className="d-flex">
                            <input type="date" className="form-control me-2" id="start-date" value="2024-09-14" />
                            <span className="mx-2">to</span>
                            <input type="date" className="form-control" id="end-date" value="2024-10-14" />
                        </div>
                    </div>
                </div>

                <div className="mb-4">
                    <label className="form-label me-2">Report Based On:</label>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            id="stay-dates"
                            value="stay-dates"
                            checked={basedOn === 'stay-dates'}
                            onChange={handleBasedOnChange}
                        />
                        <label className="form-check-label" htmlFor="stay-dates">
                            Stay Dates
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            id="created-reservation"
                            value="created-reservation"
                            checked={basedOn === 'created-reservation'}
                            onChange={handleBasedOnChange}
                        />
                        <label className="form-check-label" htmlFor="created-reservation">
                            Created Reservation
                        </label>
                    </div>
                </div>

                {/* Pilihan Tipe Kamar */}
                <div className="row mb-4">
                    <div className="col-md-6">
                        <label className="form-label">Select Room Types:</label>
                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="villa-2br"
                                checked={selectedRooms['Vienna Villa 2BR']}
                                onChange={() => handleRoomChange('Vienna Villa 2BR')}
                            />
                            <label className="form-check-label" htmlFor="villa-2br">Vienna Villa 2BR</label>
                        </div>
                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="villa-4br"
                                checked={selectedRooms['Vienna Villa 4BR']}
                                onChange={() => handleRoomChange('Vienna Villa 4BR')}
                            />
                            <label className="form-check-label" htmlFor="villa-4br">Vienna Villa 4BR</label>
                        </div>
                    </div>
                    <div className="col-md-6 text-end">
                        <button className="btn btn-primary me-2" onClick={handleExportPDF}>Export to PDF</button>
                        <button className="btn btn-success" onClick={handleExportExcel}>Export to Excel</button>
                    </div>
                </div>

                {/* Bagian Tabel */}
                <div className={`${styles.card} bg-white`}>
                    <h6 className={styles.moduleHeader}>Agent Report</h6>
                    <div className="table-responsive">
                        <Table bordered hover className="text-center">
                            <thead>
                                <tr>
                                    <th>Agent Name</th>
                                    <th>January</th>
                                    <th>February</th>
                                    <th>March</th>
                                    <th>April</th>
                                    <th>May</th>
                                    <th>June</th>
                                    <th>July</th>
                                    <th>August</th>
                                    <th>September</th>
                                    <th>October</th>
                                    <th>November</th>
                                    <th>December</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reportData.map((row, index) => (
                                    <tr key={index}>
                                        <td>{row.agent}</td>
                                        <td>{row.january}</td>
                                        <td>{row.february}</td>
                                        <td>{row.march}</td>
                                        <td>{row.april}</td>
                                        <td>{row.may}</td>
                                        <td>{row.june}</td>
                                        <td>{row.july}</td>
                                        <td>{row.august}</td>
                                        <td>{row.september}</td>
                                        <td>{row.october}</td>
                                        <td>{row.november}</td>
                                        <td>{row.december}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </ReportsPage>
    );
};

export default AgentReport;
