import { useEffect, useState } from "react";
import Loader from "components/loader/loader";
import Suspense from "components/suspense/suspense";
import { useLoading } from "contexts/LoadingContext";
import { useReload } from "contexts/ReloadContext";
import { useFetchData } from "hooks/useApiHooks";

const UserPage = ({ initialData, isDisabled, handlePermissionChange }) => {
  const { reload } = useReload();
  const { data: fetchData } = useFetchData("/user", reload);
  const { isLoading } = useLoading();

  const [checkedPermissions, setCheckedPermissions] = useState([]);
  console.log("Data initialData:", initialData);

  // Initialize checked permissions from initialData
  useEffect(() => {
    if (initialData) {
      const initialPermissions = initialData.map((permission) => permission.id);
      setCheckedPermissions(initialPermissions);
      handlePermissionChange(initialPermissions); // Notify parent of initial selections
    }
  }, [initialData, handlePermissionChange]);

  const onPermissionChange = (e) => {
    const { value, checked } = e.target;

    setCheckedPermissions((prevPermissions) => {
      const updatedPermissions = checked
        ? [...prevPermissions, value]
        : prevPermissions.filter((permission) => permission !== value);

      handlePermissionChange(updatedPermissions); // Notify parent of changes
      return updatedPermissions;
    });
  };

  return (
    <Suspense loading={isLoading} loader={<Loader />}>
      <div className="card mb-3">
        <div className="card-body">
          <table className="table">
            <thead>
              <tr>
                <th className="w-1">No</th>
                <th>User</th>
                <th>Assignment</th>
              </tr>
            </thead>
            <tbody>
              {fetchData?.data?.map((user, index) => (
                <tr key={user.id}>
                  <td>{index + 1}</td>
                  <td>{user.name}</td>
                  <td>
                    {Array.isArray(checkedPermissions) ? (
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name={user.name}
                          value={user.id}
                          checked={checkedPermissions.includes(user.id)} // Check if user ID is in checkedPermissions
                          disabled={!isDisabled}
                          onChange={onPermissionChange}
                        />
                        {/* <label className="form-check-label">{user.name}</label> */}
                      </div>
                    ) : (
                      <span>Data tidak tersedia</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Suspense>
  );
};

export default UserPage;
