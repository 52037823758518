import React, { useEffect, useState } from 'react';
import { useReload } from 'contexts/ReloadContext';

const SearchComponent = ({ defaultValue = '', onFilter }) => {
    const [search, setSearch] = useState(defaultValue);

    const { triggerReload } = useReload();

    // Run the search function when Enter is pressed
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            onFilter((prev) => ({ ...prev, search: search }));
            triggerReload();
        }
    };

    // Update the search state when defaultValue changes
    useEffect(() => {
        setSearch(defaultValue);
    }, [defaultValue]);

    return (
        <>
            {/* Search input */}
            <div className="input-icon">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <span className="input-icon-addon">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-search"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <circle cx="10" cy="10" r="7" />
                        <line x1="21" y1="21" x2="15" y2="15" />
                    </svg>
                </span>
            </div>
        </>
    );
};

export default SearchComponent;