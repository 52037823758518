import { URLS } from "utils/constants";
import { useState, useMemo } from "react";

// Context
import { useModal } from "contexts/modal-context";
import { useToast } from "contexts/toast-context";

// komponen
import LayoutDashboard from "layouts/layout-dashboard-collapse";
// import CalendarBooking from "pages/reservation/calendar/components/calendar-booking";
import CalendarBooking from "./components/calendar-booking-2";
import Suspense from 'components/suspense/suspense';
import { useReload } from "contexts/ReloadContext";
import { useFetchData } from "hooks/useApiHooks";
import { useLoading } from "contexts/LoadingContext";
import FullLoader from "components/loader/full-loader";
import { convertDateToYYYYMMDD, formatToISO8601, get31DaysFromToday, get3DaysFromToday } from "utils/date-utils";

// get dummy data
const getDataFromLocalStorage = (key) => {
    try {
        const data = localStorage.getItem(key);
        return data ? JSON.parse(data) : [];
    } catch (error) {
        console.error('Error getting data from localStorage:', error);
        return [];
    }
};

const CalendarPage = () => {
    const { reload, triggerReload } = useReload();
    const [todayDate, setTodayDate] = useState(get3DaysFromToday());
    const [lastDate, setLastDate] = useState(get31DaysFromToday());
    const [calendarSettings, setCalendarSettings] = useState({
        unsigned: false,
    });

    const { data: RoomsResource, loading: loadingRooms } = useFetchData(`/room-resource?start_date=${todayDate}&end_date=${lastDate}`, reload);
    const { data: frontOfficeData } = useFetchData(`/booking-resource?start_date=${todayDate}&end_date=${lastDate}`, reload);

    const { isLoading } = useLoading();

    // dummy data
    // const frontOfficeData = getDataFromLocalStorage('frontOfficeData');
    // console.log('RoomsResource', formatToISO8601(today));
    // console.log('frontOfficeData', frontOfficeData);

    // Memoize resourcesPayload
    const resourcesPayload = useMemo(() => {
        return RoomsResource?.data?.map((room, index) => {
            const mapResource = {
                id: room.id.toString(),
                title: room.title,
                children: [
                    ...room.children,
                    ...(calendarSettings.unsigned ? [{ id: `unsigned ${index}`, title: 'Unsigned Rooms' }] : [])
                ].filter(Boolean)
            }
            return mapResource;
        }) || []; // Beri default array kosong jika RoomsResource undefined
    }, [RoomsResource]);

    // Memoize eventsPayload
    const eventsPayload = useMemo(() => {
        return [
            ...frontOfficeData?.data?.map(book => ({
                ...book,
                start: formatToISO8601(book.start),
                end: formatToISO8601(book.end),
            })) || [], // Tambahkan data frontOfficeData jika ada
            ...(RoomsResource?.data?.flatMap(room =>
                room.available?.map(avail => ({
                    resourceId: room.id.toString(),
                    title: avail.available,
                    start: avail.date,
                    end: avail.date,
                    isRoom: true,
                    bgColor: '#67c17b',
                })) || []
            ) || []) // Fallback jika RoomsResource atau room.available undefined
        ];
    }, [RoomsResource, frontOfficeData]);

    const occupancyPayload = useMemo(() => {
        const occupancyEvents = RoomsResource?.occupancy?.map(occupancy => {
            const mappedOccupancy = {
                occupancy: occupancy.occupancy,
                date: occupancy.date,
            }
            return mappedOccupancy;
        }) || [];
        return occupancyEvents;
    }, [RoomsResource]);

    // Fungsi untuk menambahkan tab baru saat event di FullCalendar diklik
    const handleEventClick = (eventData) => {
        alert(eventData ? eventData.title : 'No event data');
    };

    // Fungsi untuk kembali ke hari ini
    const handleBackToToday = () => {
        // make function to get today
        setTodayDate(get3DaysFromToday());
        setLastDate(get31DaysFromToday());
        triggerReload();
    };

    // Fungsi untuk menampilkan bulan sebelumnya
    const handlePrevMonth = () => {
        // make function to get previous month from today
        const today = new Date(todayDate);
        today.setMonth(today.getMonth() - 1);
        setTodayDate(convertDateToYYYYMMDD(today));
        const last = new Date(lastDate);
        last.setMonth(last.getMonth() - 1);
        setLastDate(convertDateToYYYYMMDD(last));
        triggerReload();
    };

    // Fungsi untuk menampilkan bulan berikutnya
    const handleNextMonth = () => {
        // make function to get next month from today
        const today = new Date(todayDate);
        today.setMonth(today.getMonth() + 1);
        setTodayDate(convertDateToYYYYMMDD(today));
        const last = new Date(lastDate);
        last.setMonth(last.getMonth() + 1);
        setLastDate(convertDateToYYYYMMDD(last));
        triggerReload();
    };

    return (
        <Suspense loader={<FullLoader />} loading={isLoading}>
            <LayoutDashboard>
                <div className="page">
                    <div className="page-body mt-0 vh-100">
                        <div className="container-fluid px-0">
                            {!calendarSettings.unsigned && (
                                <div class="alert alert-warning mx-2 my-2 border-0 bg-transparent shadow-none col-5">
                                    <div class="d-flex">
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon alert-icon"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 9v4"></path><path d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z"></path><path d="M12 16h.01"></path></svg>
                                        </div>
                                        <div>
                                            Hello user, you have a reservation need to assign.
                                        </div>
                                        <button className="badge badge-outline text-orange ms-2 cursor-pointer" onClick={(e) => {
                                            setCalendarSettings((prev) => ({ ...prev, unsigned: true }));
                                            triggerReload();
                                        }}>Assign</button>
                                    </div>
                                </div>
                            )}
                            <CalendarBooking
                                resourcesPayload={resourcesPayload}
                                eventsPayload={eventsPayload}
                                onEventClick={handleEventClick}
                                firstDay={todayDate}
                                lastDay={lastDate}
                                moveNext={handleNextMonth}
                                moveBack={handlePrevMonth}
                                resetToday={handleBackToToday}
                                calendarSettings={calendarSettings}
                                setCalendarSettings={setCalendarSettings}
                                occupancyPayload={occupancyPayload}
                            />
                        </div>
                    </div>
                </div>
            </LayoutDashboard>
        </Suspense>
    );
};

export default CalendarPage;