// Modules
import { URLS } from 'utils/constants';
import { createBrowserRouter } from "react-router-dom";

// Middlewares
import ProtectedRoute from 'middleware/ProtectedRoute';

// Components
import { authRouter } from './auth';
import HomePage from 'pages/dashboard/dashboard-pms';
import NotFound from 'components/not-found';
import UnauthorizedPage from 'components/redirect/unauthorized-page';

// Profil
import ProfilePage from 'pages/profile/profile-page';
import ChangePasswordPage from 'pages/change-password/change-password-page';
import MyPropertyPage from 'pages/my-property/my-property-page';

// Contexts Providers
import { AuthProvider } from 'contexts/AuthContext';
import { CanvasProvider } from 'contexts/canvas-context';
import { LoadingProvider } from "contexts/LoadingContext";
import { ReloadProvider } from "contexts/ReloadContext";


// Routes
import { userSettingsRouter } from './core/user-settings';
import PermissionMiddleware from 'middleware/PermissionMiddleware';
import { reservationRouter } from './core/reservations';
import { propertySettings } from './core/property-settings';
import { channelManagerRouter } from './core/channel-manager';


export const router = createBrowserRouter([
  {
    path: URLS.DASHBOARD,
    element: (
      <AuthProvider>
        <LoadingProvider>
          <ReloadProvider>
            <CanvasProvider>
              <ProtectedRoute />
            </CanvasProvider>
          </ReloadProvider>
        </LoadingProvider>
      </AuthProvider >
    ),
    children: [
      {
        path: URLS.DASHBOARD,
        element: <HomePage />,
        children: []
      },
      {
        path: URLS.DASHBOARD_PROFILE,
        element: <ProfilePage />,
      },
      {
        path: URLS.DASHBOARD_CHANGE_PASSWORD,
        element: <ChangePasswordPage />,
      },
      {
        path: URLS.DASHBOARD_MY_PROPERTY,
        element: <MyPropertyPage />,
      },
      ...channelManagerRouter,
      ...reservationRouter,
      ...propertySettings,
      ...userSettingsRouter
    ],
    errorElement: <NotFound />,
  },
  ...authRouter,
])