import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useFormChangeHandler } from "hooks/useFormChangeHandler";
import { useSubmitHandler } from "hooks/useSubmitHandler";
import { useCreateData, useUpdateData } from "hooks/useApiHooks";

const CreatePage = ({ initialData, onSuccess, onError, onCancel }) => {
    const CREATE_URL = '/permission'; // URL API untuk create data

    // State untuk menyimpan form data. Jika initialData tersedia, isi form untuk update
    const [formData, setFormData] = useState({
        name: initialData?.group || '',
        group: initialData?.group || '',
    });

    const isUpdateMode = !!initialData; // Cek apakah ini update mode atau create mode

    // API hook untuk create dan update
    const { createData } = useCreateData(CREATE_URL);
    const { updateData } = useUpdateData(`${CREATE_URL}/${initialData?.id}`);

    // Gunakan hook untuk menangani perubahan form
    const handleChange = useFormChangeHandler(setFormData);

    // Gunakan hook untuk menangani pengiriman form
    const { handleSubmit, isSubmitting, formError } = useSubmitHandler(
        isUpdateMode ? updateData : createData,
        onSuccess,
        onError
    );

    const handleBeforeSubmit = (e) => {
        e.preventDefault();
        handleSubmit(formData, e);
    };

    useEffect(() => {
        // Jika data produk berubah (untuk update), isi ulang form data
        if (initialData) {
            setFormData({
                name: initialData.display_name || '',
                group: initialData.group || '',
            });
        }
    }, [initialData]);

    return (
        <form onSubmit={handleBeforeSubmit} style={{ height: '100%' }}>
            <div className="container-fluid mt-4">
                <div className="row mb-3 align-items-center">
                    <label htmlFor="name" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Name: </label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className={clsx("form-control", formError?.name && 'is-invalid')}
                            id="name"
                            placeholder="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                        />
                        {formError?.name && <div className="invalid-feedback">{formError.name}</div>}
                    </div>
                </div>

                <div className="row mb-3 align-items-center">
                    <label htmlFor="group" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Group: </label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className={clsx("form-control", formError?.group && 'is-invalid')}
                            id="group"
                            placeholder="Group"
                            name="group"
                            value={formData.group}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                        />
                        {formError?.group && <div className="invalid-feedback">{formError.group}</div>}
                    </div>
                </div>

                {/* Tambahkan field lain sesuai kebutuhan */}

                <div className="form-group mt-auto d-flex justify-content-between">
                    <button type="button" className="btn btn-secondary" style={{ height: '40px' }} onClick={onCancel}>
                        Cancel
                    </button>
                    <button type="submit" className="btn btn-primary" disabled={isSubmitting} style={{ height: '40px' }}>
                        {isUpdateMode ? 'Update Data' : 'Simpan Data'}
                    </button>
                </div>
            </div>
        </form>
    );
};

export default CreatePage;
