import Logo from "components/logo";

const FullLoader = () => {
    return (
        <div class="page page-center min-vh-100">
            <div class="container container-slim py-4">
                <div class="text-center">
                    <div class="mb-3">
                        <Logo />
                    </div>
                    <div class="text-secondary mb-3">Loading...</div>
                    <div class="progress progress-sm">
                        <div class="progress-bar progress-bar-indeterminate bg-primary"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FullLoader;