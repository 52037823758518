import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import styles from './SalesReport.module.css'; // Modular CSS untuk styling
import ReportsPage from '../../reports-page';
import { formatPrice } from 'utils/utils';

const SalesReport = () => {
    // State untuk properti dan tipe kamar yang dipilih
    const [selectedProperty, setSelectedProperty] = useState('VIENNA VILLAS SEMINYAK');
    const [selectedRooms, setSelectedRooms] = useState({
        'Vienna Villa 2BR': true,
        'Vienna Villa 4BR': true,
    });
    // State untuk mengelola pilihan "Based On"
    const [basedOn, setBasedOn] = useState('stay-dates');

    const reportData = [
        {
            property: 'Vienna Villas Seminyak',
            bookingDate: '2024-01-10',
            source: 'Booking.com',
            bookingId: 'BKG123456',
            reservationNumber: 'RES987654',
            roomType: 'Vienna Villa 2BR',
            roomNumber: '101',
            guestDetails: {
                name: 'John Doe',
                email: 'johndoe@example.com',
            },
            phone: '+6281234567890',
            country: 'Indonesia',
            promoCode: 'SUMMER2024',
            checkIn: '2024-01-15',
            checkOut: '2024-01-20',
            nights: 5,
            total: 2000000, // Total in IDR
            extras: 500000, // Extras in IDR
            otherCosts: 200000, // Other Costs in IDR
            roomOnly: 1500000, // Room Only in IDR
        },
        {
            property: 'Vienna Villas Seminyak',
            bookingDate: '2024-02-05',
            source: 'Freelance',
            bookingId: 'BKG123457',
            reservationNumber: 'RES987655',
            roomType: 'Vienna Villa 4BR',
            roomNumber: '102',
            guestDetails: {
                name: 'Jane Smith',
                email: 'janesmith@example.com',
            },
            phone: '+6280987654321',
            country: 'Malaysia',
            promoCode: 'WINTER2024',
            checkIn: '2024-02-10',
            checkOut: '2024-02-15',
            nights: 5,
            total: 3500000, // Total in IDR
            extras: 700000, // Extras in IDR
            otherCosts: 300000, // Other Costs in IDR
            roomOnly: 2800000, // Room Only in IDR
        },
        {
            property: 'Vienna Villas Seminyak',
            bookingDate: '2024-03-12',
            source: 'Travel Agent',
            bookingId: 'BKG123458',
            reservationNumber: 'RES987656',
            roomType: 'Vienna Villa 2BR',
            roomNumber: '103',
            guestDetails: {
                name: 'Michael Johnson',
                email: 'michaeljohnson@example.com',
            },
            phone: '+6282345678901',
            country: 'Singapore',
            promoCode: 'SPRING2024',
            checkIn: '2024-03-15',
            checkOut: '2024-03-20',
            nights: 5,
            total: 2200000, // Total in IDR
            extras: 300000, // Extras in IDR
            otherCosts: 150000, // Other Costs in IDR
            roomOnly: 1900000, // Room Only in IDR
        },
        {
            property: 'Vienna Villas Seminyak',
            bookingDate: '2024-04-18',
            source: 'Direct',
            bookingId: 'BKG123459',
            reservationNumber: 'RES987657',
            roomType: 'Vienna Villa 4BR',
            roomNumber: '104',
            guestDetails: {
                name: 'Emily Davis',
                email: 'emilydavis@example.com',
            },
            phone: '+6283456789012',
            country: 'Australia',
            promoCode: 'EASTER2024',
            checkIn: '2024-04-22',
            checkOut: '2024-04-27',
            nights: 5,
            total: 4000000, // Total in IDR
            extras: 800000, // Extras in IDR
            otherCosts: 500000, // Other Costs in IDR
            roomOnly: 3200000, // Room Only in IDR
        },
        {
            property: 'Vienna Villas Seminyak',
            bookingDate: '2024-05-01',
            source: 'Booking.com',
            bookingId: 'BKG123460',
            reservationNumber: 'RES987658',
            roomType: 'Vienna Villa 2BR',
            roomNumber: '105',
            guestDetails: {
                name: 'Robert Brown',
                email: 'robertbrown@example.com',
            },
            phone: '+6284567890123',
            country: 'United States',
            promoCode: 'HOLIDAY2024',
            checkIn: '2024-05-05',
            checkOut: '2024-05-10',
            nights: 5,
            total: 2500000, // Total in IDR
            extras: 600000, // Extras in IDR
            otherCosts: 250000, // Other Costs in IDR
            roomOnly: 1900000, // Room Only in IDR
        },
    ];


    // State untuk data yang ditampilkan (sesuai filter)
    const [filteredData, setFilteredData] = useState(reportData);

    // Fungsi untuk handle perubahan property
    const handlePropertyChange = (event) => {
        setSelectedProperty(event.target.value);
        filterData(event.target.value, selectedRooms);
    };

    // Fungsi untuk handle perubahan tipe kamar
    const handleRoomChange = (roomType) => {
        const updatedRooms = { ...selectedRooms, [roomType]: !selectedRooms[roomType] };
        setSelectedRooms(updatedRooms);
        filterData(selectedProperty, updatedRooms);
    };

    // Fungsi untuk filter data berdasarkan properti dan tipe kamar
    const filterData = (property, rooms) => {
        const filtered = reportData.filter(item => {
            return item.property === property && rooms[item.roomType];
        });
        setFilteredData(filtered);
    };

    // Fungsi Dummy untuk ekspor PDF
    const handleExportPDF = () => {
        alert('Laporan telah diekspor dalam format PDF!');
    };

    // Fungsi Dummy untuk ekspor Excel
    const handleExportExcel = () => {
        alert('Laporan telah diekspor dalam format Excel!');
        const header = ["Agent Name", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const rows = reportData.map(row => [
            row.agent,
            row.january,
            row.february,
            row.march,
            row.april,
            row.may,
            row.june,
            row.july,
            row.august,
            row.september,
            row.october,
            row.november,
            row.december
        ]);

        // Membuat CSV
        let csvContent = "data:text/csv;charset=utf-8,"
            + [header, ...rows].map(e => e.join(",")).join("\n");

        // Mengkonversi CSV ke URL
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "occupancy_report.csv");
        document.body.appendChild(link); // Diperlukan untuk Firefox

        link.click(); // Mengklik link untuk mengunduh file
    };

    // Handle perubahan pada radio button
    const handleBasedOnChange = (e) => {
        setBasedOn(e.target.value);
    };

    return (
        <ReportsPage>
            <div className={styles.container}>
                {/* Bagian Filter */}
                <div className="row mb-4 align-items-center">
                    <div className="col-md-4">
                        <label htmlFor="select-property" className="form-label">Select Property:</label>
                        <select className="form-select" id="select-property" value={selectedProperty} onChange={handlePropertyChange}>
                            <option value="VIENNA VILLAS SEMINYAK">VIENNA VILLAS SEMINYAK</option>
                            {/* Tambahkan properti lainnya jika perlu */}
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="select-year" className="form-label">Select Year:</label>
                        <select className="form-select" id="select-year">
                            <option>2024</option>
                            {/* Tambahkan tahun lainnya jika perlu */}
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="date-range" className="form-label">Select Range:</label>
                        <div className="d-flex">
                            <input type="date" className="form-control me-2" id="start-date" value="2024-09-14" />
                            <span className="mx-2">to</span>
                            <input type="date" className="form-control" id="end-date" value="2024-10-14" />
                        </div>
                    </div>
                </div>

                <div className="mb-4">
                    <label className="form-label me-2">Report Based On:</label>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            id="stay-dates"
                            value="stay-dates"
                            checked={basedOn === 'stay-dates'}
                            onChange={handleBasedOnChange}
                        />
                        <label className="form-check-label" htmlFor="stay-dates">
                            Stay Dates
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            id="created-reservation"
                            value="created-reservation"
                            checked={basedOn === 'created-reservation'}
                            onChange={handleBasedOnChange}
                        />
                        <label className="form-check-label" htmlFor="created-reservation">
                            Created Reservation
                        </label>
                    </div>
                </div>

                {/* Pilihan Tipe Kamar */}
                <div className="row mb-4">
                    <div className="col-md-6">
                        <label className="form-label">Select Room Types:</label>
                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="villa-2br"
                                checked={selectedRooms['Vienna Villa 2BR']}
                                onChange={() => handleRoomChange('Vienna Villa 2BR')}
                            />
                            <label className="form-check-label" htmlFor="villa-2br">Vienna Villa 2BR</label>
                        </div>
                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="villa-4br"
                                checked={selectedRooms['Vienna Villa 4BR']}
                                onChange={() => handleRoomChange('Vienna Villa 4BR')}
                            />
                            <label className="form-check-label" htmlFor="villa-4br">Vienna Villa 4BR</label>
                        </div>
                    </div>
                    <div className="col-md-6 text-end">
                        <button className="btn btn-primary me-2" onClick={handleExportPDF}>Export to PDF</button>
                        <button className="btn btn-success" onClick={handleExportExcel}>Export to Excel</button>
                    </div>
                </div>

                {/* Bagian Tabel */}
                <div className={`${styles.card} bg-white`}>
                    <h6 className={styles.moduleHeader}>Sales Report</h6>
                    <div className="table-responsive">
                        <Table bordered hover className="text-center">
                            <thead>
                                <tr>
                                    <th>Property</th>
                                    <th>Booking Date</th>
                                    <th>Source</th>
                                    <th>Booking ID</th>
                                    <th>Reservation Number</th>
                                    <th>Room Type</th>
                                    <th>Room Number</th>
                                    <th>Guest Name</th>
                                    <th>Phone</th>
                                    <th>Country</th>
                                    <th>Promo Code</th>
                                    <th>Check In</th>
                                    <th>Check Out</th>
                                    <th>Nights</th>
                                    <th>Total (IDR)</th>
                                    <th>Extras (IDR)</th>
                                    <th>Other Costs (IDR)</th>
                                    <th>Room Only (IDR)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reportData.map((row, index) => (
                                    <tr key={index}>
                                        <td>{row.property}</td>
                                        <td>{row.bookingDate}</td>
                                        <td>{row.source}</td>
                                        <td>{row.bookingId}</td>
                                        <td>{row.reservationNumber}</td>
                                        <td>{row.roomType}</td>
                                        <td>{row.roomNumber}</td>
                                        <td>{row.guestDetails.name}</td>
                                        <td>{row.phone}</td>
                                        <td>{row.country}</td>
                                        <td>{row.promoCode}</td>
                                        <td>{row.checkIn}</td>
                                        <td>{row.checkOut}</td>
                                        <td>{row.nights}</td>
                                        <td>{formatPrice(row.total)}</td> {/* Format IDR */}
                                        <td>{formatPrice(row.extras)}</td>
                                        <td>{formatPrice(row.otherCosts)}</td>
                                        <td>{formatPrice(row.roomOnly)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </ReportsPage>
    );
};

export default SalesReport;
