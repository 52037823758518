import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import { URLS } from 'utils/constants';

const RedirectIfLoggedIn = () => {
  const { user } = useAuth();

  return user ? <Navigate to={URLS.DASHBOARD} replace /> : <Outlet />;
};

export default RedirectIfLoggedIn;
