import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { IconEdit } from "@tabler/icons-react"; // Tabler Icons
import Suspense from "components/suspense/suspense";
import FullLoader from "components/loader/full-loader";
import LayoutDashboard from "layouts/layout-dashboard-collapse";
import BreadcrumbWithTitle from "components/breadcrumb/breadcrumb-with-title";
import { useFetchData, useUpdateData } from "hooks/useApiHooks";
import { URLS } from "utils/constants";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content"; // Import react content support for SweetAlert2

// Custom Swal with react component support
const MySwal = withReactContent(Swal);

const ProfilePage = () => {
  const API_URL = "/me"; // Set API URL for fetching and updating user data
  const PAGE_TITLE = "Profile";

  const [profileData, setProfileData] = useState({
    name: "",
    username: "",
    email: "",
  });

  // Fetch data from API
  const { data, loading, error } = useFetchData(API_URL); // Fetch user data from /me
  const { updateData } = useUpdateData(API_URL); // Update user data on /me

  // Set profile data after fetching
  useEffect(() => {
    if (data && data.data) {
      console.log("Data fetched from /me endpoint:", data); // Debugging: log data received from the API
      setProfileData({
        name: data.data.name || "",
        username: data.data.username || "",
        email: data.data.email || "",
      });
    }
  }, [data]);

  // SweetAlert for editing profile
  const handleEditProfile = () => {
    // State lokal untuk menyimpan perubahan selama dialog aktif
    let editData = { ...profileData };

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      editData = { ...editData, [name]: value };
    };

    MySwal.fire({
      title: `Edit ${PAGE_TITLE}`,
      html: (
        <form id="editProfileForm">
          <div className="mb-3">
            <label htmlFor="name" className="form-label">
              Name
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              defaultValue={editData.name}
              onInput={handleInputChange} // Menggunakan `onInput` agar berfungsi dengan SweetAlert
            />
          </div>
          <div className="mb-3">
            <label htmlFor="username" className="form-label">
              Username
            </label>
            <input
              type="text"
              className="form-control"
              id="username"
              name="username"
              defaultValue={editData.username}
              onInput={handleInputChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              defaultValue={editData.email}
              onInput={handleInputChange}
            />
          </div>
        </form>
      ),
      showCancelButton: true,
      confirmButtonText: "Save",
      preConfirm: () => {
        return editData; // Mengirimkan data edit ke dalam SweetAlert
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await updateData(result.value); // Kirim hasil edit ke server
          setProfileData(result.value); // Update state utama
          MySwal.fire("Success", "Profile updated successfully", "success");
        } catch (error) {
          MySwal.fire("Error", "Failed to update profile", "error");
        }
      }
    });
  };

  return (
    <Suspense loader={<FullLoader />} loading={loading}>
      <LayoutDashboard>
        <BreadcrumbWithTitle
          breadcrumbs={[
            { label: "Dashboard", href: "/" },
            {
              label: PAGE_TITLE,
              href: URLS.DASHBOARD_ADMIN_PROFILE,
              active: true,
            },
          ]}
          title={PAGE_TITLE}
          extras={[
            <Button
              variant="primary"
              onClick={handleEditProfile}
              key="edit-profile-btn"
            >
              <IconEdit size={20} className="me-1" /> Edit Profile
            </Button>,
          ]}
        />
        <div className="container-fluid my-4 px-4 rounded">
          <div className="card shadow-sm rounded-3">
            <div className="card-body p-4">
              <h5>Profile Information</h5>
              {error && (
                <div className="alert alert-danger">
                  Error loading profile data
                </div>
              )}
              <div className="mb-3">
                <label className="form-label">Name</label>
                <div className="form-control bg-light">
                  {profileData.name || "N/A"}
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">Username</label>
                <div className="form-control bg-light">
                  {profileData.username || "N/A"}
                </div>
              </div>
              <div className="mb-3">
                <label className="form-label">Email</label>
                <div className="form-control bg-light">
                  {profileData.email || "N/A"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutDashboard>
    </Suspense>
  );
};

export default ProfilePage;
