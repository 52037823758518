import { useState } from 'react';

export const useSubmitHandler = (submitFunction, onSuccess, onError) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formError, setFormError] = useState(null);

    const handleSubmit = async (formData, e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setFormError(null);

        const result = await submitFunction(formData);

        if (result.success) {
            onSuccess();  // Callback untuk sukses
        } else {
            if (result.errorResponse?.code === 422) {
                setFormError(result.errorResponse.message);  // Error validation
            } else {
                onError();  // Callback untuk error
            }
        }

        setIsSubmitting(false);
    };

    return { handleSubmit, isSubmitting, formError };
};
