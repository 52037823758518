import React, { useEffect, useState } from "react";
import FullLoader from "components/loader/full-loader";
import { Accordion, Card, useAccordionButton } from "react-bootstrap";
import LayoutDashboard from "layouts/layout-dashboard-collapse";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useCanvasHandler } from "hooks/useCanvasHandler";
import CreateReservationPage from "./v2/create-reservation-page";
import { IconAdjustmentsHorizontal } from "@tabler/icons-react";
import { usePaginationWithComponent } from "hooks/usePaginationWithComponent";
import { getRequest } from "services/api";
import ReservationItems from "./components/reservations-items";
import SearchComponent from "./components/search-component";
import { useReservation } from "contexts/v2/pages/reservation/ReservationContext";

// Custom Swal with react component support
const MySwal = withReactContent(Swal);

function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey);

    return (
        <button className="btn btn-icon me-2" onClick={decoratedOnClick}>
            {children}
        </button>
    );
}

const ReservationPage = () => {
    const { reservation, pagination, setPagination, filterOption, setFilterOption } = useReservation();

    const [agents, setAgents] = useState({
        data: [],
        error: null,
        isLoading: false
    });

    const fetchAgents = async () => {
        setAgents({ ...agents, isLoading: true });
        try {
            const response = await getRequest('/agent?all=1');
            console.log('response', response.data);
            if (response.success) {
                setAgents((prev) => ({
                    ...prev,
                    data: response.data,
                    error: null
                }));
            }
        } catch (error) {
            setAgents((prev) => ({ ...prev, error: error }));
        } finally {
            setAgents((prev) => ({ ...prev, isLoading: false }));
        }
    }

    useEffect(() => {
        fetchAgents();
    }, []);

    const { handleSuccess, handleError, showCanvasHandler } = useCanvasHandler();

    const handlePageChange = (page) => {
        setPagination((prev) => ({ ...prev, currentPage: page }));
    };

    const PaginationComponent = usePaginationWithComponent({
        totalPages: pagination.totalPages,
        currentPage: pagination.currentPage,
        onPageChange: handlePageChange,
        visiblePages: 5,
    });

    const handleCreateReservation = (e) => {
        e.preventDefault();
        showCanvasHandler('Create Reservation',
            <CreateReservationPage
                onSuccess={() => {
                    handleSuccess(true, 'Reservation created successfully');
                    reservation.refetch();
                }}
                onError={handleError}
            />
        );
    };

    if (reservation.isLoading) {
        return <FullLoader />;
    }

    return (
        <LayoutDashboard>
            <div className="container-fluid mt-5">
                <div className="table-container">
                    <Accordion defaultActiveKey="0" flush>
                        <div className="search-container mb-3">
                            <div className="d-flex">
                                <CustomToggle eventKey="0">
                                    <IconAdjustmentsHorizontal className="text-secondary icon" />
                                </CustomToggle>
                                <SearchComponent defaultValue={filterOption.search} onFilter={setFilterOption} />
                            </div>

                            {/* Create button */}
                            <button className="btn btn-primary" onClick={handleCreateReservation}>Create</button>
                        </div>
                        <Accordion.Collapse eventKey="0">
                            {/* multi filter with date, status and agent_id */}
                            <Card className="mb-3 shadow-none">
                                <Card.Body>
                                    <div className="row">
                                        <div className="col-md-3 mt-2 mt-md-0">
                                            <div className="form-group">
                                                <label htmlFor="date" className="mb-1">Checkin</label>
                                                <input type="date" className="form-control" id="date" onChange={(e) => {
                                                    e.preventDefault();
                                                    setFilterOption({ ...filterOption, check_in: e.target.value });
                                                }} />
                                            </div>
                                        </div>
                                        <div className="col-md-3 mt-2 mt-md-0">
                                            <div className="form-group">
                                                <label htmlFor="status" className="mb-1">Status</label>
                                                <select className="form-select" id="status" onChange={(e) => {
                                                    e.preventDefault();
                                                    setFilterOption({ ...filterOption, status: e.target.value });
                                                }}>
                                                    <option value="">All</option>
                                                    <option value="new">New</option>
                                                    <option value="confirmed">Confirmed</option>
                                                    <option value="pending">Pending</option>
                                                    <option value="canceled">Cancelled</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3 mt-2 mt-md-0">
                                            <div className="form-group">
                                                <label htmlFor="agent_id" className="mb-1">Agent</label>
                                                {agents.isLoading ? (<span>Loading...</span>) : (
                                                    <select className="form-select" id="agent_id" onChange={(e) => {
                                                        e.preventDefault();
                                                        setFilterOption({ ...filterOption, agent_id: e.target.value });
                                                    }}>
                                                        <option value="all">All</option>
                                                        {agents?.data?.map((agent, index) => (
                                                            <option key={index} value={agent.id}>{agent.name}</option>
                                                        ))}
                                                    </select>
                                                )}
                                            </div>
                                        </div>
                                        {/* Switch for showing cancel booking */}
                                        <div className="col-md-3 mt-2 mt-md-0">
                                            <div className="form-group text-start text-md-end">
                                                <label htmlFor="show_cancel" className="mb-1">Show Cancel Booking</label>
                                                <div className="form-check form-switch mb-0 mt-2 d-flex justify-content-start justify-content-md-end">
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="show_cancel"
                                                        checked={filterOption.is_cancelled}
                                                        onChange={({ target: { checked } }) =>
                                                            setFilterOption({ ...filterOption, is_cancelled: checked })
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        {/* Reset filter small button */}
                                        <div className="col-md-3 mt-2 mt-md-0">
                                            <button
                                                className="btn btn-secondary btn-sm"
                                                onClick={() => {
                                                    setFilterOption({
                                                        search: '',
                                                        check_in: '',
                                                        check_out: '',
                                                        status: '',
                                                        agent_id: '',
                                                        is_cancelled: false
                                                    });
                                                }}
                                            >
                                                Reset Filter
                                            </button>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Accordion.Collapse>
                    </Accordion>

                    {/* Table */}
                    <ReservationItems />

                    {/* Pagination */}
                    {PaginationComponent}
                </div>
            </div>
        </LayoutDashboard>
    );
};

export default ReservationPage;