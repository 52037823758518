import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useFormChangeHandler } from "hooks/useFormChangeHandler";
import { useSubmitHandler } from "hooks/useSubmitHandler";
import { useCreateData, useFetchData, useUpdateData } from "hooks/useApiHooks";
import { RoomType } from "./components/rates-tab-form";

const NumberField = ({ label, name, value, onChange, error }) => (
    <div className="row mb-3 align-items-center">
        <label htmlFor={name} className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>{label}:</label>
        <div className="col-sm-8">
            <input
                type="number"
                min={0}
                className={clsx("form-control djitu-form-control rounded-1", error && 'is-invalid')}
                id={name}
                placeholder={label}
                name={name}
                value={value}
                onChange={onChange}
                style={{ fontSize: '14px', height: '35px' }}
            />
            {error && <div className="invalid-feedback">{error}</div>}
        </div>
    </div>
);

const TextAreaField = ({ label, name, value, onChange, error }) => (
    <div className="row mb-3 align-items-center">
        <label htmlFor={name} className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>{label}:</label>
        <div className="col-sm-8">
            <textarea
                className={clsx("form-control djitu-form-control rounded-1", error && 'is-invalid')}
                id={name}
                placeholder={label}
                name={name}
                value={value}
                onChange={onChange}
                style={{ fontSize: '14px', height: '100px' }}
            />
            {error && <div className="invalid-feedback">{error}</div>}
        </div>
    </div>
);

const EmailField = ({ label, name, value, onChange, error }) => (
    <div className="row mb-3 align-items-center">
        <label htmlFor={name} className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>{label}:</label>
        <div className="col-sm-8">
            <input
                type="email"
                className={clsx("form-control djitu-form-control rounded-1", error && 'is-invalid')}
                id={name}
                placeholder={label}
                name={name}
                value={value}
                onChange={onChange}
                style={{ fontSize: '14px', height: '35px' }}
            />
            {error && <div className="invalid-feedback">{error}</div>}
        </div>
    </div>
);

const TextField = ({ label, name, value, onChange, error }) => (
    <div className="row mb-3 align-items-center">
        <label htmlFor={name} className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>{label}:</label>
        <div className="col-sm-8">
            <input
                type="text"
                className={clsx("form-control djitu-form-control rounded-1", error && 'is-invalid')}
                id={name}
                placeholder={label}
                name={name}
                value={value}
                onChange={onChange}
                style={{ fontSize: '14px', height: '35px' }}
            />
            {error && <div className="invalid-feedback">{error}</div>}
        </div>
    </div>
);

const DateField = ({ label, name, value, onChange, error }) => (
    <div className="row mb-3 align-items-center">
        <label htmlFor={name} className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>{label}:</label>
        <div className="col-sm-8">
            <input
                type="date"
                className={clsx("form-control djitu-form-control rounded-1", error && 'is-invalid')}
                id={name}
                name={name}
                value={value}
                onChange={onChange}
                style={{ fontSize: '14px', height: '35px' }}
            />
            {error && <div className="invalid-feedback">{error}</div>}
        </div>
    </div>
);

const SelectField = ({ label, name, value, options, onChange, error }) => (
    <div className="row mb-3 align-items-center">
        <label htmlFor={name} className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>{label}:</label>
        <div className="col-sm-8">
            <select
                className={clsx("form-select djitu-form-control rounded-1", error && 'is-invalid')}
                id={name}
                name={name}
                value={value}
                onChange={onChange}
                style={{ fontSize: '14px', height: '35px' }}
            >
                {options?.map((option) => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                ))}
            </select>
            {error && <div className="invalid-feedback">{error}</div>}
        </div>
    </div>
);

const CreateReservationPage = ({ initialData, onSuccess, onError }) => {
    const API_URL = '/reservation';

    const [uiState, setUiState] = useState({
        invoice_number: '',
    });

    const [formData, setFormData] = useState({
        // // Tambahkan inisialisasi untuk semua field yang diperlukan
        // invoice_number: '',
        // invoice_date: '',
        booking_date: '',
        arrival_date: '',
        departure_date: '',
        adult: 1,
        children: 1,
        status: '',
        mark: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        // language: 'en',
        country: '',
        city: '',
        address: '',
        postal_code: '',
        room_rate: [{
            room_type_id: 0, // Contoh nilai default
            room_number: 0, // Contoh nilai default
            rate: '',
            rates: [],
        }],
        agent: '',
    });

    const isUpdateMode = !!initialData?.id; // Cek apakah ini update mode atau create mode

    // API hook untuk create dan update
    const { data: agents, loading: loadingAgents } = useFetchData('/agent?all=1');
    const { createData: createProduct, loading: creatingProduct } = useCreateData(API_URL);
    const { updateData: updateProduct, loading: updatingProduct } = useUpdateData(`${API_URL}/${initialData?.id}`);

    // Gunakan hook untuk menangani perubahan form
    const handleChange = useFormChangeHandler(setFormData);

    // Gunakan hook untuk menangani pengiriman form
    const { handleSubmit, isSubmitting, formError } = useSubmitHandler(
        isUpdateMode ? updateProduct : createProduct,
        onSuccess,
        onError
    );

    useEffect(() => {
        console.log('initialData', initialData);
        // Jika data produk berubah (untuk update), isi ulang form data
        if (initialData) {
            setUiState({
                invoice_number: initialData.unique_id || '',
            });
            setFormData({
                // Isi form data dengan initialData
                // invoice_number: initialData.invoice_number || '',
                invoice_date: initialData.invoice_date || '',
                booking_date: initialData.booking_date || '',
                arrival_date: initialData.arrival_date || '',
                departure_date: initialData.departure_date || '',
                adult: initialData.adult || 1,
                children: initialData.children || 1,
                status: initialData.status || '',
                mark: initialData.mark || '',
                first_name: initialData.customer_name || '',
                last_name: initialData.customer_surname || '',
                email: initialData.customer?.email || '',
                phone: initialData.customer?.phone || '',
                // language: initialData.customer_language || 'en',
                country: initialData.customer?.country || 'ID',
                city: initialData.customer?.city || '',
                address: initialData.customer?.address || '',
                postal_code: initialData.customer?.zip || '',
                notes: initialData.notes || '',
                room_rate: initialData.booking_rooms && initialData.booking_rooms.map((room) => {
                    return {
                        room_type_id: room.room_type_id,
                        room_number: room.room_id,
                        rate: room.rate_plan_id,
                        check_in: room.checkin_date,
                        check_out: room.checkout_date,
                        rates: room.days.map((rate) => {
                            return {
                                date: rate.date,
                                rate: rate.amount,
                            };
                        }),
                        raw_data: room,
                    };
                }) || [{
                    room_type_id: 0, // Contoh nilai default
                    room_number: 0, // Contoh nilai default
                    rate: '',
                    // check_in: '',s
                    // check_out: '',
                    rates: [],
                }],
                agent: initialData.agent_id || '',
            }); // Sesuaikan dengan struktur initialData
        }
    }, [initialData]);

    const handleBeforeSubmit = (e) => {
        e.preventDefault();
        const payload = {
            customer_name: formData.first_name,
            customer_surname: formData.last_name,
            customer_email: formData.email,
            customer_phone: formData.phone,
            // customer_language: formData.language,
            customer_country: formData.country,
            customer_city: formData.city,
            customer_address: formData.address,
            customer_postal_code: formData.postal_code,
            status: formData.status,
            currency: "IDR",
            amount: formData.room_rate.reduce((acc, room) => acc + room.rates.reduce((acc, rate) => acc + parseInt(rate.rate), 0), 0),
            occupancy_children: formData.children,
            occupancy_adults: formData.adult,
            occupancy_infants: 0,
            arrival_date: formData.arrival_date,
            departure_date: formData.departure_date,
            notes: formData.notes,
            bookingroom: formData.room_rate.map((room) => {
                return {
                    room_type_id: room.room_type_id,
                    room_id: room.room_number,
                    rate_plan_id: room.rate,
                    amount: room.rates.reduce((acc, rate) => acc + parseInt(rate.rate), 0),
                    occupancy_adults: formData.adult,
                    occupancy_children: formData.children,
                    occupancy_infants: 0,
                    checkin_date: formData.arrival_date,
                    checkout_date: formData.departure_date,
                    day: room.rates.map((rate) => {
                        return {
                            date: rate.date,
                            amount: rate.rate,
                        };
                    }),
                };
            }),
            ...(formData.agent && { agent_id: formData.agent })
        };

        console.log('payload', payload);

        handleSubmit(payload, e);
    }

    return (
        <form onSubmit={(e) => handleBeforeSubmit(e)} className="d-flex flex-column" style={{ height: '100%' }}>
            <div className="action-sheet-content flex-grow-1">
                {/* Invoice Number */}
                {initialData && (
                    <div className="row mb-3 align-items-center">
                        <label htmlFor="invoice_number" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Invoice Number:</label>
                        <div className="col-sm-8">
                            <span className="form-control djitu-form-control rounded-1" style={{ fontSize: '14px', height: '35px' }}>{uiState.invoice_number}</span>
                        </div>
                    </div>
                )}

                {/* Status using SelectField */}
                <SelectField label="Status" name="status" value={formData.status} options={[
                    { value: 'confirmed', label: 'Confirmed' },
                    { value: 'pending', label: 'Pending' },
                    { value: 'cancelled', label: 'Cancelled' },
                ]} onChange={handleChange} error={formError?.status} />

                {/* Arrival Date using DateField */}
                <DateField label="Arrival Date" name="arrival_date" value={formData.arrival_date} onChange={handleChange} error={formError?.arrival_date} />

                {/* Departure Date using DateField */}
                <DateField label="Departure Date" name="departure_date" value={formData.departure_date} onChange={handleChange} error={formError?.departure_date} />

                {/* Guest Information Section */}
                <h3 className="mt-6 border-bottom fw-normal">Guest Information</h3>

                {/* First Name using TextField */}
                <TextField label="First Name" name="first_name" value={formData.first_name} onChange={handleChange} error={formError?.first_name} />

                {/* Last Name using TextField */}
                <TextField label="Last Name" name="last_name" value={formData.last_name} onChange={handleChange} error={formError?.last_name} />

                {/* Email using EmailField */}
                <EmailField label="Email" name="email" value={formData.email} onChange={handleChange} error={formError?.email} />

                {/* Phone using TextField */}
                <TextField label="Phone" name="phone" value={formData.phone} onChange={handleChange} error={formError?.phone} />

                {/* Language */}
                {/* <div className="row mb-3 align-items-center">
                    <label htmlFor="language" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Language:</label>
                    <div className="col-sm-8">
                        <Select
                            options={
                                [
                                    { value: 'en', label: 'English' },
                                    { value: 'id', label: 'Indonesia' },
                                ]
                            }
                            value={{ value: formData.language, label: formData.language === 'en' ? 'English' : 'Indonesia' }}
                            onChange={(selected) => {
                                setFormData({ ...formData, language: selected.value });
                            }}
                        />
                        {formError?.customer_language && <div className="invalid-feedback">{formError.customer_language}</div>}
                    </div>
                </div> */}

                {/* Country using TextField */}
                <TextField label="Country" name="country" value={formData.country} onChange={handleChange} error={formError?.customer_country} />

                {/* City using TextField */}
                <TextField label="City" name="city" value={formData.city} onChange={handleChange} error={formError?.customer_city} />

                {/* Address using TextAreaField */}
                <TextAreaField label="Address" name="address" value={formData.address} onChange={handleChange} error={formError?.customer_address} />

                {/* Postal Code using TextField */}
                <TextField label="Postal Code" name="postal_code" value={formData.postal_code} onChange={handleChange} error={formError?.customer_postal_code} />

                {/* Adult Count using NumberField */}
                <NumberField label="Adults" name="adult" value={formData.adult} onChange={handleChange} error={formError?.occupancy_adults} />

                {/* Children Count using NumberField */}
                <NumberField label="Children" name="children" value={formData.children} onChange={handleChange} error={formError?.occupancy_children} />

                {/* Notes using TextAreaField */}
                <TextAreaField label="Notes" name="notes" value={formData.notes} onChange={handleChange} error={formError?.notes} />

                {/* Agent using SelectField */}
                <SelectField label="Agent" name="agent" value={formData.agent} options={agents.data?.map((agent) => ({ value: agent.id, label: agent.name }))} onChange={handleChange} error={formError?.agent} />

                {/* Room Information Section */}
                <h3 className="mt-6 border-bottom fw-normal">Room Details</h3>
                <RoomType formData={formData} setFormData={setFormData} />

                {/* Add Room Button */}
                <div className="form-group mt-3 mb-3">
                    <span className="d-block text-center text-primary cursor-pointer" onClick={() => setFormData({
                        ...formData,
                        room_rate: [...formData.room_rate, { room_type_id: 0, room_number: 0, rate: '', rates: [] }],
                    })}>Add Room +</span>
                </div>
            </div>

            <div className="form-group mt-auto d-grid">
                <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting}>
                    {isUpdateMode ? 'Update Reservation' : 'Create Reservation'}
                </button>
            </div>
        </form>
    );
};

export default CreateReservationPage;
