// src/utils/dateHelpers.js

/**
 * Mengubah objek Date menjadi string format YYYY-MM-DD
 * @param {Date} date - Objek Date yang akan diformat
 * @returns {string} - Tanggal dalam format YYYY-MM-DD
 */
export const formatDateToString = (date) => {
    return date.toISOString().split('T')[0];
};

// src/utils/dateHelpers.js

/**
 * Mengubah string tanggal (format YYYY-MM-DD) menjadi format DD-Month-YYYY (contoh: 10-September-2024)
 * @param {string} dateString - String tanggal dalam format YYYY-MM-DD
 * @returns {string} - Tanggal dalam format DD-Month-YYYY
 */
export const formatDateToDayMonthYear = (dateString) => {
    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, '0');
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
};

/**
 * Mengubah string tanggal (format YYYY-MM-DD) menjadi format singkat dengan nama hari,
 * bulan, tanggal, dan tahun (contoh: "Tue, Nov 12, 2024").
 * @param {string} dateString - String tanggal dalam format YYYY-MM-DD
 * @returns {string} - Tanggal dalam format "Day, Month DD, YYYY"
 */
export const formatDateToShortWeekdayMonthYear = (dateString) => {
    const date = new Date(dateString);

    // Menyusun format menggunakan opsi toLocaleDateString
    return date.toLocaleDateString("en-US", {
        weekday: "short",   // Nama hari singkat (contoh: "Tue")
        month: "short",     // Nama bulan singkat (contoh: "Nov")
        day: "2-digit",     // Tanggal dua digit (contoh: "12")
        year: "numeric"     // Tahun lengkap (contoh: "2024")
    });
};

/**
 * Menghasilkan string yang diformat dengan nama bulan dan tahun (contoh: "October 2024")
 * berdasarkan input bulan dan tahun numerik.
 * 
 * @param {number} month - Nomor bulan (1-12), di mana 1 adalah Januari dan 12 adalah Desember.
 * @param {number} year - Tahun dalam format numerik (contoh: 2024).
 * @returns {string} - String dalam format "Month Year" (contoh: "October 2024").
 * 
 * @example
 * getFormattedMonthYear(10, 2024); // "October 2024"
 */
export const getFormattedMonthYear = (month, year) => {
    // Daftar nama bulan dalam bahasa Inggris
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    // Kurangi 1 dari bulan karena array dimulai dari indeks 0
    const monthName = monthNames[month - 1];

    return `${monthName} ${year}`;
};

