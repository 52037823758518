import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';
import FullLoader from 'components/loader/full-loader';

const ProtectedRoute = () => {
  const { user, loading } = useAuth();

  if (loading) {
    return <FullLoader />;  // Menampilkan loader saat memeriksa status login
  }

  return user ? <Outlet /> : <Navigate to="/login" replace />;
};

export default ProtectedRoute;
