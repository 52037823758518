// src/utils/queryHelpers.js

/**
 * Membangun query string dari objek dengan format 'key[index]=value' untuk array dan 'key=value' untuk string.
 * Menambahkan '?' di awal query string jika hasilnya tidak kosong.
 * @param {Object} params - Objek yang berisi kunci dan nilai untuk query string.
 * @returns {string|null} - String query yang dibangun dengan '?' di awal, atau null jika tidak ada nilai yang valid.
 */
export const buildQueryString = (params) => {
    // Membangun query string dari parameter valid (bukan array kosong atau null)
    const queryString = Object.entries(params).map(([key, value]) => {
        if (Array.isArray(value) && value.length > 0) {
            // Jika value adalah array dan memiliki nilai, masukkan ke query string
            return value.map((val, index) => `${key}[${index}]=${val}`).join('&');
        }
        if (value !== null && !Array.isArray(value)) {
            // Jika value adalah string (bukan null atau array), tambahkan langsung
            return `${key}=${value}`;
        }
        return null; // Abaikan nilai yang tidak valid (array kosong atau null)
    }).filter(Boolean).join('&'); // Filter null values

    // Menambahkan '?' jika query string tidak kosong
    return queryString ? `?${queryString}` : null;
};
