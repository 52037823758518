import { Modal } from "react-bootstrap";

const ModalCustom = ({ title, body, show, onClose, size = 'sm' }) => {
    return (
        <>
            {/* <Modal size={size} backdrop="static" scrollable={true} show={show} onHide={onClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{title.charAt(0).toUpperCase() + title.slice(1)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {children}
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-link link-secondary" onClick={onClose}>Cancel</Button>
                    <Button onClick={onButtonClick} className="btn-primary ms-auto">
                        <IconPlus size={20} />
                        {buttonText}
                    </Button>
                </Modal.Footer>
            </Modal> */}
            <Modal size={size} backdrop="static" scrollable={true} show={show} onHide={onClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                    {body}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ModalCustom;