import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import {
  IconEdit,
  IconEye,
  IconFilter,
  IconListDetails,
  IconPlus,
  IconTrash,
} from "@tabler/icons-react"; // Tabler Icons
import Suspense from "components/suspense/suspense";
import FullLoader from "components/loader/full-loader";
import { useDeleteData, useFetchData } from "hooks/useApiHooks";
import { useReload } from "contexts/ReloadContext";
import BreadcrumbWithTitle from "components/breadcrumb/breadcrumb-with-title";
import { URLS } from "utils/constants";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content"; // Import react content support for SweetAlert2
import { useCanvasHandler } from "hooks/useCanvasHandler";
import CreateForm from "./components/create-page";
import { useDeleteHandler } from "hooks/useDeleteHandler";
import { usePaginationWithComponent } from "hooks/usePaginationWithComponent";
import SyncPermissionsPage from "./components/sync-permissions-page";
import LayoutDashboard from "layouts/layout-dashboard-collapse";
import SyncUserPage from "./components/sync-user-page";

// Custom Swal with react component support
const MySwal = withReactContent(Swal);

const RolesPage = () => {
  const API_URL = "/role";
  const PAGE_TITLE = "Roles";

  const { reload } = useReload(); // Reload context
  const [searchTerm, setSearchTerm] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(10); // Default: 10 entries per page
  const [currentPage, setCurrentPage] = useState(1);

  // Fetch data
  const { data: fetchData, loading } = useFetchData(
    `${API_URL}?page=${currentPage}&limit=${entriesPerPage}&search=${searchTerm}`,
    reload
  );
  const { deleteData } = useDeleteData(API_URL);

  // Filter data based on search term
  const filteredData = fetchData.data || [];

  const totalEntries = fetchData.pagination?.total || 0; // Update based on the fetched total
  const totalPages = fetchData.pagination?.last_page || 1; // Update based on the fetched last page

  // Use the pagination hook
  const paginationComponent = usePaginationWithComponent({
    totalPages,
    currentPage,
    onPageChange: setCurrentPage,
    visiblePages: 3, // You can adjust this as needed
  });

  const handleDelete = useDeleteHandler(
    deleteData,
    `${PAGE_TITLE} telah dihapus`,
    `Gagal menghapus ${PAGE_TITLE}`
  );
  const { handleSuccess, handleError, showCanvasHandler } = useCanvasHandler();

  // Handle form submit for SweetAlert
  const handleCreateRole = () => {
    MySwal.fire({
      title: `Create New ${PAGE_TITLE}`,
      html: (
        <CreateForm
          onSuccess={() => {
            MySwal.close();
            handleSuccess(`${PAGE_TITLE} created successfully`);
            setCurrentPage(1); // Reset to the first page after creation
          }}
          onError={handleError}
          onCancel={() => {
            MySwal.close();
          }}
        />
      ),
      showCancelButton: false,
      cancelButtonText: "Cancel",
      showConfirmButton: false, // hide default button
    });
  };

  // Handle form submit for SweetAlert
  const handleUpdateRole = ({ item }) => {
    MySwal.fire({
      title: `Create New ${PAGE_TITLE}`,
      html: (
        <CreateForm
          initialData={item}
          onSuccess={() => {
            MySwal.close();
            handleSuccess(`${PAGE_TITLE} created successfully`);
            setCurrentPage(1); // Reset to the first page after creation
          }}
          onError={handleError}
          onCancel={() => {
            MySwal.close();
          }}
        />
      ),
      showCancelButton: false,
      cancelButtonText: "Cancel",
      showConfirmButton: false, // hide default button
    });
  };

  // Handle form submit for SweetAlert
  const handleSyncPermissions = ({ item }) => {
    showCanvasHandler(
      `Edit ${PAGE_TITLE}`,
      <SyncPermissionsPage
        onSuccess={handleSuccess}
        onError={handleError}
        initialData={item}
      />,
      "offcanvas-custom"
    );
  };

  const handleSyncUser = ({ item }) => {
    showCanvasHandler(
        `Sync User ${PAGE_TITLE}`,
        <SyncUserPage
            onSuccess={handleSuccess}
            onError={handleError}
            initialData={item}
        />,
        "offcanvas-custom"
    );
    };

  const truncateString = (str, maxLength) => {
    if (!str) return ""; // Jika string tidak ada, kembalikan string kosong
    return str.length > maxLength ? str.substring(0, maxLength) + "..." : str;
  };

  return (
    <Suspense loader={<FullLoader />} loading={loading}>
      <LayoutDashboard>
        <BreadcrumbWithTitle
          breadcrumbs={[
            { label: "Dashboard", href: "/" },
            {
              label: PAGE_TITLE,
              href: URLS.DASHBOARD_ADMIN_DISCOUNT,
              active: true,
            },
          ]}
          title={PAGE_TITLE}
          extras={[
            <Button variant="outline-secondary">
              <IconFilter size={20} className="me-1" /> Filter
            </Button>,
            <Button
              variant="primary"
              className="ms-1"
              onClick={handleCreateRole}
            >
              <IconPlus size={20} className="me-1" /> Add Permission
            </Button>,
          ]}
        />
        <div className="container-fluid my-4 px-4 rounded">
          <div className="card shadow-sm rounded-3">
            <div className="card-body p-0">
              {/* Card Header: Show Entries and Search */}
              <div className="d-flex justify-content-between align-items-center px-3 py-4">
                <div>
                  <label>
                    Show
                    <select
                      className="form-select form-select-sm mx-2 py-2 ps-3"
                      value={entriesPerPage}
                      onChange={(e) => {
                        setEntriesPerPage(Number(e.target.value));
                        setCurrentPage(1); // Reset to first page when entries per page changes
                      }}
                      style={{ width: "auto", display: "inline-block" }}
                    >
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={30}>30</option>
                      <option value={40}>40</option>
                    </select>
                    entries
                  </label>
                </div>
                <div className="input-group w-25">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                      setCurrentPage(1); // Reset to first page on search
                    }}
                  />
                </div>
              </div>

              {/* Table */}
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Action</th>
                      <th>Role</th>
                      <th>Permission</th>
                      <th>User</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!filteredData.length ? (
                      <tr>
                        <td colSpan="5">No permissions available</td>
                      </tr>
                    ) : (
                      filteredData.map((item, index) => (
                        <tr key={item.id}>
                          <td>{index + 1}</td>
                          <td>
                            <button
                              className="btn btn-primary btn-icon"
                              onClick={() => {
                                handleUpdateRole({ item });
                              }}
                            >
                              <IconEdit size={20} />
                            </button>
                            <button
                              className="btn btn-danger btn-icon ms-2"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDelete(item.id);
                              }}
                            >
                              <IconTrash size={20} />
                            </button>
                            <button
                              className="btn btn-success btn-icon ms-2"
                              onClick={() => {
                                handleSyncPermissions({ item });
                              }}
                            >
                              <IconListDetails size={20} />
                            </button>
                          </td>
                          <td>{item.display_name}</td>
                          <td>
                            {/* {truncateString(item.permissions.toString(), 50)} */}
                            {truncateString(
                              item.permissions
                                .map((perm) => perm.name)
                                .join(", "),
                              50
                            )}
                          </td>
                          {/* <td>
                            {item.users}
                            {Array.isArray(item.users)
                              ? item.users.map((user) => user.name).join(", ")
                              : item.users}
                          </td> */}
                          <td>
                            {Array.isArray(item.users) ? (
                              <>
                                {item.users.map((user, index) => (
                                  <div
                                    key={index}
                                    className="avatar-list avatar-list-stacked d-inline cursor-pointer"
                                  >
                                    <span
                                      className="avatar avatar-sm rounded-circle"
                                      title={user.name}
                                    >
                                      {user.name
                                        .split(" ")
                                        .map((word) => word[0])
                                        .join("")}
                                    </span>
                                  </div>
                                ))}
                                <button
                                onClick={() => {
                                    handleSyncUser({ item });
                                  }} className="btn btn-sm btn-outline-primary avatar-list avatar-list-stacked d-inline cursor-pointer avatar avatar-sm rounded-circle">
                                  +
                                </button>
                              </>
                            ) : (
                              <span>{item.users}</span>
                            )}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>

              {/* Card Footer: Showing entries and Pagination */}
              <div className="d-flex justify-content-between align-items-center px-3 py-4">
                <div>
                  Showing {(currentPage - 1) * entriesPerPage + 1} to{" "}
                  {Math.min(currentPage * entriesPerPage, totalEntries)} of{" "}
                  {totalEntries} entries
                </div>
                {/* Render the pagination component */}
                <div>
                  {fetchData.data?.length > 0 ? paginationComponent : ""}{" "}
                  {/* Menggunakan komponen pagination dari hook */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutDashboard>
    </Suspense>
  );
};

export default RolesPage;
