import React, { useState } from 'react';
import styles from './style.module.css';
import LayoutDashboard from 'layouts/layout-dashboard-collapse';
import { Dropdown } from "react-bootstrap";

// Dummy data for products
const fetchDataProducts = {
    data: [
        {
            category: "Electronics",
            itemCode: "E1001",
            itemName: "Smartphone",
            purchasingPrice: 200,
            sellingPrice: 250,
            stock: 30,
        },
        {
            category: "Electronics",
            itemCode: "E1002",
            itemName: "Laptop",
            purchasingPrice: 800,
            sellingPrice: 1000,
            stock: 15,
        },
        {
            category: "Furniture",
            itemCode: "F1001",
            itemName: "Office Chair",
            purchasingPrice: 100,
            sellingPrice: 150,
            stock: 50,
        },
        {
            category: "Furniture",
            itemCode: "F1002",
            itemName: "Desk",
            purchasingPrice: 250,
            sellingPrice: 300,
            stock: 20,
        },
        {
            category: "Groceries",
            itemCode: "G1001",
            itemName: "Coffee",
            purchasingPrice: 5,
            sellingPrice: 8,
            stock: 100,
        },
        {
            category: "Groceries",
            itemCode: "G1002",
            itemName: "Sugar",
            purchasingPrice: 2,
            sellingPrice: 3,
            stock: 200,
        },
    ],
};

const fetchDataCategories = {
    data: [
        { categoryName: "Electronics" },
        { categoryName: "Furniture" },
        { categoryName: "Groceries" },
        { categoryName: "Clothing" },
        { categoryName: "Footwear" },
    ],
};

const ProdutctTable = () => {
    return (
        <div className={`table-container ${styles.tabContent}`}>
            <div className="search-container mb-3">
                {/* Search input */}
                <div className="input-icon">
                    <input type="text" className="form-control" placeholder="Search..." />
                    <span className="input-icon-addon">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-search"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <circle cx="10" cy="10" r="7" />
                            <line x1="21" y1="21" x2="15" y2="15" />
                        </svg>
                    </span>
                </div>

                {/* Create button */}
                <button className="btn btn-primary">Create</button>
            </div>

            {/* Table */}
            <div className="table">
                <table className="table table-hover table-vcenter">
                    <thead>
                        <tr>
                            <th>Category</th>
                            <th>Item Code</th>
                            <th>Item Name</th>
                            <th>Purchasing Price</th>
                            <th>Selling Price</th>
                            <th>Stock</th>
                            <th className="text-end">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fetchDataProducts.data.map((item, index) => (
                            <tr key={index}>
                                <td>{item.category}</td>
                                <td>{item.itemCode}</td>
                                <td>{item.itemName}</td>
                                <td>${item.purchasingPrice.toFixed(2)}</td>
                                <td>${item.sellingPrice.toFixed(2)}</td>
                                <td>{item.stock}</td>
                                <td className="text-end">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="default" id="dropdownMenuButton1">
                                            Actions
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#">View</Dropdown.Item>
                                            <Dropdown.Item href="#">Edit</Dropdown.Item>
                                            <Dropdown.Item href="#">
                                                Remove
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Pagination */}
            <div className="pagination-container">
                <nav aria-label="Page navigation">
                    <ul className="pagination">
                        <li className="page-item active">
                            <a className="page-link bg-primary" href="#">
                                1
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}

const CategoryTable = () => {
    return (
        <div className={`table-container ${styles.tabContent}`}>
            <div className="search-container mb-3">
                {/* Search input */}
                <div className="input-icon">
                    <input type="text" className="form-control" placeholder="Search..." />
                    <span className="input-icon-addon">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-search"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <circle cx="10" cy="10" r="7" />
                            <line x1="21" y1="21" x2="15" y2="15" />
                        </svg>
                    </span>
                </div>

                {/* Create button */}
                <button className="btn btn-primary">Create</button>
            </div>

            {/* Table */}
            <div className="table">
                <table className="table table-hover table-vcenter">
                    <thead>
                        <tr>
                            <th>Category Name</th>
                            <th className="text-end">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fetchDataCategories.data.map((item, index) => (
                            <tr key={index}>
                                <td>{item.categoryName}</td>
                                <td className="text-end">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="default" id="dropdownMenuButton1">
                                            Actions
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#">View</Dropdown.Item>
                                            <Dropdown.Item href="#">Edit</Dropdown.Item>
                                            <Dropdown.Item href="#">
                                                Remove
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Pagination */}
            <div className="pagination-container">
                <nav aria-label="Page navigation">
                    <ul className="pagination">
                        <li className="page-item active">
                            <a className="page-link bg-primary" href="#">
                                1
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}

const StockInventoryPage = () => {
    const [activeTab, setActiveTab] = useState('product');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <LayoutDashboard>
            <div className="container-fluid">
                {/* Tab navigation for Room Types */}
                <div className={`${styles.tabContainer} mb-3`}>
                    <div className={styles.tabs}>
                        <button
                            className={`${styles.tab} ${activeTab === 'product' ? styles.active : ''}`}
                            onClick={() => handleTabClick('product')}
                        >
                            Product
                        </button>
                        <button
                            className={`${styles.tab} ${activeTab === 'category' ? styles.active : ''}`}
                            onClick={() => handleTabClick('category')}
                        >
                            Category
                        </button>
                    </div>
                </div>
                {activeTab === 'product' && (
                    <ProdutctTable />
                )}

                {activeTab === 'category' && (
                    <CategoryTable />
                )}
            </div>
        </LayoutDashboard>
    );
};

export default StockInventoryPage;
