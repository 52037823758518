import React, { useEffect, useState } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import {
  IconMail,
  IconMapPin,
  IconPhone,
  IconGlobe,
  IconCurrencyDollar,
  IconBuilding,
  IconEdit,
  IconCalendar,
  IconUser,
} from "@tabler/icons-react";
import Suspense from "components/suspense/suspense";
import FullLoader from "components/loader/full-loader";
import LayoutDashboard from "layouts/layout-dashboard-collapse";
import BreadcrumbWithTitle from "components/breadcrumb/breadcrumb-with-title";
import { useFetchData } from "hooks/useApiHooks";
import { URLS } from "utils/constants";

import CreateCanvas from "pages/property-settings/property-management/components/create-property-page";
import { useCanvasHandler } from "hooks/useCanvasHandler";
import { useReload } from "contexts/ReloadContext";

// Reusable component for each data row
const DataRow = ({ icon: Icon, label, value }) => (
  <Row>
    <Col md={6} lg={3} sm={6} xs={5}>
      <Icon size={18} className="me-2" /> {label}
    </Col>
    <Col md={6} lg={9} sm={6} xs={7}>
      : {value || "N/A"}
    </Col>
  </Row>
);

const MyPropertyPage = () => {
  const API_URL = "/my-property";
  const PAGE_TITLE = "Property Details";
  const { reload } = useReload();
  const [propertyData, setPropertyData] = useState(null);
  // Fetch data dari API
  const { data, loading, error } = useFetchData(API_URL, reload);
  const { handleSuccess, handleError, showCanvasHandler } = useCanvasHandler();

  useEffect(() => {
    if (data && data.data) {
      setPropertyData(data.data);
    }
  }, [data]);

  const handleUpdate = (e, data) => {
    e.preventDefault();
    showCanvasHandler(
      "Update Property",
      <CreateCanvas
        onSuccess={handleSuccess}
        onError={handleError}
        initialData={data}
      />
    );
  };

  return (
    <Suspense loader={<FullLoader />} loading={loading}>
      <LayoutDashboard>
        <BreadcrumbWithTitle
          breadcrumbs={[
            { label: "Dashboard", href: "/" },
            {
              label: PAGE_TITLE,
              href: URLS.DASHBOARD_USER_PROPERTIES,
              active: true,
            },
          ]}
          title={PAGE_TITLE}
          extras={[
            <Button
              variant="primary"
              // onClick={handleEditProperty}
              onClick={(e) => handleUpdate(e, propertyData)}
              key="edit-property-btn"
            >
              <IconEdit size={20} className="me-1" /> Edit Property
            </Button>,
          ]}
        />
        <div className="container-fluid my-4 px-4 rounded">
          <h4 className="mb-4">Property Information</h4>
          {error && (
            <div className="alert alert-danger">
              Error loading property data
            </div>
          )}
          {propertyData ? (
            <Row>
              {/* General Information */}
              <Col md={12} lg={12}>
                <Card className="mb-4 shadow-sm">
                  <Card.Header className="bg-primary text-white">
                    General Information
                  </Card.Header>

                  {/* General Information */}
                  <Card.Body>
                    <DataRow
                      icon={IconBuilding}
                      label="Name"
                      value={propertyData.name}
                    />
                    <DataRow
                      icon={IconBuilding}
                      label="Accommodation Type"
                      value={propertyData.accommodation_type_label}
                    />
                    <DataRow
                      icon={IconCurrencyDollar}
                      label="Preferred Currency"
                      value={propertyData.preferred_currency}
                    />
                    <DataRow
                      icon={IconCalendar}
                      label="Time Zone"
                      value={propertyData.time_zone}
                    />
                  </Card.Body>

                  {/* Contact Details */}
                  <Card.Body>
                    <DataRow
                      icon={IconMail}
                      label="Primary Email"
                      value={propertyData.primary_email}
                    />
                    <DataRow
                      icon={IconMail}
                      label="CC Emails"
                      value={propertyData.cc_emails?.join(", ") || "N/A"}
                    />
                    <DataRow
                      icon={IconMail}
                      label="Accounting Email"
                      value={propertyData.accounting_email}
                    />
                    <DataRow
                      icon={IconPhone}
                      label="Mobile Number"
                      value={`${propertyData.mobile_number_country_code} ${propertyData.mobile_number}`}
                    />
                    <DataRow
                      icon={IconPhone}
                      label="Primary Phone"
                      value={`${propertyData.primary_phone_country_code} ${propertyData.primary_phone}`}
                    />
                    <DataRow
                      icon={IconGlobe}
                      label="Website"
                      value={
                        <a
                          href={propertyData.website}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {propertyData.website || "N/A"}
                        </a>
                      }
                    />
                  </Card.Body>

                  {/* Location Information */}
                  <Card.Body>
                    <DataRow
                      icon={IconMapPin}
                      label="Country"
                      value={propertyData.country}
                    />
                    <DataRow
                      icon={IconMapPin}
                      label="State"
                      value={propertyData.state}
                    />
                    <DataRow
                      icon={IconMapPin}
                      label="City"
                      value={propertyData.city}
                    />
                    <DataRow
                      icon={IconMapPin}
                      label="Street Address"
                      value={propertyData.street_address}
                    />
                    <DataRow
                      icon={IconMapPin}
                      label="Postal Code"
                      value={propertyData.postal_code}
                    />
                    <DataRow
                      icon={IconMapPin}
                      label="Latitude / Longitude"
                      value={`${propertyData.latitude}, ${propertyData.longitude}`}
                    />
                    <DataRow
                      icon={IconUser}
                      label="Prefix Booking Number"
                      value={propertyData.prefix_booking_number}
                    />
                    <DataRow
                      icon={IconUser}
                      label="Hotel Tax Number"
                      value={propertyData.hotel_tax_number}
                    />
                  </Card.Body>

                  {/* Social Media Links */}
                  {/* <Card.Body>
                    <DataRow
                      icon={IconGlobe}
                      label="Facebook"
                      value={
                        <a
                          href={propertyData.facebook_link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {propertyData.facebook_link || "N/A"}
                        </a>
                      }
                    />
                    <DataRow
                      icon={IconGlobe}
                      label="Instagram"
                      value={
                        <a
                          href={propertyData.instagram_link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {propertyData.instagram_link || "N/A"}
                        </a>
                      }
                    />
                    <DataRow
                      icon={IconGlobe}
                      label="YouTube"
                      value={
                        <a
                          href={propertyData.youtube_link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {propertyData.youtube_link || "N/A"}
                        </a>
                      }
                    />
                    <DataRow
                      icon={IconGlobe}
                      label="TikTok"
                      value={
                        <a
                          href={propertyData.tiktok_link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {propertyData.tiktok_link || "N/A"}
                        </a>
                      }
                    />
                  </Card.Body> */}
                </Card>
              </Col>
            </Row>
          ) : (
            <div>Loading property details...</div>
          )}
        </div>
      </LayoutDashboard>
    </Suspense>
  );
};

export default MyPropertyPage;
