import React, { useState } from 'react';
import styles from './sidebar.module.css';
import { IconHome, IconChartBar, IconCoin, IconTrendingUp, IconBuilding, IconReport } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { URLS } from 'utils/constants';

const ReportsSidebar = () => {
    const [collapsed, setCollapsed] = useState(true);

    const toggleSidebar = () => {
        setCollapsed(!collapsed);
    };

    return (
        <div className={`${styles.sidebar} ${collapsed ? styles.collapsed : ''}`}>
            <button onClick={toggleSidebar} className={styles.sidebarToggle}>
                <span className={styles.chevron}>{collapsed ? '»' : '«'}</span>
            </button>
            <Link to={URLS.DASHBOARD_ADMIN_RESERVATION_REPORTS}>
                <IconHome size={24} className={styles.icon} />
                <span className={styles.text}>Dashboard</span>
            </Link>
            <Link to={URLS.DASHBOARD_ADMIN_RESERVATION_REPORTS_OCCUPANCY}>
                <IconBuilding size={24} className={styles.icon} />
                <span className={styles.text}>Occupancy Report</span>
            </Link>
            <Link to={URLS.DASHBOARD_ADMIN_RESERVATION_REPORTS_AGENT}>
                <IconReport size={24} className={styles.icon} />
                <span className={styles.text}>Agents Report</span>
            </Link>
            <Link to={URLS.DASHBOARD_ADMIN_RESERVATION_REPORTS_REVENUE}>
                <IconCoin size={24} className={styles.icon} />
                <span className={styles.text}>Revenue Report</span>
            </Link>
            <Link to={URLS.DASHBOARD_ADMIN_RESERVATION_REPORTS_SALES}>
                <IconTrendingUp size={24} className={styles.icon} />
                <span className={styles.text}>Sales Report</span>
            </Link>
        </div>
    );
};

export default ReportsSidebar;
