import PropertyListPage from 'pages/property-settings/property-management/property-management-page';
import RatePage from 'pages/property-settings/rate-settings/rate-page';
import React from 'react';
import { URLS } from 'utils/constants';

export const propertySettings = [
    {
        path: URLS.DASHBOARD_ADMIN_PROPERTY_SETTINGS_PROPERTY_MANAGEMENT,
        element: <PropertyListPage />
    },
    {
        path: URLS.DASHBOARD_ADMIN_PROPERTY_SETTINGS_RATE,
        element: <RatePage />
    }
];