import React, { useEffect, useState } from "react";
import CurrencyInput from "components/form/currency-input";
import { getRequest } from "services/api";
import Select from 'react-select';

export const RoomType = ({ formData, setFormData }) => {
    const [roomTypes, setRoomTypes] = useState({
        data: [],
        isLoading: true,
        selected: null,
        success: false,
    });

    const fetchRoomTypes = async (url) => {
        try {
            const result = await getRequest(url);
            if (result.success) {
                return { success: true, data: result.data };
            }
        } catch (error) {
            return { success: false, error: error.message };
        }
    };

    useEffect(() => {
        setRoomTypes({
            ...roomTypes,
            isLoading: true,
        });

        fetchRoomTypes(`/room-type?all=1&date=${formData?.arrival_date}`).then((result) => {
            if (result.success) {
                console.log('fetchRooms', result)
                setRoomTypes({
                    data: result.data,
                    isLoading: false,
                    selected: null,
                    success: true,
                });
            } else {
                setRoomTypes({
                    data: [],
                    isLoading: false,
                    selected: null,
                    success: false,
                });
            }
        });
    }, [formData.arrival_date]);


    return (
        formData.room_rate.length > 0 && formData.room_rate.map((room, roomIndex) => (
            <RoomRate key={roomIndex} index={roomIndex} roomTypes={roomTypes} formData={formData} setFormData={setFormData} />
        ))
    );
};

const RatePlan = ({ setSelectedData, selectedData, setFormData, formData }) => {
    const [ratePlans, setRatePlans] = useState({
        data: [],
        isLoading: true,
        selected: null,
        success: false,
    });

    const fetchRoomTypes = async (url) => {
        try {
            const result = await getRequest(url);
            if (result.success) {
                return { success: true, data: result.data };
            }
        } catch (error) {
            return { success: false, error: error.message };
        }
    };

    useEffect(() => {
        if (formData.room_rate[selectedData.index].rate) {
            setRatePlans({
                ...ratePlans,
                isLoading: true,
                selected: { value: formData.room_rate[selectedData.index].raw_data.rate_plan.id, label: formData.room_rate[selectedData.index].raw_data.rate_plan.name },
            });

            fetchRoomTypes(`/rate-plan?all=1&room_type_id=${selectedData.room_type?.value}`).then((result) => {
                if (result.success) {
                    setRatePlans((prev) => {
                        return {
                            ...prev,
                            data: result.data,
                            isLoading: false,
                            success: true,
                        };
                    }); // This line is not needed
                } else {
                    setRatePlans((prev) => {
                        return {
                            ...prev,
                            data: [],
                            isLoading: false,
                            success: false,
                        };
                    });
                }
            });

            const price = formData.room_rate[selectedData.index].rates;
            const raw_data = formData.room_rate[selectedData.index].raw_data.rate_plan;

            if (price.length > 0) {
                setSelectedData((prev) => ({ ...prev, price }));
            } else {
                setSelectedData((prev) => ({ ...prev, price: prev.price.map((rate) => ({ date: rate.date, rate: raw_data.rate })) }));
            }
        } else {
            setRatePlans({
                ...ratePlans,
                isLoading: true,
            });

            fetchRoomTypes(`/rate-plan?all=1&room_type_id=${selectedData.room_type?.value}`).then((result) => {
                if (result.success) {
                    setRatePlans({
                        data: result.data,
                        isLoading: false,
                        selected: null,
                        success: true,
                    });
                } else {
                    setRatePlans({
                        data: [],
                        isLoading: false,
                        selected: null,
                        success: false,
                    });
                }
            });
        }
    }, [selectedData.room_type]);

    return (
        <div className="row mb-3 align-items-center">
            <label htmlFor='rate_plan' className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Rate:</label>
            <div className="col-sm-8">
                {selectedData.room_type ? <Select
                    className="djitu-form-control rounded-1"
                    id='rate_plan'
                    name='rate_plan'
                    value={ratePlans.selected}
                    onChange={selected => {
                        setRatePlans({ ...ratePlans, selected });
                        setSelectedData((prev) => ({ ...prev, price: prev.price.map((rate) => ({ ...rate, rate: ratePlans.data.find((rateplan) => rateplan.id === selected.value).rate })) }));
                        setFormData((prev) => {
                            const newRoomRate = [...prev.room_rate];
                            newRoomRate[selectedData.index].rate = selected.value;
                            newRoomRate[selectedData.index].rates = selectedData.price.map((rate) => {
                                return {
                                    date: rate.date,
                                    rate: ratePlans.data.find((rateplan) => rateplan.id === selected.value).rate,
                                };
                            });
                            return {
                                ...prev,
                                room_rate: newRoomRate,
                            };
                        });
                    }}
                    options={ratePlans.data.map((ratePlan) => ({ value: ratePlan.id, label: ratePlan.name }))}
                    // disabled={isSubmitting}
                    style={{ fontSize: '14px', height: '35px' }}
                /> : 'Select room type first'}
            </div>
        </div>
    );
};

const RoomRate = ({ roomTypes, index, formData, setFormData }) => {
    const [selectedData, setSelectedData] = useState({
        index: index,
        room_type: null,
        room_number: null,
        price: [],
    });

    const handleChangePriceRate = (event, index) => {
        const { name, value } = event.target;

        setSelectedData(prevState => {
            const newRates = [...prevState.price];
            newRates[index].rate = value;
            return {
                ...prevState,
                price: newRates,
            };
        });
    };

    const getDatesBetween = (startDate, endDate) => {
        const dates = [];
        let currentDate = new Date(startDate);
        const end = new Date(endDate);
        end.setDate(end.getDate() - 1);

        while (currentDate <= end) {
            dates.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return dates;
    };

    const setupRoomRatePerDate = () => {
        return setSelectedData((prev) => ({
            ...prev,
            price: [
                ...getDatesBetween(formData.arrival_date, formData.departure_date).map((date) => {
                    return {
                        date: date.toISOString().split('T')[0],
                        rate: '',
                    };
                }),
            ],
        }));
    };

    useEffect(() => {
        if (formData.room_rate[index].room_type_id) {
            const roomType = roomTypes.data.find((type) => type.id === formData.room_rate[index].room_type_id);
            const roomNumber = roomType ? { value: formData.room_rate[index].raw_data.room.id, label: formData.room_rate[index].raw_data.room.room_number } : null;
            console.log('roomNumber', roomNumber);
            setSelectedData((prev) => ({
                ...prev,
                room_type: roomType ? { value: roomType.id, label: roomType.name } : null,
                room_number: roomNumber,
            }));
        }

        setupRoomRatePerDate();
    }, [roomTypes, formData.arrival_date, formData.departure_date]);

    return (
        <>
            <div className="row mb-3 align-items-center">
                <h3 className="mt-2 ms-4 border-bottom fw-normal fs-4 d-flex justify-content-between">Room {index + 1}
                    <button className="btn btn-link text-danger p-0 me-2" onClick={() => setFormData((prev) => {
                        const newRoomRate = [...prev.room_rate];
                        newRoomRate.splice(index, 1);
                        return {
                            ...prev,
                            room_rate: newRoomRate,
                        };
                    })}>Remove</button>
                </h3>
            </div>
            {/* Room Type */}
            <div className="row mb-3 align-items-center">
                <label htmlFor='room_type' className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Room Type:</label>
                <div className="col-sm-8">
                    <Select
                        className="djitu-form-control rounded-1"
                        id='room_type'
                        name='room_type'
                        value={selectedData.room_type}
                        onChange={selected => {
                            setSelectedData({ ...selectedData, room_type: selected });
                            setFormData((prev) => {
                                const newRoomRate = [...prev.room_rate];
                                newRoomRate[index].room_type_id = selected.value;
                                return {
                                    ...prev,
                                    room_rate: newRoomRate,
                                };
                            });
                        }}
                        options={roomTypes.data.map((roomType) => ({ value: roomType.id, label: roomType.name }))}
                        // disabled={isSubmitting}
                        style={{ fontSize: '14px', height: '35px' }}
                    ></Select>
                </div>
            </div>

            {/* Room Number */}
            <div className="row mb-3 align-items-center">
                <label htmlFor='room_number' className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Room Number:</label>
                <div className="col-sm-8">
                    {selectedData.room_type ? (
                        <Select
                            className="djitu-form-control rounded-1"
                            id='room_number'
                            name='room_number'
                            value={selectedData.room_number}
                            onChange={selected => {
                                setSelectedData({ ...selectedData, room_number: selected });
                                setFormData((prev) => {
                                    const newRoomRate = [...prev.room_rate];
                                    newRoomRate[index].room_number = selected.value;
                                    return {
                                        ...prev,
                                        room_rate: newRoomRate,
                                    };
                                });
                            }}
                            options={roomTypes.data.find((type) => type.id === selectedData.room_type?.value)?.rooms.map((room) => ({ value: room.id, label: room.room_number }))}
                            // disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                        />
                    ) : 'Select room type first'}
                </div>
            </div>

            {/* Room Rate Type */}
            <RatePlan setSelectedData={setSelectedData} selectedData={selectedData} setFormData={setFormData} formData={formData} />

            {selectedData.price.length > 0 && (
                <>
                    <div className="row mb-3 align-items-center">
                        <div className="d-flex justify-content-end">
                            <h3 className="mt-2 ms-4 border-bottom fw-normal fs-4 col-sm-9">Rates</h3>
                        </div>
                    </div>

                    {selectedData.price.map((rate, rateIndex) => (
                        <div key={rateIndex} className="row mb-3 align-items-center">
                            <label htmlFor={`rates-${rateIndex}`} className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>{rate.date}:</label>
                            <div className="col-sm-8">
                                <CurrencyInput
                                    name={`rates-${rateIndex}`}
                                    className="form-control djitu-form-control rounded-1"
                                    value={rate.rate}
                                    onChange={(e, rawValue) => {
                                        handleChangePriceRate({ target: { name: `rates-${rateIndex}`, value: rawValue } }, rateIndex);
                                        setFormData((prev) => {
                                            const newRoomRate = [...prev.room_rate];
                                            newRoomRate[index].rates[rateIndex].rate = rawValue;
                                            return {
                                                ...prev,
                                                room_rate: newRoomRate,
                                            };
                                        });
                                    }} // Simpan rawValue
                                    // error={formError?.room_rate?.[index]?.rates?.[rateIndex]?.rate}
                                    placeholder="Rp. 0"
                                />
                            </div>
                        </div>
                    ))}
                </>
            )}
        </>
    )
};