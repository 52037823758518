import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useCreateData } from 'hooks/useApiHooks'; // Import hook
import dayjs from 'dayjs'; // Untuk format tanggal

const FilterForm = ({ selectedEvent, onFormSubmit, clearSelection }) => {
    const [formType, setFormType] = useState(null);
    const [formData, setFormData] = useState({});
    const [isDisabled, setIsDisabled] = useState(true); // Tambahkan state untuk disable/enable form

    // Menggunakan hook useUpdateData untuk update ke API
    const { createData, loading } = useCreateData('/monthly-setup');

    useEffect(() => {
        if (selectedEvent && selectedEvent.type) {
            const eventType = selectedEvent.type === 'isRoom' ? 'availability' : 'rates';
            setFormType(eventType);
            setIsDisabled(false); // Enable form ketika ada event terpilih
        } else {
            setIsDisabled(true); // Disable form jika tidak ada event terpilih
        }
    }, [selectedEvent]);

    // Fungsi untuk memformat data yang akan dikirim ke API
    const formatApiData = () => {
        // Ambil data dari selectedEvent yang merupakan array
        const roomTypes = selectedEvent.data.map(event => event.additionalData.roomId);
        console.log(roomTypes);
        const dates = selectedEvent.data.map(event => dayjs(event.start).format('YYYY-MM-DD'));

        // Membentuk data sesuai dengan format API
        const dataToUpdate = {
            type: formType, // Menggunakan type dari form (rates atau availability)
            value: formData[formType], // Nilai dari input text
            room_type: roomTypes, // Mengambil roomId dari setiap event
            date: dates, // Mengambil tanggal dari setiap event
        };

        return dataToUpdate;
    };

    // Fungsi untuk handle submit
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Format data yang akan dikirim
        const apiData = formatApiData();

        const response = await createData(apiData); // Gunakan updateData dari hook

        if (response.success) {
            console.log('Event updated:', response.data);
            // Ketika API sukses, reload FullCalendar melalui parent
            onFormSubmit();
        } else {
            console.error('Error updating event:', response.errorResponse);
        }
    };

    // Fungsi untuk clear selection
    const handleClear = () => {
        setFormType(null);
        setFormData({});
        setIsDisabled(true); // Disable form setelah di-clear
        clearSelection(); // Panggil fungsi clearSelection dari parent
    };

    return (
        <div className="card p-3 rounded-0">
            <h5 className="card-title">Filter Form</h5>
            <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                    {/* Bagian pertama: Select, Input Text, dan Submit */}
                    <Col md={4}>
                        <Form.Group className="mb-3">
                            <Form.Select
                                value={formType || ''}
                                onChange={(e) => setFormType(e.target.value)}
                            >
                                <option value='rate'>Rates</option>
                                <option value='availability'>Availability</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group className="mb-3">
                            <Form.Control
                                type="text"
                                placeholder="Enter value"
                                value={formData[formType] || ''}
                                onChange={(e) => setFormData({ ...formData, [formType]: e.target.value })}
                                disabled={isDisabled} // Disable form jika isDisabled true
                            />
                        </Form.Group>
                    </Col>
                    <Col md={4} className="d-flex align-items-start">
                        <Button
                            type="submit"
                            variant="primary"
                            className="w-100"
                            disabled={isDisabled || loading} // Disable saat isDisabled true atau sedang submit
                        >
                            {loading ? 'Submitting...' : 'Submit'}
                        </Button>
                    </Col>
                </Row>

                <Row className="mt-3">
                    {/* Bagian kedua: Button Clear dan Checkbox hari */}
                    <Col md={3} className="d-flex align-items-center">
                        <Button
                            variant="outline-secondary"
                            className="me-2"
                            onClick={handleClear}
                            disabled={isDisabled} // Disable jika form dalam keadaan disabled
                        >
                            Clear Selection
                        </Button>
                    </Col>
                    <Col md={9}>
                        <Form.Group className="d-flex justify-content-between">
                            <Form.Check inline type="checkbox" label="Mon" className='me-0' />
                            <Form.Check inline type="checkbox" label="Tue" className='me-0' />
                            <Form.Check inline type="checkbox" label="Wed" className='me-0' />
                            <Form.Check inline type="checkbox" label="Thu" className='me-0' />
                            <Form.Check inline type="checkbox" label="Fri" className='me-0' />
                            <Form.Check inline type="checkbox" label="Sat" className='me-0' />
                            <Form.Check inline type="checkbox" label="Sun" className='me-0' />
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default FilterForm;
