import React from 'react';
import { Card } from 'react-bootstrap';

const MetricsCard = ({ title, data, datalast }) => {
  // Formatter untuk nilai uang dalam IDR (Rp) jika title adalah "Total Revenue"
  const formatValue = (value) =>
    title === "Total Revenue"
      ? new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value)
      : value.toLocaleString('id-ID');

  return (
    <Card className="mb-3 shadow-sm card-metric" style={{ borderRadius: '10px', border: 'none' }}>
      <Card.Body>
        <Card.Title className="text-muted">{title}</Card.Title>
        <h2 className="display-4">{formatValue(data)}</h2> {/* Format dengan simbol Rp jika title adalah "Total Revenue" */}
        <p className="text-muted">
          vs last month: {formatValue(datalast)}
        </p>
      </Card.Body>
    </Card>
  );
};

export default MetricsCard;
