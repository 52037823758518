import { useLoading } from 'contexts/LoadingContext';
import React from 'react';

const Suspense = ({ children, loading, loader, type = 'full' }) => {
  const { loadingType } = useLoading();

  return (
    <>
      {loading & loadingType === type ? loader : children}
    </>
  );
};

export default Suspense;