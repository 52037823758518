import { Link } from 'react-router-dom';

export const LogoURL = '/djituhs.jpg';

export default function Logo({ className = "navbar-brand-image", ...rest }) {
    return (
        <Link to='/'>
            <img src={LogoURL} alt="Logo" className={className} {...rest} />
        </Link>
    );
}