import { AuthProvider } from 'contexts/AuthContext';
import { LoadingProvider } from 'contexts/LoadingContext';
import { ModalProvider } from 'contexts/modal-context';
import RedirectIfLoggedIn from 'middleware/RedirectIfLoggedIn';
import Login from 'pages/auth/login';
import { URLS } from 'utils/constants';

export const authRouter = [
    {
        path: URLS.LOGIN,
        element: (
            <AuthProvider>
                <LoadingProvider>
                    <RedirectIfLoggedIn />
                </LoadingProvider>
            </AuthProvider>
        ),
        children: [
            {
                path: URLS.LOGIN,
                element: (
                    <ModalProvider>
                        <Login />
                    </ModalProvider>
                ),
            },
        ],
    },
];