import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import styles from './RevenueReport.module.css'; // Modular CSS untuk styling
import ReportsPage from '../../reports-page';
import { formatPrice } from 'utils/utils';

const RevenueReport = () => {
    // State untuk properti dan tipe kamar yang dipilih
    const [selectedProperty, setSelectedProperty] = useState('VIENNA VILLAS SEMINYAK');
    const [selectedRooms, setSelectedRooms] = useState({
        'Vienna Villa 2BR': true,
        'Vienna Villa 4BR': true,
    });
    // State untuk mengelola pilihan "Based On"
    const [basedOn, setBasedOn] = useState('stay-dates');

    // Dummy data untuk laporan bulanan per agent
    const reportData = [
        { agent: 'Booking.com', january: 15000, february: 18000, march: 20000, april: 22000, may: 25000, june: 27000, july: 30000, august: 32000, september: 31000, october: 34000, november: 36000, december: 40000 },
        { agent: 'Freelance', january: 8000, february: 9000, march: 12000, april: 15000, may: 16000, june: 18000, july: 20000, august: 22000, september: 25000, october: 28000, november: 29000, december: 32000 },
        { agent: 'Travel Agent', january: 10000, february: 12000, march: 15000, april: 18000, may: 20000, june: 22000, july: 24000, august: 26000, september: 28000, october: 30000, november: 35000, december: 37000 },
        // Tambahkan data lainnya jika perlu...
    ];

    // State untuk data yang ditampilkan (sesuai filter)
    const [filteredData, setFilteredData] = useState(reportData);

    // Fungsi untuk handle perubahan property
    const handlePropertyChange = (event) => {
        setSelectedProperty(event.target.value);
        filterData(event.target.value, selectedRooms);
    };

    // Fungsi untuk handle perubahan tipe kamar
    const handleRoomChange = (roomType) => {
        const updatedRooms = { ...selectedRooms, [roomType]: !selectedRooms[roomType] };
        setSelectedRooms(updatedRooms);
        filterData(selectedProperty, updatedRooms);
    };

    // Fungsi untuk filter data berdasarkan properti dan tipe kamar
    const filterData = (property, rooms) => {
        const filtered = reportData.filter(item => {
            return item.property === property && rooms[item.roomType];
        });
        setFilteredData(filtered);
    };

    // Fungsi Dummy untuk ekspor PDF
    const handleExportPDF = () => {
        alert('Laporan telah diekspor dalam format PDF!');
    };

    // Fungsi Dummy untuk ekspor Excel
    const handleExportExcel = () => {
        alert('Laporan telah diekspor dalam format Excel!');
        const header = ["Agent Name", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const rows = reportData.map(row => [
            row.agent,
            row.january,
            row.february,
            row.march,
            row.april,
            row.may,
            row.june,
            row.july,
            row.august,
            row.september,
            row.october,
            row.november,
            row.december
        ]);

        // Membuat CSV
        let csvContent = "data:text/csv;charset=utf-8,"
            + [header, ...rows].map(e => e.join(",")).join("\n");

        // Mengkonversi CSV ke URL
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "occupancy_report.csv");
        document.body.appendChild(link); // Diperlukan untuk Firefox

        link.click(); // Mengklik link untuk mengunduh file
    };

    // Handle perubahan pada radio button
    const handleBasedOnChange = (e) => {
        setBasedOn(e.target.value);
    };

    return (
        <ReportsPage>
            <div className={styles.container}>
                {/* Bagian Filter */}
                <div className="row mb-4 align-items-center">
                    <div className="col-md-4">
                        <label htmlFor="select-property" className="form-label">Select Property:</label>
                        <select className="form-select" id="select-property" value={selectedProperty} onChange={handlePropertyChange}>
                            <option value="VIENNA VILLAS SEMINYAK">VIENNA VILLAS SEMINYAK</option>
                            {/* Tambahkan properti lainnya jika perlu */}
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="select-year" className="form-label">Select Year:</label>
                        <select className="form-select" id="select-year">
                            <option>2024</option>
                            {/* Tambahkan tahun lainnya jika perlu */}
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="date-range" className="form-label">Select Range:</label>
                        <div className="d-flex">
                            <input type="date" className="form-control me-2" id="start-date" value="2024-09-14" />
                            <span className="mx-2">to</span>
                            <input type="date" className="form-control" id="end-date" value="2024-10-14" />
                        </div>
                    </div>
                </div>

                <div className="mb-4">
                    <label className="form-label me-2">Report Based On:</label>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            id="stay-dates"
                            value="stay-dates"
                            checked={basedOn === 'stay-dates'}
                            onChange={handleBasedOnChange}
                        />
                        <label className="form-check-label" htmlFor="stay-dates">
                            Stay Dates
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            id="created-reservation"
                            value="created-reservation"
                            checked={basedOn === 'created-reservation'}
                            onChange={handleBasedOnChange}
                        />
                        <label className="form-check-label" htmlFor="created-reservation">
                            Created Reservation
                        </label>
                    </div>
                </div>

                {/* Pilihan Tipe Kamar */}
                <div className="row mb-4">
                    <div className="col-md-6">
                        <label className="form-label">Select Room Types:</label>
                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="villa-2br"
                                checked={selectedRooms['Vienna Villa 2BR']}
                                onChange={() => handleRoomChange('Vienna Villa 2BR')}
                            />
                            <label className="form-check-label" htmlFor="villa-2br">Vienna Villa 2BR</label>
                        </div>
                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="villa-4br"
                                checked={selectedRooms['Vienna Villa 4BR']}
                                onChange={() => handleRoomChange('Vienna Villa 4BR')}
                            />
                            <label className="form-check-label" htmlFor="villa-4br">Vienna Villa 4BR</label>
                        </div>
                    </div>
                    <div className="col-md-6 text-end">
                        <button className="btn btn-primary me-2" onClick={handleExportPDF}>Export to PDF</button>
                        <button className="btn btn-success" onClick={handleExportExcel}>Export to Excel</button>
                    </div>
                </div>

                {/* Bagian Tabel */}
                <div className={`${styles.card} bg-white`}>
                    <h6 className={styles.moduleHeader}>Revenue Report</h6>
                    <div className="table-responsive">
                        <Table bordered hover className="text-center">
                            <thead>
                                <tr>
                                    <th>Agent Name</th>
                                    <th>January</th>
                                    <th>February</th>
                                    <th>March</th>
                                    <th>April</th>
                                    <th>May</th>
                                    <th>June</th>
                                    <th>July</th>
                                    <th>August</th>
                                    <th>September</th>
                                    <th>October</th>
                                    <th>November</th>
                                    <th>December</th>
                                </tr>
                            </thead>
                            <tbody>
                                {reportData.map((row, index) => (
                                    <tr key={index}>
                                        <td>{row.agent}</td>
                                        <td>{formatPrice(row.january)}</td>
                                        <td>{formatPrice(row.february)}</td>
                                        <td>{formatPrice(row.march)}</td>
                                        <td>{formatPrice(row.april)}</td>
                                        <td>{formatPrice(row.may)}</td>
                                        <td>{formatPrice(row.june)}</td>
                                        <td>{formatPrice(row.july)}</td>
                                        <td>{formatPrice(row.august)}</td>
                                        <td>{formatPrice(row.september)}</td>
                                        <td>{formatPrice(row.october)}</td>
                                        <td>{formatPrice(row.november)}</td>
                                        <td>{formatPrice(row.december)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </ReportsPage>
    );
};

export default RevenueReport;
