import React from "react";
import clsx from "clsx";

// Helper untuk format ke Rupiah
const formatRupiah = (value) => {
    if (!value) return '';
    // Gunakan Intl.NumberFormat untuk format Rupiah
    return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        maximumFractionDigits: 0, // Hanya tampilkan angka bulat, tanpa koma
    }).format(value).replace('Rp', 'Rp.').trim();
};

// Komponen CurrencyInput
const CurrencyInput = ({ name, className, value, onChange, error, placeholder }) => {
    // Handle perubahan input
    const handleInputChange = (e) => {
        const inputValue = e.target.value.replace(/[^0-9]/g, ''); // Hanya angka yang boleh
        onChange(e, inputValue); // Kirim angka murni ke parent
    };

    return (
        <>
            <input
                type="text"
                id={name}
                className={clsx("form-control", error && 'is-invalid', className)}
                value={formatRupiah(value)} // Tampilkan dalam format Rupiah
                onChange={handleInputChange} // Handle perubahan input
                placeholder={placeholder || 'Rp. 0'} // Placeholder default
            />
            {error && <div className="invalid-feedback">{error}</div>}
        </>
    );
};

export default CurrencyInput;
