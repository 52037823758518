// hooks/useCanvasHandler.js
import { useState } from 'react';
import Swal from 'sweetalert2';

// Importing Contexts
import { useCanvas } from 'contexts/canvas-context';
import { useReload } from 'contexts/ReloadContext';

export const useCanvasHandler = () => {
    // Contexts
    const { triggerReload } = useReload();
    const { showCanvas, hideCanvas } = useCanvas();

    const handleSuccess = (withAlert = true, customMessage = "Data has been successfully saved") => {
        hideCanvas();
        if (withAlert) {
            Swal.fire({
                icon: "success",
                title: "Success!",
                text: customMessage
            });
        }
        triggerReload();
    };

    const handleError = (withAlert = true, customMessage = "An error occurred!") => {
        hideCanvas();
        if (withAlert) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: customMessage
            });
        }
    };

    const showCanvasHandler = (title, bodyComponent, className) => {
        showCanvas({
            title: title,
            body: bodyComponent,
            className: className,
        });
    };

    return {
        handleSuccess,
        handleError,
        showCanvasHandler
    };
};
