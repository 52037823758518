import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useFormChangeHandler } from "hooks/useFormChangeHandler";
import { useSubmitHandler } from "hooks/useSubmitHandler";
import { useCreateData, useUpdateData } from "hooks/useApiHooks";
import CurrencyInput from "components/form/currency-input";

const CreateRatePage = ({ initialData, onSuccess, onError, roomId }) => {
    const API_URL = '/rate-plan';

    // State untuk menyimpan form data. Jika initialData tersedia, isi form untuk update
    const [formData, setFormData] = useState({
        room_type: [roomId] || [],
        name: initialData?.name || '',
        description: initialData?.description || '',
        max_night: initialData?.max_night || '2',
        min_night: initialData?.min_night || '1',
        guest_include: initialData?.guest_include || '1',
        guest_max: initialData?.guest_max || '2',
        extra_adult: initialData?.extra_adult || '2',
        extra_child: initialData?.extra_child || '2',
        extra_adult_rate: initialData?.extra_adult_rate || '',
        extra_child_rate: initialData?.extra_child_rate || '',
        rate: initialData?.rate || '',
        min_rate: initialData?.min_rate || ''
    });

    const isUpdateMode = !!initialData; // Cek apakah ini update mode atau create mode

    // API hook untuk create dan update
    const { createData: createData, loading: creatingProduct } = useCreateData(API_URL);
    const { updateData: updateData, loading: updatingProduct } = useUpdateData(`${API_URL}/${initialData?.id}`);

    // Gunakan hook untuk menangani perubahan form
    const handleChange = useFormChangeHandler(setFormData);

    // Gunakan hook untuk menangani pengiriman form
    const { handleSubmit, isSubmitting, formError } = useSubmitHandler(
        isUpdateMode ? updateData : createData, // Gunakan updateData jika dalam mode update
        onSuccess,
        onError
    );

    // Jika initialData ada, isi ulang form dengan data dari initialData
    useEffect(() => {
        if (initialData) {
            setFormData({
                room_type: [roomId] || [],
                name: initialData.name,
                description: initialData.description,
                max_night: initialData.max_night,
                min_night: initialData.min_night,
                guest_include: initialData.guest_include,
                guest_max: initialData.guest_max,
                extra_adult: initialData.extra_adult,
                extra_child: initialData.extra_child,
                extra_adult_rate: initialData.extra_adult_rate,
                extra_child_rate: initialData.extra_child_rate,
                rate: initialData.rate,
                min_rate: initialData.min_rate
            });
        }
    }, [initialData]);

    return (
        <form onSubmit={(e) => handleSubmit(formData, e)} className="d-flex flex-column" style={{ height: '100%' }}>
            <div className="action-sheet-content flex-grow-1">
                {/* Name */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="name" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Name: </label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className={clsx("form-control djitu-form-control rounded-1", formError?.name && 'is-invalid')}
                            id="name"
                            placeholder="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                        />
                        {formError?.name && <div className="invalid-feedback">{formError.name}</div>}
                    </div>
                </div>

                {/* Description */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="description" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Description: </label>
                    <div className="col-sm-8">
                        <textarea
                            className={clsx("form-control djitu-form-control rounded-1", formError?.description && 'is-invalid')}
                            id="description"
                            placeholder="Description"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px' }}
                        />
                        {formError?.description && <div className="invalid-feedback">{formError.description}</div>}
                    </div>
                </div>

                <h3 className="mt-6 border-bottom fw-normal">Rate Settings</h3>
                {/* Max Night */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="max_night" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Max Night: </label>
                    <div className="col-sm-8">
                        <input
                            type="number"
                            className={clsx("form-control djitu-form-control rounded-1", formError?.max_night && 'is-invalid')}
                            id="max_night"
                            placeholder="Max Night"
                            name="max_night"
                            value={formData.max_night}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                            onWheel={(e) => e.target.blur()} // Disable scroll on number input
                        />
                        {formError?.max_night && <div className="invalid-feedback">{formError.max_night}</div>}
                    </div>
                </div>

                {/* Min Night */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="min_night" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Min Night: </label>
                    <div className="col-sm-8">
                        <input
                            type="number"
                            className={clsx("form-control djitu-form-control rounded-1", formError?.min_night && 'is-invalid')}
                            id="min_night"
                            placeholder="Min Night"
                            name="min_night"
                            value={formData.min_night}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                            onWheel={(e) => e.target.blur()} // Disable scroll on number input
                        />
                        {formError?.min_night && <div className="invalid-feedback">{formError.min_night}</div>}
                    </div>
                </div>

                {/* Guest Include */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="guest_include" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Guest Include: </label>
                    <div className="col-sm-8">
                        <input
                            type="number"
                            className={clsx("form-control djitu-form-control rounded-1", formError?.guest_include && 'is-invalid')}
                            id="guest_include"
                            placeholder="Guest Include"
                            name="guest_include"
                            value={formData.guest_include}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                            onWheel={(e) => e.target.blur()} // Disable scroll on number input
                        />
                        {formError?.guest_include && <div className="invalid-feedback">{formError.guest_include}</div>}
                    </div>
                </div>

                {/* Guest Max */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="guest_max" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Guest Max: </label>
                    <div className="col-sm-8">
                        <input
                            type="number"
                            className={clsx("form-control djitu-form-control rounded-1", formError?.guest_max && 'is-invalid')}
                            id="guest_max"
                            placeholder="Guest Max"
                            name="guest_max"
                            value={formData.guest_max}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                            onWheel={(e) => e.target.blur()} // Disable scroll on number input
                        />
                        {formError?.guest_max && <div className="invalid-feedback">{formError.guest_max}</div>}
                    </div>
                </div>

                {/* Extra Adult */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="extra_adult" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Extra Adult: </label>
                    <div className="col-sm-8">
                        <input
                            type="number"
                            className={clsx("form-control djitu-form-control rounded-1", formError?.extra_adult && 'is-invalid')}
                            id="extra_adult"
                            placeholder="Extra Adult"
                            name="extra_adult"
                            value={formData.extra_adult}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                            onWheel={(e) => e.target.blur()} // Disable scroll on number input
                        />
                        {formError?.extra_adult && <div className="invalid-feedback">{formError.extra_adult}</div>}
                    </div>
                </div>

                {/* Extra Child */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="extra_child" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Extra Child: </label>
                    <div className="col-sm-8">
                        <input
                            type="number"
                            className={clsx("form-control djitu-form-control rounded-1", formError?.extra_child && 'is-invalid')}
                            id="extra_child"
                            placeholder="Extra Child"
                            name="extra_child"
                            value={formData.extra_child}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                            onWheel={(e) => e.target.blur()} // Disable scroll on number input
                        />
                        {formError?.extra_child && <div className="invalid-feedback">{formError.extra_child}</div>}
                    </div>
                </div>

                <h3 className="mt-6 border-bottom fw-normal">Rate Price Settings</h3>
                {/* Extra Adult Rate */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="extra_adult_rate" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Extra Adult Rate: </label>
                    <div className="col-sm-8">
                        <CurrencyInput
                            name="extra_adult_rate"
                            className={clsx("djitu-form-control rounded-1", formError?.extra_adult_rate && 'is-invalid')}
                            value={formData.extra_adult_rate} // Bind value ke formData
                            onChange={(e, rawValue) => handleChange({ target: { name: 'extra_adult_rate', value: rawValue } })} // Simpan rawValue
                            error={formError?.extra_adult_rate} // Menampilkan error jika ada
                            placeholder="Rp. 0" // Placeholder Rupiah default
                        />
                    </div>
                </div>

                {/* Extra Child Rate */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="extra_child_rate" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Extra Child Rate: </label>
                    <div className="col-sm-8">
                        <CurrencyInput
                            name="extra_child_rate"
                            className={clsx("djitu-form-control rounded-1", formError?.extra_child_rate && 'is-invalid')}
                            value={formData.extra_child_rate} // Bind value ke formData
                            onChange={(e, rawValue) => handleChange({ target: { name: 'extra_child_rate', value: rawValue } })} // Simpan rawValue
                            error={formError?.extra_child_rate} // Menampilkan error jika ada
                            placeholder="Rp. 0" // Placeholder Rupiah default
                        />
                    </div>
                </div>

                {/* Rate */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="rate" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Rate: </label>
                    <div className="col-sm-8">
                        <CurrencyInput
                            name="rate"
                            className={clsx("djitu-form-control rounded-1", formError?.rate && 'is-invalid')}
                            value={formData.rate} // Bind value ke formData
                            onChange={(e, rawValue) => handleChange({ target: { name: 'rate', value: rawValue } })} // Simpan rawValue
                            error={formError?.rate} // Menampilkan error jika ada
                            placeholder="Rp. 0" // Placeholder Rupiah default
                        />
                    </div>
                </div>

                {/* Min Rate */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="min_rate" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Min Rate: </label>
                    <div className="col-sm-8">
                        <CurrencyInput
                            name="min_rate"
                            className={clsx("djitu-form-control rounded-1", formError?.min_rate && 'is-invalid')}
                            value={formData.min_rate} // Bind value ke formData
                            onChange={(e, rawValue) => handleChange({ target: { name: 'min_rate', value: rawValue } })} // Simpan rawValue
                            error={formError?.min_rate} // Menampilkan error jika ada
                            placeholder="Rp. 0" // Placeholder Rupiah default
                        />
                    </div>
                </div>
            </div>

            {/* Submit Button */}
            <div className="form-group mt-auto d-grid">
                <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting}>
                    {isUpdateMode ? 'Update Rate Plan' : 'Create Rate Plan'}
                </button>
            </div>
        </form>
    );
};

export default CreateRatePage;
