import { useMemo } from 'react';

// Custom hook yang menggabungkan logika dan komponen pagination
export const usePaginationWithComponent = ({ totalPages, currentPage, onPageChange, visiblePages = 5 }) => {

    // Fungsi untuk menentukan halaman yang akan ditampilkan
    const getVisiblePages = useMemo(() => {
        if (totalPages <= visiblePages) {
            return Array.from({ length: totalPages }, (_, i) => i + 1);
        }

        const start = Math.max(currentPage - Math.floor(visiblePages / 2), 1);
        const end = Math.min(start + visiblePages - 1, totalPages);
        return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    }, [totalPages, currentPage, visiblePages]);

    // Logika Pagination
    const canGoToFirst = currentPage > 1;
    const canGoToPrevious = currentPage > 1;
    const canGoToNext = currentPage < totalPages;
    const canGoToLast = currentPage < totalPages;

    const goToFirst = () => onPageChange(1);
    const goToPrevious = () => onPageChange(currentPage - 1);
    const goToNext = () => onPageChange(currentPage + 1);
    const goToLast = () => onPageChange(totalPages);

    // Komponen Pagination
    const PaginationComponent = (
        <nav className='mt-3'>
            <ul className="pagination d-flex justify-content-end">
                {/* Tombol First */}
                {canGoToFirst && (
                    <li className="page-item">
                        <button className="page-link" onClick={goToFirst}>First</button>
                    </li>
                )}

                {/* Tombol Previous */}
                {canGoToPrevious && (
                    <li className="page-item">
                        <button className="page-link" onClick={goToPrevious}>Previous</button>
                    </li>
                )}

                {/* Halaman yang terlihat */}
                {getVisiblePages.map((page) => (
                    <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
                        <button className={`page-link ${currentPage === page ? 'btn-primary' : ''}`} onClick={() => onPageChange(page)}>{page}</button>
                    </li>
                ))}

                {/* Tombol Next */}
                {canGoToNext && (
                    <li className="page-item">
                        <button className="page-link" onClick={goToNext}>Next</button>
                    </li>
                )}

                {/* Tombol Last */}
                {canGoToLast && (
                    <li className="page-item">
                        <button className="page-link" onClick={goToLast}>Last</button>
                    </li>
                )}
            </ul>
        </nav>
    );

    return PaginationComponent; // Kembalikan komponen pagination
};
