import React from 'react';
import { Card } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';

const OccupancyChart = ({ data }) => (
  <Card className="mb-3 shadow-sm flex-fill" style={{ borderRadius: '10px', border: 'none' }}>
    <Card.Body>
      <Card.Title className="text-muted">Occupancy Rate</Card.Title>
      <Line data={data} />
    </Card.Body>
  </Card>
);

export default OccupancyChart;
