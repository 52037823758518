import React, { createContext, useState, useContext } from 'react';
import Modal from 'components/modal/modal';
import ModalCustom from 'components/modal/modal-custom';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [modalProps, setModalProps] = useState({
    show: false,
    title: '',
    body: null,
    type: 'basic'
  });

  // const showModal = ({ type, title, message, buttonText, onButtonClick, body, size = 'sm' }) => {
  //   setModal({
  //     show: true,
  //     type,
  //     title,
  //     message,
  //     buttonText,
  //     onButtonClick,
  //     body,
  //     size
  //   });
  // };

  const showModal = {
    basic: ({ type, title, message, buttonText, onButtonClick, size = 'sm' }) => {
      setModalProps({
        show: true,
        type,
        title,
        message,
        buttonText,
        onButtonClick,
        size
      });
    },
    custom: ({ title, body }) => {
      setModalProps({
        show: true,
        title,
        body,
        type: 'custom',
        size: 'lg'
      });
    }
  };

  // const hideModal = () => {
  //   setModal({ show: false });
  // };

  const hideModal = () => {
    setModalProps({
      ...modalProps,
      show: false
    });
  };

  return (
    <ModalContext.Provider value={{ showModal, hideModal }}>
      {children}
      {modalProps.type === 'custom' ? (
        <ModalCustom show={modalProps.show} onClose={hideModal} {...modalProps} />
      ) : (
        <Modal show={modalProps.show} onClose={hideModal} {...modalProps} />
      )}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
