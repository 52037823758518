import React from "react";
import FullLoader from "components/loader/full-loader";
import Suspense from "components/suspense/suspense";
import { Dropdown } from "react-bootstrap";
import { useLoading } from "contexts/LoadingContext";
import { useReload } from "contexts/ReloadContext";
import { useDeleteData, useFetchData } from "hooks/useApiHooks";
import LayoutDashboard from "layouts/layout-dashboard-collapse";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'; // Import react content support for SweetAlert2
import { useCanvasHandler } from "hooks/useCanvasHandler";
import CreateUserPage from "./components/create-user-page";
import { useDeleteHandler } from "hooks/useDeleteHandler";

// Custom Swal with react component support
const MySwal = withReactContent(Swal);

const UserPage = () => {
    const { reload } = useReload();

    const { data: fetchData } = useFetchData('/user', reload);
    const { deleteData } = useDeleteData('/user');

    const { isLoading } = useLoading();

    const handleDelete = useDeleteHandler(deleteData, 'User has been deleted', 'Failed to delete User');
    const { handleSuccess, handleError, showCanvasHandler } = useCanvasHandler();

    const handleCreateUser = (e, data) => {
        e.preventDefault();

        const isUpdateMode = !!data;

        showCanvasHandler(
            isUpdateMode ? 'Update User' : 'Create User',
            <CreateUserPage
                initialData={isUpdateMode ? data : null}
                onSuccess={handleSuccess}
                onError={handleError}
            />
        );
    };

    return (
        <Suspense loading={isLoading} loader={<FullLoader />}>
            <LayoutDashboard>
                <div className="container-fluid mt-5">
                    <div className="table-container vh-100">
                        <div className="search-container mb-3">
                            {/* Search input */}
                            <div className="input-icon">
                                <input type="text" className="form-control" placeholder="Search..." />
                                <span className="input-icon-addon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="icon icon-tabler icon-tabler-search"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <circle cx="10" cy="10" r="7" />
                                        <line x1="21" y1="21" x2="15" y2="15" />
                                    </svg>
                                </span>
                            </div>

                            {/* Create button */}
                            <button className="btn btn-primary" onClick={handleCreateUser}>Create</button>
                        </div>

                        {/* Table */}
                        <div className="table">
                            <table className="table table-hover table-vcenter">
                                <thead>
                                    <tr>
                                        <th>name</th>
                                        <th>email</th>
                                        <th>created_at</th>
                                        <th className="text-end">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fetchData?.data?.map((user, index) => (
                                        <React.Fragment key={index}>
                                            <tr>
                                                <td>{user.name}</td>
                                                <td>{user.email}</td>
                                                <td>{user.created_at}</td>
                                                <td className="text-end">
                                                    {user.id !== '9d2ff4ad-b176-4700-ab03-c06d91cfaefe' && (
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="default" id="dropdownMenuButton1">
                                                                Actions
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item href="#">View</Dropdown.Item>
                                                                <Dropdown.Item href="#" onClick={(e) => handleCreateUser(e, user)}>Edit</Dropdown.Item>
                                                                <Dropdown.Item href="#" onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleDelete(user.id);
                                                                }}>
                                                                    Remove
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    )}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        {/* Pagination */}
                        <div className="pagination-container">
                            <nav aria-label="Page navigation">
                                <ul className="pagination">
                                    <li className="page-item active">
                                        <a className="page-link bg-primary" href="#">
                                            1
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </LayoutDashboard>
        </Suspense>
    );
};

export default UserPage;