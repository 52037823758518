import React, { useState } from "react";
import { Button, Form, Alert } from "react-bootstrap";
import { useUpdateData } from "hooks/useApiHooks";
import LayoutDashboard from "layouts/layout-dashboard-collapse";
import BreadcrumbWithTitle from "components/breadcrumb/breadcrumb-with-title";
import { URLS } from "utils/constants";

const ChangePasswordPage = () => {
  const API_URL = "/change-password"; // Endpoint untuk ganti kata sandi
  const PAGE_TITLE = "Change Password";

  const [passwordData, setPasswordData] = useState({
    old_password: "",
    new_password: "",
    confirm_new_password: "",
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const { updateData } = useUpdateData(API_URL);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPasswordData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const { old_password, new_password, confirm_new_password } = passwordData;

    // Validasi kata sandi baru dan konfirmasi
    if (new_password !== confirm_new_password) {
      setErrorMessage("New password and confirmation do not match.");
      setSuccessMessage("");
      return;
    }

    try {
      await updateData({
        old_password,
        new_password,
        confirm_new_password,
      });
      setSuccessMessage("Password changed successfully.");
      setErrorMessage("");
      setPasswordData({
        old_password: "",
        new_password: "",
        confirm_new_password: "",
      });
    } catch (error) {
      setErrorMessage("Failed to change password. Please check your inputs.");
      setSuccessMessage("");
    }
  };

  return (
    <LayoutDashboard>
      <BreadcrumbWithTitle
        breadcrumbs={[
          { label: "Dashboard", href: "/" },
          { label: PAGE_TITLE, href: URLS.DASHBOARD_ADMIN_CHANGE_PASSWORD, active: true },
        ]}
        title={PAGE_TITLE}
      />
      <div className="container my-4">
        <div className="card shadow-sm p-4">
          <h3 className="mb-3">Change Password</h3>
          <Form onSubmit={handleSubmit}>
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            {successMessage && <Alert variant="success">{successMessage}</Alert>}
            <Form.Group controlId="oldPassword" className="mb-3">
              <Form.Label>Old Password</Form.Label>
              <Form.Control
                type="password"
                name="old_password"
                value={passwordData.old_password}
                onChange={handleInputChange}
                placeholder="Enter old password"
                required
              />
            </Form.Group>
            <Form.Group controlId="newPassword" className="mb-3">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                name="new_password"
                value={passwordData.new_password}
                onChange={handleInputChange}
                placeholder="Enter new password"
                required
              />
            </Form.Group>
            <Form.Group controlId="confirmNewPassword" className="mb-3">
              <Form.Label>Confirm New Password</Form.Label>
              <Form.Control
                type="password"
                name="confirm_new_password"
                value={passwordData.confirm_new_password}
                onChange={handleInputChange}
                placeholder="Confirm new password"
                required
              />
            </Form.Group>
            <Button type="submit" variant="primary" className="mt-3">
              Change Password
            </Button>
          </Form>
        </div>
      </div>
    </LayoutDashboard>
  );
};

export default ChangePasswordPage;
