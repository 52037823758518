import { API_URL } from 'utils/constants';

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms)); // untuk test loading state

const fetchWrapper = async (url, method, data) => {
    const options = {
        method,
        headers: {
            'Content-Type': 'application/json',
        },
    };

    const token = localStorage.getItem('token');

    if (token) {
        options.headers.Authorization = `Bearer ${token}`;
    }

    if (data) {
        options.body = JSON.stringify(data);
    }

    // await delay(1000); // Test loading state

    const response = await fetch(`${API_URL}${url}`, options);

    if (!response.ok) {
        const errorData = response.status === 422 ? await response.json() : null;
        if (response.status === 422) {
            throw {
                code: errorData.code,
                errors: errorData.errors
            };
        } else {
            throw new Error(response.statusText);
        }
    }

    return response.json();
};

export const postRequest = (url, data) => fetchWrapper(url, 'POST', data);
export const patchRequest = (url, data) => fetchWrapper(url, 'PUT', data);
export const deleteRequest = (url) => fetchWrapper(url, 'DELETE');
export const getRequest = (url) => fetchWrapper(url, 'GET');