import Sidebar from "components/navigation-2/sidebar";
import Footer from "components/footer";

export default function LayoutDashboard({ children }) {
    return (
        <div className="page">
            <Sidebar>
                <div className="page-wrapper">
                    {children}
                    <Footer />
                </div>
            </Sidebar>
        </div>
    );
}