import FullLoader from "components/loader/full-loader";
import Suspense from "components/suspense/suspense";
import { useLoading } from "contexts/LoadingContext";
import { useReload } from "contexts/ReloadContext";
import { useFetchData } from "hooks/useApiHooks";
import LayoutDashboard from "layouts/layout-dashboard-collapse";
import Swal from "sweetalert2";

const ChannelSetupPage = () => {
    const { reload } = useReload();

    const { data: channelData, error } = useFetchData('/channex-iframe-url', reload, 'channel-setup');

    const { isLoading } = useLoading();

    if (error) {
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to fetch channel data',
        });
    }

    return (
        <Suspense loader={<FullLoader />} loading={isLoading} type="channel-setup">
            <LayoutDashboard>
                <div style={{ width: '100%', height: '100vh' }}>
                    <iframe
                        src={channelData}  // Ganti dengan URL iframe yang ingin ditampilkan
                        title="channel-setup"
                        width="100%"
                        height="100%"
                        style={{ border: 'none' }}
                    />
                </div>
            </LayoutDashboard>
        </Suspense>
    );
};

export default ChannelSetupPage;