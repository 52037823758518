import React, { createContext, useState, useContext } from 'react';

// Context untuk mengelola state reload
const ReloadContext = createContext();

export const ReloadProvider = ({ children }) => {
    const [reload, setReload] = useState(false);

    const triggerReload = () => {
        setReload(prevReload => !prevReload); // Memicu reload dengan toggle
    };

    return (
        <ReloadContext.Provider value={{ reload, triggerReload }}>
            {children}
        </ReloadContext.Provider>
    );
};

// Custom hook untuk menggunakan reload context
export const useReload = () => {
    return useContext(ReloadContext);
};
