export const useFormChangeHandler = (setFormData) => {
    const handleChange = (e) => {
        console.log('Form data:', e);
        const { name, value, type, checked } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    return handleChange;
};
