import { useEffect, useState, useRef } from "react";
import Loader from "components/loader/loader";
import Suspense from "components/suspense/suspense";
import { useLoading } from "contexts/LoadingContext";
import { useReload } from "contexts/ReloadContext";
import { useFetchData } from "hooks/useApiHooks";

const PermissionPage = ({ initialData, isDisabled, handlePermissionChange, permissions }) => {
    const isMounted = useRef(false);
    const { reload } = useReload();
    const { data: fetchData } = useFetchData('/permission-list', reload);
    const { isLoading } = useLoading();
    // State lokal untuk menyimpan izin yang dicentang
    const [checkedPermissions, setCheckedPermissions] = useState([]);

    console.log("Data permission:", fetchData);
    // console.log("Initial data permission:", initialData);

    // Fungsi untuk cek apakah permission user memiliki permission_id tertentu
    // function hasPermission(permissionToCheck) {
    //     return permissions.some(permission => permission?.includes(permissionToCheck));
    // }
    function hasPermission(permissionToCheck) {
        // Cek apakah `initialData` adalah array dan cari `permissionToCheck` di dalam `initialData` berdasarkan `name`
        return Array.isArray(initialData) && initialData.some(permission => permission.name === permissionToCheck);
    }
    
    
    // useEffect(() => {
    //     console.log("Isi dari permissions:", permissions);
    // }, [permissions]);
        

    // Fungsi untuk split name permission dengan module
    function splitPermissionName(permissionName) {
        return permissionName.split('-');
    }

    // Handle perubahan permission
    // const onPermissionChange = (e) => {
    //     const { name, checked } = e.target;
    //     if (checked) {
    //         handlePermissionChange((prevFormData) => [...prevFormData, name]);
    //         return;
    //     }

    //     handlePermissionChange((prevFormData) => {
    //         return prevFormData.filter(permission => permission !== name);
    //     });
    // };
    const onPermissionChange = (e) => {
        const { name, checked } = e.target;

        setCheckedPermissions(prevPermissions => {
            let updatedPermissions;
            if (checked) {
                // Tambahkan izin jika checkbox di-check
                updatedPermissions = [...prevPermissions, name];
            } else {
                // Hapus izin jika checkbox di-uncheck
                updatedPermissions = prevPermissions.filter(permission => permission !== name);
            }
            // Update state di parent dengan izin yang baru
            handlePermissionChange(updatedPermissions);
            return updatedPermissions;
        });
    };

    // Update state ketika initialData berubah
    // useEffect(() => {
    //     if (!isMounted.current) {
    //         isMounted.current = true;
    //         initialData.map(permission => {
    //             handlePermissionChange(prevFormData => [...prevFormData, permission]);
    //         });
    //     }
    // }, []);

    useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true;
            // Mengambil `name` dari setiap permission di initialData untuk menandai sebagai checked
            const initialPermissions = initialData.map(permission => permission.name);
            setCheckedPermissions(initialPermissions);
            handlePermissionChange(initialPermissions);
        }
    }, [initialData, handlePermissionChange]);
    

    return (
        <Suspense loading={isLoading} loader={<Loader />}>
            <div className="card mb-3">
                <div className="card-body">
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="w-1">No</th>
                                <th>Group</th>
                                <th className="d-flex">
                                    Permision
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(fetchData?.data || []).map((group, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{group}</td>
                                        <td>
                                            {fetchData?.data[group].map((permission, permissionIndex) => {
                                                // const isChecked = hasPermission(permission.name);
                                                const isChecked = checkedPermissions.includes(permission.name);

                                                // console.log("Permision name", permission);

                                                return (
                                                    // Menampilkan action dinamis
                                                    <div key={permissionIndex} className="form-check form-check-inline">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name={permission.name}
                                                            checked={isChecked}
                                                            disabled={!isDisabled}
                                                            onChange={onPermissionChange}
                                                        />
                                                        <label className="form-check-label">
                                                            {/* {splitPermissionName(permission.name)[1]} */}
                                                            {/* {permission.name} */}
                                                            {splitPermissionName(permission.name)[1] || permission.name}
                                                        </label>
                                                    </div>
                                                );
                                            })}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </Suspense>
    );
};

export default PermissionPage;