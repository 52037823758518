import CreateReservationPage from "pages/reservation/reservation/v2/create-reservation-page";
import { IconCopy } from "@tabler/icons-react";
import Loader from "components/loader/loader";
import Suspense from "components/suspense/suspense";
import { useReload } from "contexts/ReloadContext";
import { useFetchData } from "hooks/useApiHooks";
import React from "react";
import { Accordion } from "react-bootstrap";
import { formatDateToShortWeekdayMonthYear } from "utils/dateHelpers";
import { convertCurrency, formatPrice } from "utils/utils";
import { useCanvas } from "contexts/canvas-context";
import { useCanvasHandler } from "hooks/useCanvasHandler";

const ShortReservation = ({ bookingId, onSuccess, onError }) => {
    const { reload } = useReload();
    const { hideCanvas } = useCanvas();

    const { handleSuccess, handleError, showCanvasHandler } = useCanvasHandler();

    console.log('bookingId', bookingId);

    const { data: bookingData, loading: isLoading } = useFetchData(`/reservation/${bookingId}`, reload, 'short');
    // Dummy data
    const initialData = {
        unique_id: "R12345",
        arrival_date: "2024-10-15",
        departure_date: "2024-10-18",
        occupancy: {
            adults: 2,
            children: 1
        },
        currency: "$",
        amount: 450.00,
        ota_reservation_code: "OTA456789",
        notes: "Special request for a king-size bed."
    };

    // handle copy to clipboard
    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);
        alert('Copied to clipboard');
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Simulating successful submission
        onSuccess && onSuccess();
    };

    const handleEdit = (e) => {
        e.preventDefault();
        hideCanvas();
        showCanvasHandler('Edit Reservation',
            <CreateReservationPage
                initialData={bookingData?.data}
                onSuccess={() => {
                    handleSuccess(true, 'Reservation has been updated successfully.')
                }}
                onError={() => {
                    handleError(true, 'Failed to update reservation.')
                }}
            />
        );
    };

    console.log('bookingData', bookingData?.data?.customer);

    return (
        <Suspense loader={<Loader />} loading={isLoading} type="short">
            <form className="d-flex flex-column" style={{ height: '100%' }} onSubmit={handleSubmit}>
                {/* <div className="action-sheet-content flex-grow-1">
                    <div className="row mb-3 align-items-center">
                        <label className="col-sm-4 col-form-label d-flex justify-content-end">
                            Reservation ID:
                        </label>
                        <div className="col-sm-8">
                            <p className="form-control-plaintext">{`${bookingData?.data?.unique_id}`}</p>
                        </div>
                    </div>
                    <div className="row mb-3 align-items-center">
                        <label className="col-sm-4 col-form-label d-flex justify-content-end">
                            Arrival Date:
                        </label>
                        <div className="col-sm-8">
                            <p className="form-control-plaintext">{bookingData?.data?.arrival_date}</p>
                        </div>
                    </div>
                    <div className="row mb-3 align-items-center">
                        <label className="col-sm-4 col-form-label d-flex justify-content-end">
                            Departure Date:
                        </label>
                        <div className="col-sm-8">
                            <p className="form-control-plaintext">{bookingData?.data?.departure_date}</p>
                        </div>
                    </div>
                    <div className="row mb-3 align-items-center">
                        <label className="col-sm-4 col-form-label d-flex justify-content-end">
                            Occupancy:
                        </label>
                        <div className="col-sm-8">
                            <p className="form-control-plaintext">
                                {bookingData?.data?.occupancy_adults} Adults, {bookingData?.data?.occupancy_children || 0} Children
                            </p>
                        </div>
                    </div>
                    {bookingData?.data?.notes && (
                        <div className="row mb-3 align-items-center">
                            <label className="col-sm-4 col-form-label d-flex justify-content-end">
                                Notes:
                            </label>
                            <div className="col-sm-8">
                                <p className="form-control-plaintext">{bookingData?.data?.notes}</p>
                            </div>
                        </div>
                    )}

                    <h3 className="mt-6 border-bottom fw-normal">Rooms Information</h3>
                    {bookingData && bookingData?.data?.booking_rooms.map((room, index) => (
                        <>
                            <div className="row mb-3 align-items-center">
                                <label className="col-sm-4 col-form-label d-flex justify-content-end">
                                    Room {index + 1} Type:
                                </label>
                                <div className="col-sm-8">
                                    <p className="form-control-plaintext">{room?.room_type?.name}</p>
                                </div>
                            </div>
                            <div className="row mb-3 align-items-center">
                                <label className="col-sm-4 col-form-label d-flex justify-content-end">
                                    Rate {index + 1} Plan:
                                </label>
                                <div className="col-sm-8">
                                    <p className="form-control-plaintext">{room?.rate_plan?.name}</p>
                                </div>
                            </div>
                            <div className="row mb-3 align-items-center">
                                <label className="col-sm-4 col-form-label d-flex justify-content-end">
                                    Room {index + 1} Number:
                                </label>
                                <div className="col-sm-8">
                                    <p className="form-control-plaintext">{room?.room?.room_number}</p>
                                </div>
                            </div>
                        </>
                    ))}

                    <h3 className="mt-6 border-bottom fw-normal">Guest Information</h3>
                    <div className="row mb-3 align-items-center">
                        <label className="col-sm-4 col-form-label d-flex justify-content-end">
                            Customer Name:
                        </label>
                        <div className="col-sm-8">
                            <p className="form-control-plaintext">{bookingData?.data?.customer_name}</p>
                        </div>
                    </div>
                    <div className="row mb-3 align-items-center">
                        <label className="col-sm-4 col-form-label d-flex justify-content-end">
                            Customer Surname:
                        </label>
                        <div className="col-sm-8">
                            <p className="form-control-plaintext">{bookingData?.data?.customer_surname}</p>
                        </div>
                    </div>

                    <h3 className="mt-6 border-bottom fw-normal">Payment Information</h3>
                    <div className="row mb-3 align-items-center">
                        <label className="col-sm-4 col-form-label d-flex justify-content-end">
                            Total Amount:
                        </label>
                        <div className="col-sm-8">
                            <p className="form-control-plaintext">
                                {convertCurrency(bookingData?.data?.amount, bookingData?.data?.currency)}
                            </p>
                        </div>
                    </div>
                    <div className="row mb-3 align-items-center">
                        <label className="col-sm-4 col-form-label d-flex justify-content-end">
                            Payment:
                        </label>
                        <div className="col-sm-8">
                            <p className="form-control-plaintext">
                                {convertCurrency(bookingData?.data?.amount, bookingData?.data?.currency)}
                            </p>
                        </div>
                    </div>
                </div> */}

                <div className="action-sheet-content flex-grow-1 mb-3">
                    <div className="row align-items-center">
                        <label className="col-sm-4 col-form-label d-flex justify-content-end">Status:</label>
                        <div className="col-sm-8">
                            <span className="badge bg-lime-lt">{bookingData?.data?.status}</span>
                        </div>
                    </div>

                    <div className="row align-items-center">
                        <label className="col-sm-4 col-form-label d-flex justify-content-end">Source / OTA:</label>
                        <div className="col-sm-8">
                            <p className="form-control-plaintext">{bookingData?.data?.ota_name}</p>
                        </div>
                    </div>

                    <div className="row align-items-center">
                        <label className="col-sm-4 col-form-label d-flex justify-content-end">Channel:</label>
                        <div className="col-sm-8">
                            <p className="form-control-plaintext">
                                {bookingData?.data?.channel_id && (
                                    <>
                                        {bookingData?.data?.channel_id}
                                        <IconCopy className="text-azure ms-1" size={16} onClick={(e) => handleCopy(e, bookingData?.data?.channel_id)} />
                                    </>
                                )}
                            </p>
                        </div>
                    </div>

                    <div className="row align-items-center">
                        <label className="col-sm-4 col-form-label d-flex justify-content-end">Reservation ID:</label>
                        <div className="col-sm-8">
                            <p className="form-control-plaintext">
                                {bookingData?.data?.unique_id && (
                                    <>
                                        {bookingData?.data?.unique_id}
                                        <IconCopy className="text-azure ms-1" size={16} onClick={(e) => handleCopy(e, bookingData?.data?.unique_id)} />
                                    </>
                                )}
                            </p>
                        </div>
                    </div>
                    {/* 
                    <div className="row align-items-center">
                        <label className="col-sm-4 col-form-label d-flex justify-content-end">Booking ID:</label>
                        <div className="col-sm-8">
                            <p className="form-control-plaintext">
                                {bookingData?.data?.booking_id && (
                                    <>
                                        {bookingData?.data?.booking_id}
                                        <IconCopy className="text-azure ms-1" size={16} onClick={(e) => handleCopy(e, bookingData?.data?.booking_id)} />
                                    </>
                                )}
                            </p>
                        </div>
                    </div>

                    <div className="row align-items-center">
                        <label className="col-sm-4 col-form-label d-flex justify-content-end">OTA Reservation ID:</label>
                        <div className="col-sm-8">
                            <p className="form-control-plaintext">
                                {bookingData?.data?.ota_reservation_code && (
                                    <>
                                        {bookingData?.data?.booking_id}
                                        <IconCopy className="text-azure ms-1" size={16} onClick={(e) => handleCopy(e, bookingData?.data?.booking_id)} />
                                    </>
                                )}
                            </p>
                        </div>
                    </div> */}

                    <div className="row align-items-center">
                        <label className="col-sm-4 col-form-label d-flex justify-content-end">Booked At:</label>
                        <div className="col-sm-8">
                            <p className="form-control-plaintext">{formatDateToShortWeekdayMonthYear(bookingData?.data?.created_at)}</p>
                        </div>
                    </div>

                    <h3 className="mt-6 border-bottom fw-normal">Period of Stay</h3>
                    <div className="row align-items-center">
                        <label className="col-sm-4 col-form-label d-flex justify-content-end">Checkin Date:</label>
                        <div className="col-sm-8">
                            <p className="form-control-plaintext">{bookingData?.data?.arrival_date}</p>
                        </div>
                    </div>

                    <div className="row align-items-center">
                        <label className="col-sm-4 col-form-label d-flex justify-content-end">Checkout Date:</label>
                        <div className="col-sm-8">
                            <p className="form-control-plaintext">{bookingData?.data?.departure_date}</p>
                        </div>
                    </div>

                    <div className="row align-items-center">
                        <label className="col-sm-4 col-form-label d-flex justify-content-end">Arrival Time:</label>
                        <div className="col-sm-8">
                            <p className="form-control-plaintext">{bookingData?.data?.arrival_hour}</p>
                        </div>
                    </div>

                    <div className="row align-items-center">
                        <label className="col-sm-4 col-form-label d-flex justify-content-end">Nights:</label>
                        <div className="col-sm-8">
                            <p className="form-control-plaintext">{bookingData?.data?.nights}</p>
                        </div>
                    </div>

                    <div className="row align-items-center">
                        <label className="col-sm-4 col-form-label d-flex justify-content-end">Rooms:</label>
                        <div className="col-sm-8">
                            <p className="form-control-plaintext">{bookingData?.data?.occupancy?.adults}</p>
                        </div>
                    </div>

                    <div className="row align-items-center">
                        <label className="col-sm-4 col-form-label d-flex justify-content-end">Occupancy:</label>
                        <div className="col-sm-8">
                            <p className="form-control-plaintext">
                                {bookingData?.data?.occupancy_adults} Adults, {bookingData?.data?.occupancy_children} Children
                            </p>
                        </div>
                    </div>

                    <h3 className="mt-6 border-bottom fw-normal">Customer</h3>
                    <div className="row align-items-center">
                        <label className="col-sm-4 col-form-label d-flex justify-content-end">Name:</label>
                        <div className="col-sm-8">
                            <p className="form-control-plaintext">{bookingData?.data?.customer_name}</p>
                        </div>
                    </div>

                    <div className="row align-items-center">
                        <label className="col-sm-4 col-form-label d-flex justify-content-end">Surname:</label>
                        <div className="col-sm-8">
                            <p className="form-control-plaintext">{bookingData?.data?.customer_surname}</p>
                        </div>
                    </div>

                    <div className="row align-items-center">
                        <label className="col-sm-4 col-form-label d-flex justify-content-end">Email:</label>
                        <div className="col-sm-8">
                            <p className="form-control-plaintext">{bookingData?.data?.customer?.email}</p>
                        </div>
                    </div>

                    <div className="row align-items-center">
                        <label className="col-sm-4 col-form-label d-flex justify-content-end">Phone:</label>
                        <div className="col-sm-8">
                            <p className="form-control-plaintext">{bookingData?.data?.customer?.phone}</p>
                        </div>
                    </div>

                    {/* <div className="row align-items-center">
                        <label className="col-sm-4 col-form-label d-flex justify-content-end">Language:</label>
                        <div className="col-sm-8">
                            <p className="form-control-plaintext">{bookingData?.data?.customer?.language}</p>
                        </div>
                    </div> */}

                    <div className="row align-items-center">
                        <label className="col-sm-4 col-form-label d-flex justify-content-end">Country:</label>
                        <div className="col-sm-8">
                            <p className="form-control-plaintext">{bookingData?.data?.customer?.country}</p>
                        </div>
                    </div>

                    <div className="row align-items-center">
                        <label className="col-sm-4 col-form-label d-flex justify-content-end">City:</label>
                        <div className="col-sm-8">
                            <p className="form-control-plaintext">{bookingData?.data?.customer?.city}</p>
                        </div>
                    </div>

                    <div className="row align-items-center">
                        <label className="col-sm-4 col-form-label d-flex justify-content-end">Address:</label>
                        <div className="col-sm-8">
                            <p className="form-control-plaintext">{bookingData?.data?.customer?.address}</p>
                        </div>
                    </div>

                    <div className="row align-items-center">
                        <label className="col-sm-4 col-form-label d-flex justify-content-end">Postal Code:</label>
                        <div className="col-sm-8">
                            <p className="form-control-plaintext">{bookingData?.data?.customer?.zip}</p>
                        </div>
                    </div>

                    <h3 className="mt-6 border-bottom fw-normal">Rooms</h3>
                    <div className="row align-items-center">
                        {bookingData && bookingData?.data?.booking_rooms.map((room, index) => (
                            <Accordion className="border-0">
                                <Accordion.Item eventKey={index} className="border-0">
                                    <Accordion.Header className="px-0">
                                        <div className="col-12 d-flex align-items-center justify-content-between">
                                            <div>
                                                <h3 className="fs-3 mb-0 ps-0">
                                                    {room?.room_type?.name}
                                                </h3>
                                                <span className="form-control-plaintext fw-normal">
                                                    {`${formatDateToShortWeekdayMonthYear(room.checkin_date)} - ${formatDateToShortWeekdayMonthYear(room.checkout_date)}`}
                                                </span>
                                            </div>
                                            <span className="fw-bold fs-3 d-flex justify-content-end me-2">
                                                {formatPrice(room.amount)}
                                            </span>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body className="pt-0">
                                        <div className="row">
                                            <label className="col-sm-4 col-form-label d-flex justify-content-end">Price Breakdown:</label>
                                            <div className="col-sm-8">
                                                <table className="table table-borderless mt-0">
                                                    <tbody>
                                                        {/* <tr>
                                                            <td className="border-0 p-0"><p className="form-control-plaintext">{formatDateToShortWeekdayMonthYear(room.checkin_date)}</p></td>
                                                            <td className="border-0 p-0"><p className="form-control-plaintext">{room?.rate_plan?.name}</p></td>
                                                            <td className="border-0 p-0"><p className="form-control-plaintext d-flex justify-content-end">{formatPrice(room?.rate_plan?.rate)}</p></td>
                                                        </tr> */}
                                                        {room?.days.map((day, index) => (
                                                            <tr key={index}>
                                                                <td className="border-0 p-0"><p className="form-control-plaintext">{formatDateToShortWeekdayMonthYear(day.date)}</p></td>
                                                                <td className="border-0 p-0"><p className="form-control-plaintext">{room?.rate_plan?.name}</p></td>
                                                                <td className="border-0 p-0"><p className="form-control-plaintext d-flex justify-content-end">{formatPrice(day.amount)}</p></td>
                                                            </tr>
                                                        ))}
                                                        <tr>
                                                            <td className="border-0 p-0" colSpan={2}><p className="form-control-plaintext fw-bold">Subtotal</p></td>
                                                            <td className="border-0 p-0"><p className="form-control-plaintext d-flex justify-content-end">{formatPrice(room.amount)}</p></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <label className="col-sm-4 col-form-label d-flex justify-content-end">Occupancy:</label>
                                            <div className="col-sm-8">
                                                <p className="form-control-plaintext">{room.occupancy_adults} Adults, {room.occupancy_children} Children</p>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <label className="col-sm-4 col-form-label d-flex justify-content-end">Meal Plan</label>
                                            <div className="col-sm-8">
                                                <p className="form-control-plaintext">Breakfast</p>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        ))}
                    </div>

                    <h3 className="mt-6 border-bottom fw-normal">Guarantee</h3>
                    <div className="row align-items-center">
                        <p className="plain-text">
                            No credit card required to book. We'll send you an email confirming your reservation.
                        </p>
                    </div>

                    <h3 className="mt-6 border-bottom fw-normal">Notes</h3>
                    <div className="row align-items-center">
                        <p className="plain-text">{bookingData?.data?.notes || 'No notes'}</p>
                    </div>
                </div>

                {/* Submit Button */}
                <div className="form-group mt-auto d-grid">
                    <button type="submit" className="btn btn-primary btn-block mb-3" onClick={handleEdit}>
                        Edit Reservation
                    </button>
                </div>
            </form>
        </Suspense>
    );
};

export default ShortReservation;
