import Suspense from 'components/suspense/suspense';
import clsx from 'clsx';

const DefaultButton = ({ children, className, ...rest }) => (
  <button
    {...rest}
    className={clsx(
      'btn',
      className,
    )}
  >
    {children}
  </button>
);


const ButtonLoading = ({ className, children, ...rest }) => (
  <button
    {...rest}
    className={clsx(
      'btn',
      className,
    )}
    disabled // Nonaktifkan tombol saat loading
  >
    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    {/* Tambahkan margin agar spinner dan teks tidak berdekatan */}
    <span className="ms-2">{children}</span>
  </button>
);

export default function Button({ children, className, loading, ...rest }) {
  return (
    <Suspense loading={loading} loader={<ButtonLoading className={className} {...rest}>{children}</ButtonLoading>}>
      <DefaultButton className={className} {...rest} disabled={loading}>
        {children}
      </DefaultButton>
    </Suspense>
  );
}
