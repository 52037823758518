import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import styles from './OccupancyReport.module.css'; // Modular CSS untuk styling
import ReportsPage from '../../reports-page';

const OccupancyReport = () => {
    // State untuk properti dan tipe kamar yang dipilih
    const [selectedProperty, setSelectedProperty] = useState('VIENNA VILLAS SEMINYAK');
    const [selectedRooms, setSelectedRooms] = useState({
        'Vienna Villa 2BR': true,
        'Vienna Villa 4BR': true,
    });

    // Data dummy okupansi
    const occupancyData = [
        { date: '2024-09-14', rooms: 5, roomSold: 4, occupancy: '80%', available: 1, property: 'VIENNA VILLAS SEMINYAK', roomType: 'Vienna Villa 2BR' },
        { date: '2024-09-15', rooms: 5, roomSold: 5, occupancy: '100%', available: 0, property: 'VIENNA VILLAS SEMINYAK', roomType: 'Vienna Villa 4BR' },
        { date: '2024-09-16', rooms: 5, roomSold: 5, occupancy: '100%', available: 0, property: 'VIENNA VILLAS SEMINYAK', roomType: 'Vienna Villa 2BR' },
        { date: '2024-09-17', rooms: 5, roomSold: 3, occupancy: '60%', available: 2, property: 'VIENNA VILLAS SEMINYAK', roomType: 'Vienna Villa 4BR' },
        { date: '2024-09-18', rooms: 5, roomSold: 4, occupancy: '80%', available: 1, property: 'VIENNA VILLAS SEMINYAK', roomType: 'Vienna Villa 2BR' },
        { date: '2024-10-14', rooms: 5, roomSold: 4, occupancy: '80%', available: 1, property: 'VIENNA VILLAS SEMINYAK', roomType: 'Vienna Villa 4BR' },
        // Tambahkan data lainnya...
    ];

    // State untuk data yang ditampilkan (sesuai filter)
    const [filteredData, setFilteredData] = useState(occupancyData);

    // Fungsi untuk handle perubahan property
    const handlePropertyChange = (event) => {
        setSelectedProperty(event.target.value);
        filterData(event.target.value, selectedRooms);
    };

    // Fungsi untuk handle perubahan tipe kamar
    const handleRoomChange = (roomType) => {
        const updatedRooms = { ...selectedRooms, [roomType]: !selectedRooms[roomType] };
        setSelectedRooms(updatedRooms);
        filterData(selectedProperty, updatedRooms);
    };

    // Fungsi untuk filter data berdasarkan properti dan tipe kamar
    const filterData = (property, rooms) => {
        const filtered = occupancyData.filter(item => {
            return item.property === property && rooms[item.roomType];
        });
        setFilteredData(filtered);
    };

    // Fungsi Dummy untuk ekspor PDF
    const handleExportPDF = () => {
        alert('Laporan telah diekspor dalam format PDF!');
    };

    // Fungsi Dummy untuk ekspor Excel
    const handleExportExcel = () => {
        alert('Laporan telah diekspor dalam format Excel!');
    };

    return (
        <ReportsPage>
            <div className={styles.container}>
                {/* Bagian Filter */}
                <div className="row mb-4 align-items-center">
                    <div className="col-md-4">
                        <label htmlFor="select-property" className="form-label">Select Property:</label>
                        <select className="form-select" id="select-property" value={selectedProperty} onChange={handlePropertyChange}>
                            <option value="VIENNA VILLAS SEMINYAK">VIENNA VILLAS SEMINYAK</option>
                            {/* Tambahkan properti lainnya jika perlu */}
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="select-year" className="form-label">Select Year:</label>
                        <select className="form-select" id="select-year">
                            <option>2024</option>
                            {/* Tambahkan tahun lainnya jika perlu */}
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="date-range" className="form-label">Select Range:</label>
                        <div className="d-flex">
                            <input type="date" className="form-control me-2" id="start-date" value="2024-09-14" />
                            <span className="mx-2">to</span>
                            <input type="date" className="form-control" id="end-date" value="2024-10-14" />
                        </div>
                    </div>
                </div>

                {/* Pilihan Tipe Kamar */}
                <div className="row mb-4">
                    <div className="col-md-6">
                        <label className="form-label">Select Room Types:</label>
                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="villa-2br"
                                checked={selectedRooms['Vienna Villa 2BR']}
                                onChange={() => handleRoomChange('Vienna Villa 2BR')}
                            />
                            <label className="form-check-label" htmlFor="villa-2br">Vienna Villa 2BR</label>
                        </div>
                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="villa-4br"
                                checked={selectedRooms['Vienna Villa 4BR']}
                                onChange={() => handleRoomChange('Vienna Villa 4BR')}
                            />
                            <label className="form-check-label" htmlFor="villa-4br">Vienna Villa 4BR</label>
                        </div>
                    </div>
                    <div className="col-md-6 text-end">
                        <button className="btn btn-primary me-2" onClick={handleExportPDF}>Export to PDF</button>
                        <button className="btn btn-success" onClick={handleExportExcel}>Export to Excel</button>
                    </div>
                </div>

                {/* Bagian Tabel */}
                <div className={`${styles.card} bg-white`}>
                    <h6 className={styles.moduleHeader}>Occupancy Report</h6>
                    <div className="table-responsive">
                        <Table bordered hover className="text-center">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Rooms</th>
                                    <th>Room Sold</th>
                                    <th>Occupancy</th>
                                    <th>Available</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredData.map((row, index) => (
                                    <tr key={index}>
                                        <td>{row.date}</td>
                                        <td>{row.rooms}</td>
                                        <td>{row.roomSold}</td>
                                        <td>{row.occupancy}</td>
                                        <td>{row.available}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <td><strong>Total</strong></td>
                                    <td><strong>{filteredData.reduce((sum, row) => sum + row.rooms, 0)}</strong></td>
                                    <td><strong>{filteredData.reduce((sum, row) => sum + row.roomSold, 0)}</strong></td>
                                    <td><strong>82.6%</strong></td>
                                    <td><strong>{filteredData.reduce((sum, row) => sum + row.available, 0)}</strong></td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </ReportsPage>
    );
};

export default OccupancyReport;
