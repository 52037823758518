import MonthlySetupPage from 'pages/channel-manager/monthly-setup/monthly-setup-page';
import ChannelManagerMappingPage from 'pages/channel-manager/channel-manager-integration/channel-manager-mapping-page';
import React from 'react';
import { URLS } from 'utils/constants';
import ChannelSetupPage from 'pages/channel-manager/channel-setup/channel-setup';

export const channelManagerRouter = [
    {
        path: URLS.DASHBOARD_ADMIN_CHANNEL_MANAGER_MONTHLY_SETUP,
        element: <MonthlySetupPage />
    },
    {
        path: URLS.DASHBOARD_ADMIN_CHANNEL_MANAGER_MAPPING,
        element: <ChannelManagerMappingPage />
    },
    {
        path: URLS.DASHBOARD_ADMIN_CHANNEL_MANAGER_CHANNEL_SETUP,
        element: <ChannelSetupPage />
    }
];