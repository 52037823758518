import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getRequest } from 'services/api';

const RoomType = ({ setRoomTypeSelected }) => {
    const [roomType, setRoomType] = useState({
        data: [],
        loading: false,
        error: null,
    });

    const fetchRoomType = async () => {
        setRoomType((prev) => ({ ...prev, loading: true }));

        try {
            const response = await getRequest('/room-type');
            if (response.success) {
                setRoomType((prev) => ({ ...prev, data: response.data.map((type) => ({ label: type.name, value: type.id })), error: null }));
            } else {
                setRoomType((prev) => ({ ...prev, error: 'Failed to fetch room type.', loading: false }));
            }
        } catch (error) {
            setRoomType((prev) => ({ ...prev, error: 'Something went wrong. Please try again later.', loading: false }));
        } finally {
            setRoomType((prev) => ({ ...prev, loading: false }));
        }
    };

    useEffect(() => {
        fetchRoomType();
    }, []);

    return (roomType.loading ? (
        <span className="text-muted">Loading...</span>
    ) : (
        <Select
            options={roomType.data}
            onChange={(selectedOption) => {
                setRoomTypeSelected((prev) => ({ ...prev, room_type: selectedOption }));
            }}
        />
    ));
};

export default RoomType;