import React from 'react';
import Select from 'react-select';
import { useSelectOptions } from 'hooks/form/useSelectOptions';

/**
 * SimpleSelect component with data fetching from a URL and loading state.
 * @param {object} props - The properties for the component.
 * @param {string} props.url - The URL to fetch data from.
 * @param {object} props.value - The current selected value.
 * @param {function} props.onChange - Callback when the selected value changes.
 * @param {string} [props.valueKey='id'] - The key to use for option values.
 * @param {string} [props.labelKey='name'] - The key to use for option labels.
 * @param {string} [props.placeholder='Select...'] - Placeholder text for the select input.
 * @param {boolean} [props.reload=false] - Boolean to trigger reload of data.
 * @param {boolean} [props.isSearchable=false] - Boolean to enable/disable search functionality.
 * @returns {JSX.Element} - The rendered select component.
 */
const SimpleSelect = ({
    url,
    value,
    onChange,
    valueKey = 'id',
    labelKey = 'name',
    placeholder = 'Select...',
    reload = false,
    isSearchable = false, // New prop for search functionality
    isDisabled = false, // New prop for disabling select input
    ...props
}) => {
    // Use hook to fetch options from the provided URL
    const { options, isLoading } = useSelectOptions(url, '', valueKey, labelKey, reload);

    // Handle selection change
    const handleChange = (selectedOption) => {
        onChange(selectedOption);
    };

    return (
        <Select
            value={options.find(option => option.value === value)} // Find and display selected value
            onChange={handleChange}
            options={options} // Display formatted options
            placeholder={isLoading ? 'Loading...' : placeholder} // Show loading text while data is being fetched
            isLoading={isLoading} // Show loading spinner
            isSearchable={isSearchable} // Enable/disable search functionality based on prop
            isDisabled={isDisabled} // Disable select input while loading
            {...props}
        />
    );
};

export default SimpleSelect;
