import React from "react";
import { Card, Table } from "react-bootstrap";

const TodayCheckinTable = ({ data }) => (
  <Card
    className="mb-3 shadow-sm"
    style={{ borderRadius: "10px", border: "none" }}
  >
    <Card.Body>
      <Card.Title className="text-muted">Today Checkin</Card.Title>
      <Table striped bordered hover responsive className="mb-0">
        <thead className="table-light">
          <tr>
            <th>Reservation ID</th>
            <th>Name</th>
            <th>Rooms</th>
            <th>Checkin</th>
            <th>Checkout</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {data.map((activity, index) => (
            <tr key={index}>
              <td>{activity.unique_id}</td>
              <td>{activity.name}</td>
              <td>{activity.room}</td>
              {/* <td>{activity.checkin}</td>
              <td>{activity.checkout}</td> */}
              <td>
                {new Date(activity.checkin).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })}
              </td>
              <td>
                {new Date(activity.checkout).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })}
              </td>

              <td>
                <span
                  className={`badge bg-${
                    activity.status === "confirmed"
                      ? "success text-green-fg"
                      : activity.status === "pending"
                      ? "warning text-orange-fg"
                      : "primary text-light"
                  }`}
                >
                  {activity.status}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Card.Body>
  </Card>
);

export default TodayCheckinTable;
