import { useReload } from 'contexts/ReloadContext';
import Swal from 'sweetalert2';

export const useDeleteHandler = (deleteFunction, successMessage, errorMessage) => {
    const { triggerReload } = useReload();

    const handleDelete = async (id) => {
        const result = await Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, cancel!",
            customClass: {
                confirmButton: 'btn btn-danger me-2',
                cancelButton: 'btn btn-secondary'
            },
            buttonsStyling: false,
        });

        if (result.isConfirmed) {
            deleteAction(id);
        }
    };

    const deleteAction = async (id) => {
        const result = await deleteFunction(id);

        if (result.success) {
            Swal.fire({
                icon: "success",
                title: "Deleted!",
                text: successMessage,
            });
            // reloadSetter(prev => !prev); // Trigger reload after success
            triggerReload();
        } else {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: errorMessage || "Something went wrong!"
            });
        }
    };

    return handleDelete;
};
