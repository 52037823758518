import Suspense from 'components/suspense/suspense';
import FullLoader from 'components/loader/full-loader';
import { useLoading } from 'contexts/LoadingContext';
import { useReload } from 'contexts/ReloadContext';
import { useDeleteData, useFetchData } from 'hooks/useApiHooks';
import LayoutDashboard from 'layouts/layout-dashboard-collapse';
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import Loader from 'components/loader/loader';
import { useCanvasHandler } from 'hooks/useCanvasHandler';
import CreateCanvas from 'pages/property-settings/rate-settings/components/create-room-page';
import { useDeleteHandler } from 'hooks/useDeleteHandler';
import CreateRatePage from './components/create-rate-page';
import CreateLinkRatePage from './components/create-link-rate-page';

const ChildRates = ({ roomId }) => {
    const { reload } = useReload(); // Asumsi useReload sudah ada
    const { data: fetchData } = useFetchData(`/rate-plan?room_type_id=${roomId}`, reload, roomId);
    const { deleteData, loading: deletingData } = useDeleteData('/rate-plan');
    const { deleteLinkData, loading: deletingLinkData } = useDeleteData('/link-rate-plan');

    const { isLoading } = useLoading();

    const handleDelete = useDeleteHandler(deleteData, `Rate telah dihapus`, `Gagal menghapus Rate`);
    const handleLinkDelete = useDeleteHandler(deleteData, `Link Rate telah dihapus`, `Gagal menghapus Link Rate`);
    const { handleSuccess, handleError, showCanvasHandler } = useCanvasHandler();

    const handleUpdateRate = (e, data) => {
        e.preventDefault();
        showCanvasHandler('Create Rate Plan',
            <CreateRatePage
                onSuccess={handleSuccess}
                onError={handleError}
                roomId={data.id}
                initialData={data}
            />
        );
    }

    const handleCreateLinkRate = (e, data) => {
        e.preventDefault();
        showCanvasHandler('Create Link Rate Plan',
            <CreateLinkRatePage
                onSuccess={handleSuccess}
                onError={handleError}
                ratePlanId={data}
            />
        );
    }

    return (
        <>
            <Suspense loader={
                <tr>
                    <td colSpan="4">
                        <Loader />
                    </td>
                </tr>
            } loading={isLoading} type={roomId}>
                {fetchData?.data?.length ? (
                    fetchData.data.map((childRate, index) => (
                        <React.Fragment key={index}>
                            <tr className="child-rate">
                                <td>{childRate.name} (Per Room, {childRate.guest_max} Persons, IDR)</td>
                                <td>—</td>
                                <td>—</td>
                                <td className="text-end">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="default" id="dropdownMenuButton2">
                                            Actions
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#" onClick={(e) => handleUpdateRate(e, childRate)}>Edit</Dropdown.Item>
                                            <Dropdown.Item href="#" onClick={(e) => handleCreateLinkRate(e, childRate.id)}>Create Link Rate</Dropdown.Item>
                                            <Dropdown.Item href="#" className="text-danger" onClick={(e) => {
                                                e.stopPropagation();
                                                handleDelete(childRate.id)
                                            }}>
                                                Remove
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                            {/* Hidden Child Rates for Row 1 */}
                            {childRate.children.length > 0 && childRate.children.map((child, index) => (
                                <tr className="child-rate" key={index}>
                                    <td style={{ paddingLeft: '90px' }}>{child.name} (Per Room, {child.guest_max} Persons, IDR)</td>
                                    <td>—</td>
                                    <td>—</td>
                                    <td className="text-end">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="default" id="dropdownMenuButton2">
                                                Actions
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#" className="text-danger" onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleLinkDelete(child.id)
                                                }}>
                                                    Remove
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                        </React.Fragment>
                    ))
                ) : (
                    <tr>
                        <td colSpan="4" className='text-center'>No Rate Plans found</td>
                    </tr>
                )}
            </Suspense>
        </>
    );
};

const RatePage = () => {
    const { reload, triggerReload } = useReload();
    const { data: fetchData } = useFetchData('/room-type', reload, 'full');
    const { deleteData, loading: deletingData } = useDeleteData('/room-type');

    const { isLoading } = useLoading();

    // Inisialisasi childRatesVisible sebagai array untuk setiap row (misalnya 2 row)
    const [childRatesVisible, setChildRatesVisible] = useState([]);

    // Fungsi untuk toggle child rates untuk baris tertentu
    const toggleChildRates = (index) => {
        const newVisibility = [...childRatesVisible]; // Copy array lama
        newVisibility[index] = !newVisibility[index]; // Toggle nilai di index
        setChildRatesVisible(newVisibility); // Set state baru
    };

    const handleDelete = useDeleteHandler(deleteData, `Room telah dihapus`, `Gagal menghapus Room`);
    const { handleSuccess, handleError, showCanvasHandler } = useCanvasHandler();

    // Fungsi untuk menampilkan modal create
    const handleCreate = (e) => {
        e.preventDefault();
        showCanvasHandler('Create Room',
            <CreateCanvas
                onSuccess={handleSuccess}
                onError={handleError}
            />
        );
    };

    const handleUpdate = (data, e) => {
        e.preventDefault();
        showCanvasHandler(`Edit Room: ${data.name}`,
            <CreateCanvas
                onSuccess={handleSuccess}
                onError={handleError}
                initialData={data}
            />
        );
    };

    const handleCreateRate = (e, data) => {
        e.preventDefault();
        showCanvasHandler('Create Rate Plan',
            <CreateRatePage
                onSuccess={handleSuccess}
                onError={handleError}
                roomId={data}
            />
        );
    }

    return (
        <Suspense loading={isLoading} loader={<FullLoader />} type='full'>
            <LayoutDashboard>
                <div className="container-fluid mt-5">
                    <div className="table-container">
                        <div className="search-container mb-3">
                            {/* Search input */}
                            <div className="input-icon">
                                <input type="text" className="form-control" placeholder="Search..." />
                                <span className="input-icon-addon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="icon icon-tabler icon-tabler-search"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <circle cx="10" cy="10" r="7" />
                                        <line x1="21" y1="21" x2="15" y2="15" />
                                    </svg>
                                </span>
                            </div>

                            {/* Create button */}
                            <button className="btn btn-primary" onClick={handleCreate}>Create</button>
                        </div>

                        {/* Table */}
                        <div className="table">
                            <table className="table table-hover table-vcenter">
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Count Of Rooms</th>
                                        <th>Occupancy</th>
                                        <th className="text-end">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fetchData?.data?.map((roomType, index) => (
                                        <React.Fragment key={index}>
                                            <tr>
                                                <td>
                                                    {/* Toggle Button for Child Rates */}
                                                    <button className="btn-toggle me-2" onClick={() => toggleChildRates(index)}>
                                                        {childRatesVisible[index] ? '−' : '+'}
                                                    </button> {roomType.name}
                                                </td>
                                                <td>{roomType.total_rooms}</td>
                                                <td>A: 2 C: 0 I: 1</td>
                                                <td className="text-end">
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="default" id="dropdownMenuButton1">
                                                            Actions
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item href="#" onClick={(e) => handleUpdate(roomType, e)}>Edit</Dropdown.Item>
                                                            <Dropdown.Item href="#" onClick={(e) => handleCreateRate(e, roomType.id)}>Create Rate</Dropdown.Item>
                                                            <Dropdown.Item href="#" className="text-danger" onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleDelete(roomType.id)
                                                            }}>
                                                                Remove
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>

                                            {/* Hidden Child Rates for Row 1 */}
                                            {childRatesVisible[index] && (
                                                <ChildRates roomId={roomType.id} key={index} />
                                            )}
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        {/* Pagination */}
                        {/* <div className="pagination-container">
                            <nav aria-label="Page navigation">
                                <ul className="pagination">
                                    <li className="page-item active">
                                        <a className="page-link bg-primary" href="#">
                                            1
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div> */}
                    </div >
                </div >
            </LayoutDashboard >
        </Suspense>
    );
};

export default RatePage;
