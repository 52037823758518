import React, { createContext, useState, useContext } from 'react';
import CanvasComponent from 'components/canvas/canvas';

const CanvasContext = createContext();

export const CanvasProvider = ({ children }) => {
    const [canvas, setCanvas] = useState({ show: false, title: '', body: null, close: null, className: null });

    // Fungsi untuk menampilkan canvas
    const showCanvas = ({ title, body, close, className }) => {
        setCanvas({
            show: true,
            title,
            body,
            close,
            className
        });
    };

    // Fungsi untuk menyembunyikan canvas
    const hideCanvas = () => {
        setCanvas((prevState) => ({
            ...prevState,
            show: false
        }));
    };

    return (
        <CanvasContext.Provider value={{ showCanvas, hideCanvas }}>
            {children}
            <CanvasComponent
                {...canvas}
                handleClose={() => {
                    hideCanvas();
                    if (canvas.close) {
                        canvas.close();
                    }
                }}
            />
        </CanvasContext.Provider>
    );
};

// Hook untuk menggunakan canvas di komponen lain
export const useCanvas = () => useContext(CanvasContext);
