import { IconAlertCircle, IconAlertCircleFilled } from '@tabler/icons-react';
import clsx from 'clsx';
import React from 'react';
import { Modal } from 'react-bootstrap';

const ModalComponent = ({ type = 'success', title, message, buttonText, show, onButtonClick, onClose }) => {
    let modalClass = {};

    switch (type) {
        case 'success':
            modalClass = { bg: 'bg-success', button: 'btn-success' };
            break;
        case 'danger':
            modalClass = { bg: 'bg-danger', button: 'btn-danger' };
            break;
        case 'warning':
            modalClass = { bg: 'bg-warning', button: 'btn-warning' };
            break;
        default:
            modalClass = { bg: 'bg-primary', button: 'btn-primary' };
    }

    return (
        <>
            <Modal show={show} onHide={onClose} centered>
                <Modal.Body>
                    <div class={clsx("modal-status", modalClass.bg)}></div>
                    <div class="modal-body text-center py-4">
                        <IconAlertCircle size={48} stroke={1.5} />
                        <h3>{title}</h3>
                        <div class="text-secondary">{message}</div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div class="col">
                        <button className='btn w-100' onClick={onClose}>Cancel</button>
                    </div>
                    <div class="col">
                        <button onClick={() => {
                            onButtonClick();
                            onClose();
                        }} class={clsx("btn w-100", modalClass.button)}>
                            {buttonText}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalComponent;
