import ReservationPage from 'pages/reservation/reservation/reservation-page';
import CalendarPage from 'pages/reservation/calendar/calendar-page';
import React from 'react';
import { URLS } from 'utils/constants';
import AgentSettingsPage from 'pages/reservation/agent-settings/agent-settings-page';
import HousekeepingPage from 'pages/reservation/housekeeping/housekeeping-page';
import DashboardReservation from 'pages/reservation/dashboard/dashboard-page';
import StockInventoryPage from 'pages/reservation/stock-inventory/stock-inventory-page';
import DashboardReport from 'pages/reservation/reports/components/dashboard/dashboard-report';
import OccupancyReport from 'pages/reservation/reports/components/occupancy/occupancy-report';
import AgentReport from 'pages/reservation/reports/components/agent/agent-report';
import RevenueReport from 'pages/reservation/reports/components/revenue/revenue-report';
import SalesReport from 'pages/reservation/reports/components/sales/sales-report';
import { ReservationProvider } from 'contexts/v2/pages/reservation/ReservationContext';

export const reservationRouter = [
    {
        path: URLS.DASHBOARD_ADMIN_RESERVATION_DASHBOARD,
        element: <DashboardReservation />
    },
    {
        path: URLS.DASHBOARD_ADMIN_RESERVATION,
        element:
            <ReservationProvider>
                <ReservationPage />
            </ReservationProvider>
    },
    {
        path: URLS.DASHBOARD_ADMIN_RESERVATION_CALENDAR,
        element: <CalendarPage />
    },
    {
        path: URLS.DASHBOARD_ADMIN_RESERVATION_AGENT_SETTINGS,
        element: <AgentSettingsPage />
    },
    {
        path: URLS.DASHBOARD_ADMIN_RESERVATION_ACCOUNT_EXTRAS,
        element: <CalendarPage />
    },
    {
        path: URLS.DASHBOARD_ADMIN_RESERVATION_HOUSEKEEPING,
        element: <HousekeepingPage />
    },
    {
        path: URLS.DASHBOARD_ADMIN_RESERVATION_STOCK_INVENTORY,
        element: <StockInventoryPage />
    },
    {
        path: URLS.DASHBOARD_ADMIN_RESERVATION_REPORTS,
        children: [
            {
                path: URLS.DASHBOARD_ADMIN_RESERVATION_REPORTS,
                element: <DashboardReport />
            },
            {
                path: URLS.DASHBOARD_ADMIN_RESERVATION_REPORTS_OCCUPANCY,
                element: <OccupancyReport />
            },
            {
                path: URLS.DASHBOARD_ADMIN_RESERVATION_REPORTS_AGENT,
                element: <AgentReport />
            },
            {
                path: URLS.DASHBOARD_ADMIN_RESERVATION_REPORTS_REVENUE,
                element: <RevenueReport />
            },
            {
                path: URLS.DASHBOARD_ADMIN_RESERVATION_REPORTS_SALES,
                element: <SalesReport />
            }
        ]
    }
];