import CurrencyInput from "components/form/currency-input";
import { useCreateData } from "hooks/useApiHooks";
import { useFormChangeHandler } from "hooks/useFormChangeHandler";
import { useSubmitHandler } from "hooks/useSubmitHandler";
import React, { useState } from "react";
import Select from "react-select";
import RoomType from "./roomtype-select";
import RatePlan from "./rateplan-select";
import { useAuth } from "hooks/useAuth";

const ChangeRateAvailability = ({ onSuccess, onError }) => {
    const { user } = useAuth();

    const [uiState, setUiState] = useState({
        type: { label: 'Rate', value: 'rate' },
        room_type: null,
        rate_plan: null,
    });
    const [formData, setFormData] = useState({
        start_date: '',
        end_date: '',
        value: '',
    });

    const { createData: updateAvailability } = useCreateData('/set-availability');
    const { createData: updateRate } = useCreateData('/set-rate');

    const { handleSubmit, isSubmitting, formError } = useSubmitHandler(
        uiState.type.value === 'availability' ? updateAvailability : updateRate,
        onSuccess,
        onError
    );

    const handleBeforeSubmit = (e) => {
        e.preventDefault();

        const payload = {
            values: [
                {
                    date_from: formData.start_date,
                    date_to: formData.end_date,
                    property_id: user?.active_property_id,
                    ...(uiState.type.value === 'availability'
                        ? { availability: Number(formData.value), room_type_id: uiState.room_type.value }
                        : { rate: formData.value, rate_plan_id: uiState.rate_plan.value })
                }
            ]
        };

        handleSubmit(payload, e);
    }

    return (
        <form className="d-flex flex-column" style={{ height: '100%' }} onSubmit={(e) => handleBeforeSubmit(e)}>
            <div className="action-sheet-content flex-grow-1">
                <div className="row mb-3 align-items-center">
                    <label className="col-sm-4 col-form-label d-flex justify-content-end">
                        Type:
                    </label>
                    <div className="col-sm-8">
                        {/* <input
                            type="text"
                            className="form-control djitu-form-control rounded-1"
                            value={formData.type ? 'Availability' : 'Rate'}
                        /> */}
                        <Select
                            value={uiState.type}
                            options={[
                                { label: 'Availability', value: 'availability' },
                                { label: 'Rate', value: 'rate' }
                            ]}
                            onChange={(selectedOption) => {
                                setUiState({ ...uiState, type: selectedOption });
                            }}
                        />
                    </div>
                </div>
                <div className="row mb-3 align-items-center">
                    <label className="col-sm-4 col-form-label d-flex justify-content-end">
                        Room Title:
                    </label>
                    <div className="col-sm-8">
                        {/* <span className="djitu-form-control">{formData.room_title}</span> */}
                        <RoomType setRoomTypeSelected={setUiState} />
                    </div>
                </div>
                {uiState.type.value === 'availability' ? '' : (
                    <div className="row mb-3 align-items-center">
                        <label className="col-sm-4 col-form-label d-flex justify-content-end">
                            Rateplan:
                        </label>
                        <div className="col-sm-8">
                            {/* <span className="djitu-form-control">{formData.rate_plan_name}</span> */}
                            <RatePlan selectedRoomType={uiState.room_type} setSelectedRatePlan={setUiState} />
                        </div>
                    </div>
                )}
                <div className="row mb-3 align-items-center">
                    <label className="col-sm-4 col-form-label d-flex justify-content-end">
                        From Date:
                    </label>
                    <div className="col-sm-8">
                        <input
                            type="date"
                            className="form-control djitu-form-control rounded-1"
                            value={formData.start_date}
                            onChange={e => setFormData({ ...formData, start_date: e.target.value })}
                        />
                    </div>
                </div>

                <div className="row mb-3 align-items-center">
                    <label className="col-sm-4 col-form-label d-flex justify-content-end">
                        To Date:
                    </label>
                    <div className="col-sm-8">
                        <input
                            type="date"
                            className="form-control djitu-form-control rounded-1"
                            value={formData.end_date}
                            onChange={e => setFormData({ ...formData, end_date: e.target.value })}
                        />
                    </div>
                </div>

                <div className="row mb-3 align-items-center">
                    <label className="col-sm-4 col-form-label d-flex justify-content-end">
                        Value:
                    </label>
                    <div className="col-sm-8">
                        {uiState.type.value === 'availability' ? (
                            <input
                                type="text"
                                className="form-control djitu-form-control rounded-1"
                                value={formData.value}
                                onChange={e => setFormData({ ...formData, value: e.target.value })}
                                placeholder="0"
                            />
                        ) : (
                            <CurrencyInput
                                name="extra_child_rate"
                                className="djitu-form-control rounded-1"
                                value={formData.value} // Bind value ke formData
                                onChange={(e, rawValue) => {
                                    e.preventDefault();
                                    setFormData({ ...formData, value: rawValue });
                                }} // Simpan rawValue
                                error={formError?.value} // Menampilkan error jika ada
                                placeholder="Rp. 0" // Placeholder Rupiah default
                            />
                        )}
                    </div>
                </div>
            </div>

            {/* Submit Button */}
            <div className="form-group mt-auto d-grid">
                <button type="submit" className="btn btn-primary btn-block">
                    Change Value
                </button>
            </div>
        </form>
    );
};

export default ChangeRateAvailability;
