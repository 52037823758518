import React from "react";
import LayoutDashboard from "layouts/layout-dashboard-collapse";
import Sidebar from "./reports-sidebar";
import styles from "./content.module.css";
import { IconTrendingUp, IconCalendarEvent } from '@tabler/icons-react'; // Importing icons from Tabler

const ReportsPage = ({ children }) => {
    return (
        <LayoutDashboard>
            <div className="d-flex">
                <Sidebar />
                <div className='container-fluid mt-3'>
                    <h1>Dashboard</h1>
                    <div className="row row-cols-1 row-cols-md-2 g-4 mb-3">
                        <div className="col">
                            <div className={`card h-100 ${styles.card}`}>
                                <div className="card-body">
                                    <div className="d-flex align-items-center mb-3">
                                        {/* Adding the icon */}
                                        <IconTrendingUp size={28} className="me-2 text-primary" />
                                        {/* Title with text-primary class */}
                                        <h5 className="card-title text-primary">Total Revenue by Month</h5>
                                    </div>
                                    <p className="card-text">
                                        Pivot table with sum of debits by property name and room type.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className={`card h-100 ${styles.card}`}>
                                <div className="card-body">
                                    <div className="d-flex align-items-center mb-3">
                                        {/* Adding another icon */}
                                        <IconCalendarEvent size={28} className="me-2 text-primary" />
                                        {/* Title with text-primary class */}
                                        <h5 className="card-title text-primary">Daily Revenue Report</h5>
                                    </div>
                                    <p className="card-text">
                                        Revenue report by type including month-to-day and year-to-day values.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {children}
                </div>
            </div>
        </LayoutDashboard>
    );
};

export default ReportsPage;
