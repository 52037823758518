import React, { useMemo, useState } from 'react';
import { URLS } from "utils/constants";

// komponen
import LayoutDashboard from "layouts/layout-dashboard-collapse";
import Breadcrumb from "components/breadcrumb";
import MonthlyCalendar from "pages/channel-manager/monthly-setup/components/monthly-calendar-2";
import FilterDashboard from "pages/channel-manager/monthly-setup/components/monthly-setup/filter-dashboard";
import Suspense from 'components/suspense/suspense';
import { useFetchData } from 'hooks/useApiHooks';
import { useReload } from 'contexts/ReloadContext';
import { useLoading } from 'contexts/LoadingContext';
import FullLoader from 'components/loader/full-loader';
import { convertDateToYYYYMMDD, getFirstDayOfMonth, getFirstDayOfThisMonth, getLastDayOfMonth, getLastDayOfThisMonth } from 'utils/date-utils';
import { formatDateToString } from 'utils/dateHelpers';

const MonthlySetupPage = () => {
    // State untuk menyimpan tipe event dan data event yang dipilih
    const [selectedEvents, setSelectedEvents] = useState({ type: null, data: [] });
    const [lastClickedEventType, setLastClickedEventType] = useState(null);
    const [todayDate, setTodayDate] = useState(convertDateToYYYYMMDD(new Date()));
    const [lastDate, setLastDate] = useState(getLastDayOfThisMonth());
    const { reload, triggerReload } = useReload();

    const { data: RoomsResource } = useFetchData(`/room-resource-monthly-setup?start_date=${todayDate}&end_date=${lastDate}`, reload);

    const { isLoading } = useLoading();

    // Mempersiapkan resources dari data rooms
    const resourcesPayload = useMemo(() => {
        console.log('RoomsResource', RoomsResource);
        const mappedResources = RoomsResource?.data?.rate?.map(room => ({
            id: room.id.toString(),
            title: room.title,
            children: room.children.map(child => ({
                id: child.id.toString(),
                title: child.title,
                type: child.type
            }))
        })) || [];
        console.log('mappedResources', mappedResources);
        return mappedResources;
    }, [RoomsResource]);

    // Mempersiapkan events dari data available dan price
    const eventsPayload = useMemo(() => {
        const availableEvents = RoomsResource?.data?.rate?.flatMap(room =>
            room.available.map(avail => ({
                resourceId: room.id.toString(),
                title: avail.available,
                start: avail.date,
                end: avail.date,
                isRoom: true,
                bgColor: '#67c17b',
                roomId: room.id.toString(),
                isSelected: false,
                roomDetail: RoomsResource?.data?.rate,
                roomTitle: room.title,
                room_type_id: avail.room_type_id,
                room_type_name: avail.room_type_name,
                property_id: avail.property_id
            }))
        ) || [];

        const priceEvents = RoomsResource?.data?.price?.map(price => ({
            resourceId: price.resourceId,
            title: price.title,
            start: price.start,
            end: price.start,
            type: price.type,
            isRoom: false,
            roomId: price.resourceId.toString(),
            isSelected: false,
            roomDetail: RoomsResource?.data?.rate,
            room_type_id: price.room_type_id,
            room_type_name: price.room_type_name,
            rate_plan_id: price.rate_plan_id,
            rate_plan_name: price.rate_plan_name,
            property_id: price.property_id,
            price: price.price
        })) || [];

        console.log('availableEvents', availableEvents);
        console.log('priceEvents', priceEvents);

        return [...availableEvents, ...priceEvents];
    }, [RoomsResource]);

    const occupancyPayload = useMemo(() => {
        const occupancyEvents = RoomsResource?.data?.occupancy?.map(occupancy => {
            const mappedOccupancy = {
                occupancy: occupancy.occupancy,
                date: occupancy.date,
            }
            return mappedOccupancy;
        }) || [];
        return occupancyEvents;
    }, [RoomsResource]);

    const options = [
        { value: '2024-09', label: 'September 2024' },
        { value: '2024-10', label: 'Oktober 2024' },
        { value: '2024-11', label: 'November 2024' },
        { value: '2024-12', label: 'Desember 2024' },
        { value: '2025-01', label: 'Januari 2025' },
        { value: '2025-02', label: 'Februari 2025' },
        { value: '2025-03', label: 'Maret 2025' },
        { value: '2025-04', label: 'April 2025' },
        { value: '2025-05', label: 'Mei 2025' },
        { value: '2025-06', label: 'Juni 2025' },
        { value: '2025-07', label: 'Juli 2025' },
        { value: '2025-08', label: 'Agustus 2025' },
    ]

    // const handleSelectEvent = (event) => {
    //     setSelectedEvent(event);
    // };

    // Function untuk menangani event yang dipilih dari FullCalendar
    const handleSelectEvent = (selectedEventData) => {
        setSelectedEvents((prevState) => {
            // Jika tipe event berbeda, tampilkan peringatan atau reset
            // if (prevState.type && prevState.type !== selectedEventData.type) {
            //     alert("Cannot select different event types at the same time.");
            //     return prevState;
            // }

            return {
                type: selectedEventData.type,
                data: [...prevState.data, ...selectedEventData.data] // Tambahkan event baru
            };
        });
    };

    // Function untuk reset event selection
    const resetSelectedEvents = () => {
        setSelectedEvents({ type: null, data: [] }); // Reset state event yang dipilih
        setLastClickedEventType(null);
        triggerReload(); // Trigger reload untuk fetch data ulang
    };

    // Fungsi untuk merefresh atau mengupdate kalender setelah submit
    const handleFormSubmit = () => {
        // Di sini, logikanya bisa menambahkan event baru atau memperbarui state
        console.log('Form submitted successfully, refresh the calendar!');
        resetSelectedEvents();
        // Refresh kalender atau fetch ulang data jika diperlukan
    };

    // Fungsi untuk kembali ke hari ini
    const handleBackToToday = () => {
        // make function to get today
        setTodayDate(getFirstDayOfThisMonth());
        setLastDate(getLastDayOfThisMonth());
        triggerReload();
    };

    // Fungsi untuk menampilkan bulan sebelumnya
    const handlePrevMonth = () => {
        // make function to get previous month from today
        const today = new Date(todayDate);
        today.setMonth(today.getMonth() - 1);
        setTodayDate(convertDateToYYYYMMDD(today));
        const last = new Date(lastDate);
        last.setMonth(last.getMonth() - 1);
        setLastDate(convertDateToYYYYMMDD(last));
        triggerReload();
    };

    // Fungsi untuk menampilkan bulan berikutnya
    const handleNextMonth = () => {
        // make function to get next month from today
        const today = new Date(todayDate);
        today.setMonth(today.getMonth() + 1);
        setTodayDate(convertDateToYYYYMMDD(today));
        const last = new Date(lastDate);
        last.setMonth(last.getMonth() + 1);
        setLastDate(convertDateToYYYYMMDD(last));
        triggerReload();
    };

    return (
        <Suspense loader={<FullLoader />} loading={isLoading}>
            <LayoutDashboard>
                <div className="page">
                    <div className="page-body mt-0 vh-100">
                        <div className="container-fluid px-0">
                            {/* <FilterDashboard selectedEvent={selectedEvents} onFormSubmit={handleFormSubmit} clearSelection={resetSelectedEvents} /> */}
                            <MonthlyCalendar
                                resourcesPayload={resourcesPayload}
                                eventsPayload={eventsPayload}
                                optionDates={options}
                                onSelectEvent={handleSelectEvent}
                                lastClickedEventType={lastClickedEventType}
                                setLastClickedEventType={setLastClickedEventType}
                                firstDay={todayDate}
                                lastDay={lastDate}
                                moveNext={handleNextMonth}
                                moveBack={handlePrevMonth}
                                resetToday={handleBackToToday}
                                occupancyPayload={occupancyPayload}
                            />
                        </div>
                    </div>
                </div>
            </LayoutDashboard>
        </Suspense>
    );
};

export default MonthlySetupPage;