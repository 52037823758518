import React from 'react';
import { Card } from 'react-bootstrap';
import { Pie } from 'react-chartjs-2';

const GuestSatisfactionChart = ({ data }) => (
  <Card className="mb-3 shadow-sm flex-fill" style={{ borderRadius: '10px', border: 'none' }}>
    <Card.Body>
      <Card.Title className="text-muted">Guest Satisfaction Rating</Card.Title>
      <Pie data={data} />
    </Card.Body>
  </Card>
);

export default GuestSatisfactionChart;
