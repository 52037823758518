import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { getRequest } from 'services/api';
import { convertCurrency } from 'utils/utils';
import { useCanvasHandler } from 'hooks/useCanvasHandler';
import AsignRoomPage from './asign-room-page';
import ShortReservation from '../../calendar/components/detail-reservation/short-reservation';
import { useReservation } from 'contexts/v2/pages/reservation/ReservationContext';
import CreateReservationPage from '../v2/create-reservation-page';

const ReservationItems = () => {
    const { reservation, setReservation } = useReservation();

    const { handleSuccess, handleError, showCanvasHandler } = useCanvasHandler();

    // Handle assign reservation room
    const handleAssignRoom = (e, data) => {
        e.preventDefault();
        showCanvasHandler('Assign Room',
            <AsignRoomPage
                onSuccess={handleSuccess}
                onError={handleError}
                initialData={data}
            />
        );
    }

    // Handle view reservation
    const handleViewReservation = (e, booking_id) => {
        e.preventDefault();
        showCanvasHandler('View Reservation',
            <ShortReservation bookingId={booking_id} onSuccess={handleSuccess} onError={handleError} />
        );
    }

    // Handle edit reservation
    const handleEditReservation = (e, initialData) => {
        e.preventDefault();
        showCanvasHandler('Edit Reservation',
            <CreateReservationPage initialData={initialData} onSuccess={() => {
                handleSuccess(true, 'Reservation updated successfully');
                reservation.refetch();
            }} onError={handleError} />
        );
    }

    return (
        <>
            {/* Table */}
            <div className="table">
                <table className="table table-hover table-vcenter">
                    <thead>
                        <tr>
                            <th>Reservation</th>
                            <th>Customer</th>
                            <th>Dates Booked</th>
                            <th>Source</th>
                            <th>Price</th>
                            <th>Check In</th>
                            <th>Check Out</th>
                            <th>Status</th>
                            <th className="text-end">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reservation.data?.map((reservation, index) => (
                            <React.Fragment key={index}>
                                <tr>
                                    <td>
                                        {`${reservation.unique_id}`}
                                    </td>
                                    <td>{reservation.customer_name}</td>
                                    <td>{reservation.inserted_at}</td>
                                    <td>{reservation.ota_name}</td>
                                    <td>{convertCurrency(reservation.amount, reservation.currency)}</td>
                                    <td>{reservation.arrival_date}</td>
                                    <td>{reservation.departure_date}</td>
                                    <td>{reservation.status}</td>
                                    <td className="text-end">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="default" id="dropdownMenuButton1">
                                                Actions
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#" onClick={(e) => handleViewReservation(e, reservation.booking_id)}>View</Dropdown.Item>
                                                <Dropdown.Item href="#" onClick={(e) => handleEditReservation(e, reservation)}>Edit</Dropdown.Item>
                                                <Dropdown.Item href="#" onClick={(e) => handleAssignRoom(e, reservation)}>Assign Room</Dropdown.Item>
                                                <Dropdown.Item href="#">
                                                    Remove
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default ReservationItems;