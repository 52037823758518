import React, { useEffect, useState } from 'react';
import LayoutDashboard from 'layouts/layout-dashboard-collapse';
import { Dropdown } from "react-bootstrap";
import { IconCheck, IconX } from '@tabler/icons-react';
import Swal from 'sweetalert2';
import { useCanvasHandler } from 'hooks/useCanvasHandler';
import CreateAgentPage from './components/create-agent-page';
import { useDeleteHandler } from 'hooks/useDeleteHandler';
import { useDeleteData, useFetchData, useUpdateData } from 'hooks/useApiHooks';
import { useReload } from 'contexts/ReloadContext';
import FullLoader from 'components/loader/full-loader';
import { buildQueryString } from 'utils/queryHelpers';
import { useFormChangeHandler } from 'hooks/useFormChangeHandler';
import { useSubmitHandler } from 'hooks/useSubmitHandler';

const SearchComponent = ({ setSearchQuery }) => {
    const [formData, setFormData] = useState({
        search: '',
    });

    // Direct onChange handler
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSearch = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            setSearchQuery(formData.search); // Update parent component's query
            console.log("Searching for:", formData.search); // Check value in console
        }
    };

    return (
        <div className="input-icon">
            <input
                type="text"
                name="search"
                className="form-control"
                placeholder="Search..."
                value={formData.search}
                onChange={handleChange}
                onKeyDown={handleSearch}
            />
            <span className="input-icon-addon">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-search"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <circle cx="10" cy="10" r="7" />
                    <line x1="21" y1="21" x2="15" y2="15" />
                </svg>
            </span>
        </div>
    );
};

const FieldForColor = ({ setAgents, setEditState, userAgents, editState, agents, agent, idx }) => {
    const { updateData: updateAgent } = useUpdateData(`/agent/${agent.id}`);
    const { handleSuccess, handleError, showCanvasHandler } = useCanvasHandler();

    const { handleSubmit, isSubmitting, formError } = useSubmitHandler(
        () => { },
        handleSuccess,
        handleError
    );

    const handleColorChange = (index, value, type) => {
        const updatedAgents = [...agents];
        updatedAgents[index].color = value;
        setAgents(updatedAgents);

        // Trigger edit state for this row
        const updatedEditState = [...editState];
        updatedEditState[index] = true;
        setEditState(updatedEditState);
    };

    const updateDatatoAPI = async (data) => {
        const result = await updateAgent(data);

        if (result.success) {
            handleSuccess(true, 'Color has been changed');  // Callback untuk sukses
        } else {
            handleError(true, 'Failed to change color');  // Callback untuk error
        }
    };

    const handleSave = (index, e) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You are about to save this change.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, save it!'
        }).then((result) => {
            if (result.isConfirmed) {
                updateDatatoAPI({
                    name: agent.name,
                    color: agents[index].color
                });  // Update data to API

                // console.log(`Saving color for agent ${agents[index].name}:`, agents[index].color);
                // const updatedEditState = [...editState];
                // updatedEditState[index] = false; // Reset edit state after saving
                // setEditState(updatedEditState);

                // Swal.fire(
                //     'Saved!',
                //     'Your change has been saved.',
                //     'success'
                // );
            }
        });
    };

    const handleCancel = (index) => {
        const originalColor = userAgents[index].color; // Reset to original color
        const updatedAgents = [...agents];
        updatedAgents[index].color = originalColor;
        setAgents(updatedAgents);

        const updatedEditState = [...editState];
        updatedEditState[index] = false; // Reset edit state after cancel
        setEditState(updatedEditState);
    };

    return (
        <div className="d-flex align-items-center color-input-group">
            <input
                type="color"
                value={agent.color}
                className="form-control form-control-color color-picker"
                onChange={(e) => handleColorChange(idx, e.target.value, 'color')}
            />
            <input
                type="text"
                value={agent.color}
                className="form-control ms-2 color-text"
                onChange={(e) => handleColorChange(idx, e.target.value, 'text')}
            />
            {editState[idx] && (
                <div className="d-flex ms-2">
                    <button className="btn btn-success btn-sm btn-icon me-2" onClick={(e) => handleSave(idx, e)}>
                        <IconCheck size={14} />
                    </button>
                    <button className="btn btn-danger btn-sm btn-icon" onClick={() => handleCancel(idx)}>
                        <IconX size={14} />
                    </button>
                </div>
            )}
        </div>
    );
};

const AgentSettingsPage = () => {
    const defaultAgents = [
        {
            name: 'Default Agent 1',
            color: '#FF5733',
        },
        {
            name: 'Default Agent 2',
            color: '#33FF57',
        },
    ];

    const [agents, setAgents] = useState(null);
    const [editState, setEditState] = useState(null); // Track edit state for each row
    const [searchQuery, setSearchQuery] = useState('');

    const queryString = buildQueryString({ search: searchQuery });

    const { reload } = useReload();
    const { data: userAgents, loading: fetchingAgent } = useFetchData(`/agent${queryString}`, reload, 'agents');
    const { deleteData, loading: deletingData } = useDeleteData('/agent');


    useEffect(() => {
        setAgents(userAgents?.data);
        setEditState(Array(userAgents?.data?.length).fill(false));
    }, [userAgents]);

    const handleDelete = useDeleteHandler(deleteData, 'Agent has been deleted', 'Failed to delete Agent');
    const { handleSuccess, handleError, showCanvasHandler } = useCanvasHandler();

    const handleCreateAgent = (e, data) => {
        e.preventDefault();

        const isUpdateMode = !!data;

        showCanvasHandler(
            isUpdateMode ? 'Update Agent' : 'Create Agent',
            <CreateAgentPage
                initialData={isUpdateMode ? data : null}
                onSuccess={handleSuccess}
                onError={handleError}
            />
        );
    };

    if (fetchingAgent || deletingData) {
        return <FullLoader />;
    }

    return (
        <LayoutDashboard>
            <div className="container-fluid mt-5">
                <div className="table-container vh-100">
                    <div className="search-container mb-3">
                        {/* Search input */}
                        <SearchComponent setSearchQuery={setSearchQuery} />

                        {/* Create button */}
                        <button className="btn btn-primary" onClick={handleCreateAgent}>Create</button>
                    </div>

                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Agent Name</th>
                                <th>Assigned Color</th>
                                <th>Website</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th className='text-end'>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* {defaultAgents.map((agent, idx) => (
                                <tr key={idx} className="default-agent">
                                    <td>{agent.name}</td>
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <input type="color" value={agent.color} className="form-control form-control-color" disabled />
                                            <input type="text" value={agent.color} className="form-control ms-2" disabled />
                                        </div>
                                    </td>
                                    <td className='text-end'>
                                        <button className="btn btn-outline-secondary default-agent-action" disabled>
                                            No Action
                                        </button>
                                    </td>
                                </tr>
                            ))} */}
                            {agents?.length > 0 && agents.map((agent, idx) => (
                                <tr key={idx}>
                                    <td>{agent.name}</td>
                                    <td>
                                        <FieldForColor setAgents={setAgents} setEditState={setEditState} userAgents={userAgents} editState={editState} agents={agents} agent={agent} idx={idx} />
                                        {/* <div className="d-flex align-items-center color-input-group">
                                            <input
                                                type="color"
                                                value={agent.color}
                                                className="form-control form-control-color color-picker"
                                                onChange={(e) => handleColorChange(idx, e.target.value, 'color')}
                                            />
                                            <input
                                                type="text"
                                                value={agent.color}
                                                className="form-control ms-2 color-text"
                                                onChange={(e) => handleColorChange(idx, e.target.value, 'text')}
                                            />
                                            {editState[idx] && (
                                                <div className="d-flex ms-2">
                                                    <button className="btn btn-success btn-sm btn-icon me-2" onClick={() => handleSave(idx)}>
                                                        <IconCheck size={14} />
                                                    </button>
                                                    <button className="btn btn-danger btn-sm btn-icon" onClick={() => handleCancel(idx)}>
                                                        <IconX size={14} />
                                                    </button>
                                                </div>
                                            )}
                                        </div> */}
                                    </td>
                                    <td>{agent?.website}</td>
                                    <td>{agent?.email}</td>
                                    <td>{agent?.phone}</td>
                                    <td className="text-end">
                                        <Dropdown>
                                            <Dropdown.Toggle variant="default" id="dropdownMenuButton1">
                                                Actions
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#" onClick={(e) => handleCreateAgent(e, agent)}>Edit</Dropdown.Item>
                                                <Dropdown.Item href="#" onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleDelete(agent.id);
                                                }}>
                                                    Remove
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </LayoutDashboard>
    );
};

export default AgentSettingsPage;