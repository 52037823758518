import { createContext, useContext, useEffect, useState } from "react";
import { getRequest } from "services/api";
import { buildQueryString } from "utils/queryHelpers";

const ReservationContext = createContext();

export const ReservationProvider = ({ children }) => {
    const [reservation, setReservation] = useState({
        data: [],
        pagination: {},
        error: null,
        isLoading: false,
        refetch: () => fetchReservations()
    });

    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalPages: 1
    });

    const [filterOption, setFilterOption] = useState({
        search: '',
        check_in: '',
        check_out: '',
        status: '',
        agent_id: '',
        is_cancelled: false
    });

    const queryString = buildQueryString({
        search: filterOption.search,
        page: pagination.currentPage,
        check_in: '',
        check_out: '',
        status: filterOption.status === 'all' ? '' : filterOption.status,
        agent_id: filterOption.agent_id === 'all' ? '' : filterOption.agent_id,
        is_cancelled: (filterOption.is_cancelled ? 1 : 0)
    });

    const fetchReservations = async () => {
        setReservation({ ...reservation, isLoading: true });
        try {
            const response = await getRequest(`/reservation${queryString}`);
            if (response.success) {
                setReservation((prev) => ({
                    ...prev,
                    data: response.data,
                    pagination: response.pagination,
                    error: null
                }));

                setPagination((prev) => ({
                    ...prev,
                    totalPages: response.pagination.last_page
                }));
            }
        } catch (error) {
            setReservation((prev) => ({ ...prev, error: error }));
        } finally {
            setReservation((prev) => ({ ...prev, isLoading: false }));
        }
    }

    useEffect(() => {
        fetchReservations();
    }, [queryString]);

    return (
        <ReservationContext.Provider value={{ reservation, setReservation, pagination, setPagination, filterOption, setFilterOption }}>
            {children}
        </ReservationContext.Provider>
    );
}

export const useReservation = () => {
    const context = useContext(ReservationContext);
    if (!context) {
        throw new Error('useReservation must be used within a ReservationProvider');
    }
    return context;
}