import React, { useState } from 'react';

import Logo from 'components/logo';
import "./login.css";

import { useAuth } from 'hooks/useAuth';
import Swal from 'sweetalert2';
import Button from 'components/button/button';

export default function Login() {
    const { login } = useAuth();
    const [loading, setLoading] = useState(false);
    const [credentials, setCredentials] = useState({ email: '', password: '' });

    const handleChange = (e) => {
        setCredentials({
            ...credentials,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            await login(credentials);
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: 'Failed to login',
            });
        } finally {
            setLoading(false);
        }
    };
    return (
        <>
            <div className="page page-center">
                <div className='row g-0'>
                    {/* Left side - Illustration */}
                    <div className="col-md-8 d-none d-md-block">
                        <div className="h-100 bg-cover" style={{ backgroundImage: 'url("/2024-07-30.jpg")' }}>
                            {/* Optional content in illustration area, e.g., title, overlay */}
                        </div>
                    </div>

                    {/* Right side - Login Form */}
                    <div className="col-12 col-lg-4">
                        <div className="card card-md vh-100">
                            <div className='container'>
                                <div className="card-body padding-login">
                                    {/* Logo */}
                                    <Logo height="50" className='mb-5 mt-2' />

                                    {/* Login Title */}
                                    <h2 className="card-title fs-2 fw-bolder mb-4 pt-2">Login</h2>
                                    <h4 className="fs-3 fw-bold mb-2">Login to your account</h4>
                                    <p className="text-muted">
                                        Thank you for using <strong>DjituApps</strong> as your application partner. Let's access our best App for you.
                                    </p>

                                    {/* Form */}
                                    <form onSubmit={handleSubmit} className='mt-4 pt-2'>
                                        <div className="mb-4">
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="email"
                                                name="email"
                                                placeholder="Email"
                                                autoComplete="off"
                                                value={credentials.email}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <input
                                                className="form-control"
                                                id="password"
                                                type="password"
                                                name="password"
                                                placeholder="Password"
                                                required
                                                minLength={6}
                                                autoComplete="off"
                                                value={credentials.password}
                                                onChange={handleChange}
                                            />
                                        </div>

                                        {/* Remember Me & Forgot Password */}
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <label className="form-check">
                                                <input className="form-check-input" type="checkbox" />
                                                <span className="form-check-label">Remember me</span>
                                            </label>
                                            <a href="/forgot-password" className="small text-primary">
                                                Forgot password?
                                            </a>
                                        </div>

                                        {/* Login Button */}
                                        <div className="mb-3">
                                            <Button loading={loading} className="w-100 btn-primary">
                                                Sign in
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}