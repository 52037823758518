import React, { useEffect, useState } from 'react';
import { FormControl, ListGroup, Form } from 'react-bootstrap';
import styles from './search-reservation.module.css'; // Import the CSS module
import { getRequest } from 'services/api';
import Loader from 'components/loader/loader';

const SearchReservations = () => {
    const [search, setSearch] = useState('');
    const [temporarySearch, setTemporarySearch] = useState('');
    const [reservations, setReservations] = useState({
        data: [],
        loading: false,
        error: null,
    });

    const fetchReservations = async ({ search }) => {
        // Start loading
        setReservations((prev) => ({ ...prev, loading: true }));

        try {
            // Fetch data from API
            const response = await getRequest(`/reservation?search=${search}&limit=3`);
            if (response.success) {
                setReservations((prev) => ({ ...prev, data: response.data, error: null }));
            } else {
                setReservations((prev) => ({ ...prev, error: 'Failed to fetch reservations.', loading: false }));
            }
        } catch (error) {
            // Handle error
            setReservations((prev) => ({ ...prev, error: 'Something went wrong. Please try again later.', loading: false }));
        } finally {
            // Ensure loading is turned off
            setReservations((prev) => ({ ...prev, loading: false }));
        }
    };


    // Function to handle search input and filter results
    const handleSearch = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            setSearch(temporarySearch);
        }
    };

    useEffect(() => {
        // Fetch reservations when search value changes
        fetchReservations({ search });
    }, [search]);

    return (
        <>
            <input
                type='text'
                placeholder='Search reservation'
                className='form-control me-2'
                value={temporarySearch}
                onChange={(e) => setTemporarySearch(e.target.value)}
                onKeyDown={handleSearch}
            />

            {/* Floating search results */}

            {search && (
                reservations.loading ? (
                    <div className={styles.searchResults}>
                        <Loader />
                    </div>
                ) : (reservations.data.length <= 0 ? (
                    <div className={styles.searchResults}>
                        {/* <p className='text-center my-4'>No reservations found</p> */}
                        <p className='text-center my-4'>{reservations.error}</p>
                    </div>
                ) : (
                    <div className={styles.searchResults}>
                        <ListGroup>
                            {reservations?.data.map((result) => (
                                <ListGroup.Item key={result.id} className={styles.listItem}>
                                    <div>
                                        <small>Reservation ID {result.unique_id}</small><br />
                                        <strong>{result.customer_name}</strong><br />
                                        {result.booking_rooms.map((room) => (
                                            <small key={room.id}>{room.room_type?.name} : {room.room?.room_number}</small>
                                        ))}
                                    </div>
                                    <div>
                                        <small>{result.arrival_date} - {result.departure_date}</small><br />
                                        <span className='badge bg-primary text-light'>
                                            {result.status}
                                        </span>
                                    </div>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </div>
                ))
            )}
        </>
    );
};

export default SearchReservations;