import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { postRequest, getRequest } from 'services/api';

// Membuat konteks untuk autentikasi
export const AuthContext = createContext();

export function AuthProvider({ children }) {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    // Fungsi untuk login
    const login = async (credentials) => {
        try {
            const response = await postRequest('/login', credentials);

            localStorage.setItem('token', response.token);
            setUser(response.data);
            setLoading(false); // Set loading ke false setelah login berhasil
            navigate('/');
        } catch (error) {
            console.error('Login error:', error);
            throw error;
            // alert('Login failed');
        }
    };

    // Fungsi untuk logout
    const logout = () => {
        localStorage.removeItem('token');
        setUser(null);
        setLoading(false); // Set loading false ketika logout
        navigate('/login');
    };

    const getUser = async () => {
        try {
            const response = await getRequest('/me');
            setUser(response.data);
        } catch (error) {
            console.error('Get user error:', error);
            logout(); // Logout jika gagal mendapatkan user
        } finally {
            setLoading(false); // Set loading ke false setelah data user diambil
        }
    };

    // Mengecek token saat aplikasi pertama kali di-mount
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token && user === null) {
            getUser();
        } else {
            setLoading(false); // Set loading ke false jika tidak ada token
        }
    }, []);

    return (
        <AuthContext.Provider value={{ user, login, logout, loading }}>
            {children}
        </AuthContext.Provider>
    );
}
