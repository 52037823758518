import { Offcanvas } from 'react-bootstrap';

const Canvas = ({ show, handleClose, title, body, className }) => {
    return (
        <Offcanvas show={show} onHide={handleClose} placement='end' style={{ width: '45%' }}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{title}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className={className}>
                {body}
            </Offcanvas.Body>
        </Offcanvas>
    )
}

export default Canvas;