import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useFormChangeHandler } from "hooks/useFormChangeHandler";
import { useSubmitHandler } from "hooks/useSubmitHandler";
import { useCreateData, useFetchData, useUpdateData } from "hooks/useApiHooks";
import { useReload } from "contexts/ReloadContext";

const AsignRoomPage = ({ initialData, onSuccess, onError }) => {
    const API_URL = '/rate-plan';

    const { reload } = useReload();
    // State untuk menyimpan form data. Jika initialData tersedia, isi form untuk update
    const [formData, setFormData] = useState({
        assign_room: [], // Assign room: {reservation_id, room_type_id, room_id}
    });

    // API hook untuk create dan update
    const { data: fetchRoomTypeData } = useFetchData('/room-type?all=1', reload, 'component');
    // const { createData: createData, loading: creatingProduct } = useCreateData(API_URL);

    const createData = async (formData) => {
        return true;
    };

    // Gunakan hook untuk menangani perubahan form
    const handleChange = (e) => {
        const { name, value } = e.target;
        const [arrayName, index, field] = name.split(/[[\].]+/).filter(Boolean); // Memecah "assign_room[0].room_type_id" jadi ["assign_room", "0", "room_type_id"]

        setFormData(prevState => {
            const updatedAssignRoom = [...prevState.assign_room];
            updatedAssignRoom[index] = {
                ...updatedAssignRoom[index],
                [field]: value
            };
            return {
                ...prevState,
                [arrayName]: updatedAssignRoom
            };
        });
    };

    // Gunakan hook untuk menangani pengiriman form
    const { handleSubmit, isSubmitting, formError } = useSubmitHandler(
        createData, // Gunakan updateData jika dalam mode update
        onSuccess,
        onError
    );

    return (
        <form onSubmit={(e) => handleSubmit(formData, e)} className="d-flex flex-column" style={{ height: '100%' }}>
            <div className="action-sheet-content flex-grow-1">
                {/* Reservation Details */}
                <div className="row mb-3 align-items-center">
                    <label className="col-sm-4 col-form-label d-flex justify-content-end">
                        Reservation ID:
                    </label>
                    <div className="col-sm-8">
                        <p className="form-control-plaintext">{initialData.unique_id}</p>
                    </div>
                </div>
                <div className="row mb-3 align-items-center">
                    <label className="col-sm-4 col-form-label d-flex justify-content-end">
                        Arrival Date:
                    </label>
                    <div className="col-sm-8">
                        <p className="form-control-plaintext">{initialData.arrival_date}</p>
                    </div>
                </div>
                <div className="row mb-3 align-items-center">
                    <label className="col-sm-4 col-form-label d-flex justify-content-end">
                        Departure Date:
                    </label>
                    <div className="col-sm-8">
                        <p className="form-control-plaintext">{initialData.departure_date}</p>
                    </div>
                </div>
                <div className="row mb-3 align-items-center">
                    <label className="col-sm-4 col-form-label d-flex justify-content-end">
                        Occupancy:
                    </label>
                    <div className="col-sm-8">
                        <p className="form-control-plaintext">
                            {initialData.occupancy.adults} Adults, {initialData.occupancy.children || 0} Children
                        </p>
                    </div>
                </div>
                <div className="row mb-3 align-items-center">
                    <label className="col-sm-4 col-form-label d-flex justify-content-end">
                        Total Amount:
                    </label>
                    <div className="col-sm-8">
                        <p className="form-control-plaintext">
                            {initialData.currency} {initialData.amount}
                        </p>
                    </div>
                </div>
                <div className="row mb-3 align-items-center">
                    <label className="col-sm-4 col-form-label d-flex justify-content-end">
                        OTA Reservation Code:
                    </label>
                    <div className="col-sm-8">
                        <p className="form-control-plaintext">{initialData.ota_reservation_code}</p>
                    </div>
                </div>
                {initialData.notes && (
                    <div className="row mb-3 align-items-center">
                        <label className="col-sm-4 col-form-label d-flex justify-content-end">
                            Notes:
                        </label>
                        <div className="col-sm-8">
                            <p className="form-control-plaintext">{initialData.notes}</p>
                        </div>
                    </div>
                )}

                {initialData.rooms.map((room, index) => (
                    <React.Fragment key={index}>
                        <h3 className="mt-6 border-bottom fw-normal">Room {index + 1}</h3>
                        <div className="row mb-3 align-items-center">
                            <label htmlFor="room_type" className="col-sm-4 col-form-label d-flex justify-content-end">Room Type: </label>
                            <div className="col-sm-8">
                                <select
                                    name={`assign_room[${index}].room_type_id`} // Gunakan `index` untuk akses posisi array
                                    className={clsx("form-control djitu-form-control rounded-1", formError?.assign_room[index]?.room_type_id && 'is-invalid')}
                                    value={formData.assign_room[index]?.room_type_id || ''} // Tambahkan fallback value ''
                                    onChange={handleChange}
                                >
                                    <option disabled value=''>Select Room Type</option>
                                    {fetchRoomTypeData?.data?.map(roomType => (
                                        <option key={roomType.id} value={roomType.id}>{roomType.name}</option>
                                    ))}
                                </select>

                                {formError?.assign_room[index]?.room_type_id && <div className="invalid-feedback">{formError?.assign_room[index]?.room_type_id}</div>}
                            </div>
                        </div>
                        {formData.assign_room[index]?.room_type_id && (
                            <div className="row mb-3 align-items-center">
                                <label htmlFor="room" className="col-sm-4 col-form-label d-flex justify-content-end">Room: </label>
                                <div className="col-sm-8">
                                    <select
                                        name={`assign_room[${index}].room_id`} // Gunakan properti `.room_id`
                                        className={clsx("form-control djitu-form-control rounded-1", formError?.assign_room[index]?.room_id && 'is-invalid')}
                                        value={formData.assign_room[index]?.room_id || ''} // Tambahkan fallback value ''
                                        onChange={handleChange}
                                    >
                                        <option disabled value=''>Select Room</option>
                                        {fetchRoomTypeData?.data?.find(roomType => roomType.id === formData.assign_room[index]?.room_type_id)?.rooms?.map(room => (
                                            <option key={room.id} value={room.id}>{room.room_number}</option>
                                        ))}
                                    </select>

                                    {formError?.assign_room[index]?.room_id && <div className="invalid-feedback">{formError?.assign_room[index]?.room_id}</div>}
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                ))}
            </div>

            {/* Submit Button */}
            <div className="form-group mt-auto d-grid">
                <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting}>
                    Assign Room
                </button>
            </div>
        </form>
    );
};

export default AsignRoomPage;
