import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getRequest } from 'services/api';

const RatePlan = ({ selectedRoomType, setSelectedRatePlan }) => {
    const [ratePlan, setRatePlan] = useState({
        data: [],
        loading: false,
        error: null,
    });

    const fetchRatePlan = async () => {
        setRatePlan((prev) => ({ ...prev, loading: true }));

        try {
            const response = await getRequest(`/rate-plan?room_type_id=${selectedRoomType?.value}`);
            if (response.success) {
                setRatePlan((prev) => ({ ...prev, data: response.data.map((plan) => ({ label: plan.name, value: plan.id })), error: null }));
            } else {
                setRatePlan((prev) => ({ ...prev, error: 'Failed to fetch rate plan.', loading: false }));
            }
        } catch (error) {
            setRatePlan((prev) => ({ ...prev, error: 'Something went wrong. Please try again later.', loading: false }));
        } finally {
            setRatePlan((prev) => ({ ...prev, loading: false }));
        }
    };

    useEffect(() => {
        fetchRatePlan();
    }, [selectedRoomType]);

    return (selectedRoomType ? ratePlan.loading ? (
        <span className="text-muted">Loading...</span>
    ) : (
        <Select
            options={ratePlan.data}
            onChange={(selectedOption) => {
                setSelectedRatePlan((prev) => ({ ...prev, rate_plan: selectedOption }));
            }}
        />
    ) : <span className="text-muted">Please select room type first</span>);
};

export default RatePlan;