import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import styles from './channel-manager-mapping-page.module.css';
import LayoutDashboard from 'layouts/layout-dashboard-collapse';
import { IconRefresh, IconCheck, IconPlugConnectedX, IconDeviceFloppy, IconHttpConnect, IconPlugConnected } from '@tabler/icons-react';
import { useCreateData, useFetchData } from 'hooks/useApiHooks';
import Suspense from 'components/suspense/suspense';
import FullLoader from 'components/loader/full-loader';
import { useLoading } from 'contexts/LoadingContext';
import { useReload } from 'contexts/ReloadContext';
import { postRequest } from 'services/api';

const ChannelManagerSelectRatePlan = ({ localRate, ratePlan, selectedChannelManagerRatePlans }) => {
    return (
        <select className="form-select" onChange={(e) => selectedChannelManagerRatePlans(e, localRate)}>
            <option selected disabled>Select Rate Plan</option>
            {ratePlan?.data?.map((rate, index) => (
                <option key={index} value={rate.attributes.id}>{rate.attributes.title}</option>
            ))}
        </select>
    );
};

const RatePlanMapping = ({ room, selectedRoom, componentId, channelManagerRatePlans, selectedChannelManagerRatePlans }) => {
    const [isConnected, setIsConnected] = useState(false);

    const { reload } = useReload();

    const { data: fetchRatePlans } = useFetchData(`/rate-plan?room_type_id=${room.id}`, reload, `component-${componentId}`);

    const { isLoading } = useLoading();

    const connectionStatus = () => {
        if (room.channex_id === null) {
            // Find if any object in selectedRoom has a matching room_id
            const matchedRoom = selectedRoom.find(selected => {
                return selected.room_id === room.id;
            });

            // If matchedRoom exists, return true, otherwise return false
            return matchedRoom ? true : false;
        }

        // If room.channex_id is not null, return true
        return true;
    };

    const haveChannelManager = (ratePlan) => {
        return ratePlan.channex_id === null ? false : true;
    };

    useEffect(() => {
        // Whenever selectedRoom or room changes, trigger connectionStatus and update the state
        setIsConnected(connectionStatus());
        console.log('selectedRoom:', selectedRoom);
    }, [JSON.stringify(selectedRoom)]);


    return (
        <Suspense loading={isLoading} loader={
            <tr>
                <td>
                    <div className="d-flex justify-content-between align-items-center placeholder-glow">
                        <span className="placeholder col-6"></span>
                        <span className="badge placeholder bg-secondary col-3 ms-2"></span>
                    </div>
                </td>
                <td className="text-center">
                    <span className={`placeholder ${styles.mappingArrow}`}>&#x2192;</span>
                </td>
                <td colSpan="3">
                    <div className="placeholder-glow">
                        <span className="placeholder col-6"></span>
                    </div>
                </td>
            </tr>
        } type={`component-${componentId}`}>
            {fetchRatePlans?.data?.map((ratePlan, index) => (
                <tr key={index}>
                    <td>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className={styles.rateplanTitle}>{ratePlan.name}</div>
                            <span className="badge bg-orange-lt ms-2">Rate Plan</span>
                        </div>
                    </td>
                    <td className="text-center">
                        <span className={styles.mappingArrow}>&#x2192;</span>
                    </td>
                    <td colSpan="3">
                        {isConnected ? (
                            <>
                                {haveChannelManager(ratePlan) ? (
                                    <span className="text-success d-flex align-items-center">
                                        <IconCheck className="badge bg-teal text-teal-fg rounded-circle me-2" /> Connected
                                    </span>
                                ) : (
                                    <ChannelManagerSelectRatePlan localRate={ratePlan} ratePlan={channelManagerRatePlans} selectedChannelManagerRatePlans={selectedChannelManagerRatePlans} />
                                )}
                            </>
                        ) : (
                            <span className="text-muted">
                                <IconPlugConnectedX className="badge rounded-circle me-2" />
                                Connect the room first
                            </span>
                        )}
                    </td>
                </tr>
            ))}
        </Suspense>
    );
};

const ChannelManagerMappingPage = () => {
    const [selectedRoom, setSelectedRoom] = useState([]);
    const [selectedRatePlan, setSelectedRatePlan] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { reload, triggerReload } = useReload();
    const { data: fetchRooms } = useFetchData('/room-type', reload);
    const { data: fetchChannelManagerRooms } = useFetchData('/channex/room-type', reload);
    const { data: fetchChannelManagerRatePlans } = useFetchData('/channex/rate-plan', reload);

    const { isLoading } = useLoading();

    const handleSelectRoom = (e, room) => {
        setSelectedRoom(prevData => {
            const existingRoomIndex = prevData.findIndex(item => item.room_id === room.id);

            // If the room already exists in the array, update the channex_id
            if (existingRoomIndex !== -1) {
                const updatedData = [...prevData]; // Create a copy of the array
                updatedData[existingRoomIndex] = {
                    ...updatedData[existingRoomIndex],
                    channex_id: e.target.value // Update the channex_id
                };
                return updatedData; // Return the updated array
            }

            // If the room does not exist, create a new object
            return [...prevData, {
                room_id: room.id,
                channex_id: e.target.value,
            }];
        });
    };

    const handleSelectRatePlan = (e, ratePlan) => {
        setSelectedRatePlan(prevData => {
            const existingRatePlanIndex = prevData.findIndex(item => item.rate_plan_id === ratePlan.id);

            // If the rate plan already exists in the array, update the channex_id
            if (existingRatePlanIndex !== -1) {
                const updatedData = [...prevData]; // Create a copy of the array
                updatedData[existingRatePlanIndex] = {
                    ...updatedData[existingRatePlanIndex],
                    channex_id: e.target.value // Update the channex_id
                };
                return updatedData; // Return the updated array
            }

            // If the rate plan does not exist, create a new object
            return [...prevData, {
                rate_plan_id: ratePlan.id,
                channex_id: e.target.value,
            }];
        });
    };


    const haveChannelManager = (room) => {
        return room.channex_id === null ? false : true;
    };

    const errorMessage = (result) => {
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: result?.message || 'Failed to connect room to Channel Manager',
        });
    };

    const submitFunction = async (formData, url) => {
        let status = false;

        try {
            // Submit the formData to the API
            const result = await postRequest(url, formData);

            // Handle success
            if (result?.success) {
                status = true;
            } else {
                // Handle failure
                errorMessage(result);
                status = false;
            }
        } catch (error) {
            // Handle API error
            errorMessage(error);
            status = false;
        }

        return status;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        if (selectedRoom.length == 0 && selectedRatePlan == 0) {
            const result = {
                message: 'You need to select data first before connect'
            }
            errorMessage(result);
            setIsSubmitting(false);
            return;
        }

        let allSuccess = true;  // Variable to track if all requests are successful

        // Loop through selectedRoom
        for (let room of selectedRoom) {
            const { room_id, channex_id } = room;
            // Construct the dynamic URL for room
            const roomUrl = `/room-type/${room_id}/mappping-channex`;

            const result = await submitFunction({ channex_id }, roomUrl);

            // If any submission fails, stop the loop and display error
            if (!result) {
                allSuccess = false;  // Set flag to false on failure
                setIsSubmitting(false);
                return; // Exit the loop if any request fails
            }
        }

        // Loop through selectedRatePlan
        for (let ratePlan of selectedRatePlan) {
            const { rate_plan_id, channex_id } = ratePlan;
            // Construct the dynamic URL for rate plan
            const ratePlanUrl = `/rate-plan/${rate_plan_id}/mappping-channex`;

            const result = await submitFunction({ channex_id }, ratePlanUrl);

            // If any submission fails, stop the loop and display error
            if (!result) {
                allSuccess = false;  // Set flag to false on failure
                setIsSubmitting(false);
                return; // Exit the loop if any request fails
            }
        }

        // If all submissions are successful
        if (allSuccess) {
            Swal.fire({
                icon: "success",
                title: "Success!",
                text: 'All rooms and rate plans have been successfully connected to Channel Manager',
            });
        }

        setIsSubmitting(false);
        triggerReload();
    };


    return (
        <Suspense loader={<FullLoader />} loading={isLoading}>
            <LayoutDashboard>
                <div className="container-fluid mt-5">
                    <div className="table-container vh-100">
                        <div className="d-flex justify-content-between mb-3">
                            {/* Dropdown for Mapping Filter */}
                            <div className="form-group me-2">
                                <select id="filterSelect" className="form-select" defaultValue="all">
                                    <option value="" disabled>Select Filter</option>
                                    <option value="all">All</option>
                                    <option value="connected">Connected</option>
                                    <option value="notConnected">Not Connected</option>
                                </select>
                            </div>

                            {/* Button to Refresh or Update */}
                            <button className="btn btn-success">
                                <IconRefresh size={20} className="me-2" />
                                Refresh
                            </button>
                        </div>

                        {/* Table */}
                        <table className="table table-hover align-middle">
                            <thead>
                                <tr>
                                    <th>Local System</th>
                                    <th>Mapping</th>
                                    <th>Channel Manager</th>
                                </tr>
                            </thead>
                            <tbody>
                                {fetchRooms?.data?.map((room, index) => (
                                    <React.Fragment key={index}>
                                        <tr>
                                            <td>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <span>{room.name}</span>
                                                    <span className={`badge bg-blue-lt ms-2`}>Room</span>
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <span className={styles.mappingArrow}>&#x2192;</span>
                                            </td>
                                            <td>
                                                {haveChannelManager(room) ? (
                                                    <span className="text-success d-flex align-items-center">
                                                        <IconCheck className="badge bg-teal text-teal-fg rounded-circle me-2" /> Connected
                                                    </span>
                                                ) : (
                                                    <select className="form-select" onChange={(e) => handleSelectRoom(e, room)}>
                                                        <option selected disabled>Select Room</option>
                                                        {fetchChannelManagerRooms?.data?.map((cmRoom, index) => (
                                                            console.log(cmRoom),
                                                            <option key={index} value={cmRoom.attributes.id}>{cmRoom.attributes.title}</option>
                                                        ))}
                                                    </select>
                                                )}
                                            </td>
                                        </tr>
                                        <RatePlanMapping room={room} selectedRoom={selectedRoom} componentId={index} channelManagerRatePlans={fetchChannelManagerRatePlans} selectedChannelManagerRatePlans={handleSelectRatePlan} />
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                        <div className="d-flex align-items-end justify-content-end mb-3 mt-3">
                            {/* Button to Refresh or Update */}
                            <button className="btn btn-primary" onClick={handleSubmit} disabled={isSubmitting}>
                                <IconPlugConnected size={20} className="me-2" />
                                Connect
                            </button>
                        </div>
                    </div>
                </div>
            </LayoutDashboard>
        </Suspense>
    );
};

export default ChannelManagerMappingPage;
