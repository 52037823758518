import { useState, useEffect } from 'react';
import { useFetchData } from 'hooks/useApiHooks';
import { buildQueryString } from 'utils/queryHelpers';
import { debounce } from 'lodash';

/**
 * Custom hook to manage search query and trigger reload for refetching data.
 * @param {string} initialSearch - The initial search query (optional).
 * @returns {object} - The searchQuery, reload state, and a debounced function to change the search query.
 */
export const useHandleSearch = (initialSearch = '') => {
    const [searchQuery, setSearchQuery] = useState(initialSearch);
    const [reload, setReload] = useState(false);

    // Debounced function to handle search query changes and trigger reload
    const handleSearchQueryChange = debounce((query) => {
        setSearchQuery(query);
        setReload(prev => !prev); // Toggle reload to refetch data
    }, 500); // Debounce delay (500ms)

    return {
        searchQuery,
        reload,
        handleSearchQueryChange
    };
};

/**
 * Custom hook to fetch and manage options for a select component.
 * @param {string} url - The base URL for the API endpoint.
 * @param {string} searchQuery - The search query for filtering options.
 * @param {string} valueKey - The key to use for option values (default: 'id').
 * @param {string} labelKey - The key to use for option labels (default: 'name').
 * @param {boolean} reload - Flag to refetch data when toggled.
 * @returns {object} - The state containing options, loading status, and a handler to change the search query.
 */
export const useSelectOptions = (url, searchQuery = '', valueKey = 'value', labelKey = 'text', reload = false) => {
    const [options, setOptions] = useState([]);

    // Construct the query string using the search query.
    const queryString = buildQueryString({ search: searchQuery });
    const { data, loading } = useFetchData(`${url}${queryString}`, reload, 'component');

    // Update options when data is fetched.
    useEffect(() => {
        if (data && data.success) {
            // Jika data adalah array
            if (Array.isArray(data.data)) {
                setOptions(data.data.map(item => ({
                    value: item[valueKey], // Menggunakan valueKey
                    label: item[labelKey], // Menggunakan labelKey
                    originalData: item, // Menyimpan data asli jika diperlukan
                })));
            }
            // Jika data adalah objek
            else if (typeof data.data === 'object') {
                const optionsArray = Object.entries(data.data).map(([key, value]) => ({
                    value: key, // Menggunakan key sebagai value
                    label: value, // Menggunakan value sebagai label
                    originalData: { id: key, name: value }, // Menyimpan data asli jika diperlukan
                }));
                setOptions(optionsArray);
            }
        }
    }, [data, reload]);

    return {
        options,
        loading
    };
};

