import React from 'react';
import { Breadcrumb, Button } from 'react-bootstrap';
import { IconChevronRight } from '@tabler/icons-react'; // Menggunakan ikon dari tabler
import './breadcrumb-with-title.css'; // Import file css yang berisi styling breadcrumb

const BreadcrumbWithTitle = ({ breadcrumbs, title, extras }) => {
    return (
        <>
            {/* Breadcrumb */}
            <div className="breadcrumb-container bg-light d-flex justify-content-between align-items-center p-3 bg-body" style={{ boxShadow: '0 1px 3px 0 rgba(0,0,0,.1), 0 1px 2px -1px rgba(0,0,0,.1)' }}>
                <div className='d-flex justify-content-between align-items-center'>
                    {/* Title Dashboard dengan border-right */}
                    <h2 className="m-0 me-3 fs-3 border-end pe-3">{title}</h2>

                    {/* Breadcrumb */}
                    <Breadcrumb className="m-0 d-flex align-items-center">
                        {breadcrumbs.map((breadcrumb, index) => (
                            <React.Fragment key={index}>
                                <Breadcrumb.Item href={breadcrumb.href} active={breadcrumb.active}>
                                    {breadcrumb.label}
                                </Breadcrumb.Item>

                                {/* Tambahkan ikon panah, kecuali pada item terakhir */}
                                {index < breadcrumbs.length - 1 && (
                                    <IconChevronRight size={16} className="mx-2" />
                                )}
                            </React.Fragment>
                        ))}
                    </Breadcrumb>
                </div>
                <div className="d-flex">
                    {extras && extras.map((extra, index) => (
                        <>{ extra }</>
                    ))}
                </div>
            </div>
        </>
    );
};

export default BreadcrumbWithTitle;
