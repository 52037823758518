import React from "react";
import LayoutDashboard from "layouts/layout-dashboard-collapse";
import { Dropdown } from 'react-bootstrap';
import { useDeleteData, useFetchData } from "hooks/useApiHooks";
import Suspense from "components/suspense/suspense";
import { useLoading } from "contexts/LoadingContext";
import FullLoader from "components/loader/full-loader";
import { useCanvasHandler } from "hooks/useCanvasHandler";
import { useDeleteHandler } from "hooks/useDeleteHandler";
import CreateCanvas from "pages/property-settings/property-management/components/create-property-page";
import { useReload } from "contexts/ReloadContext";

const PropertyManagementPage = () => {
    const { reload } = useReload();
    const { data: fetchData } = useFetchData('/property', reload);
    const { deleteData, loading: deletingData } = useDeleteData('/property', reload);

    const { isLoading } = useLoading();

    const handleDelete = useDeleteHandler(deleteData, `Property telah dihapus`, `Gagal menghapus property`);
    const { handleSuccess, handleError, showCanvasHandler } = useCanvasHandler();

    // Fungsi untuk menampilkan modal create
    const handleCreate = (e) => {
        e.preventDefault();
        showCanvasHandler('Create Property',
            <CreateCanvas
                onSuccess={handleSuccess}
                onError={handleError}
            />
        );
    };

    const handleUpdate = (e, data) => {
        e.preventDefault();
        showCanvasHandler('Update Property',
            <CreateCanvas
                onSuccess={handleSuccess}
                onError={handleError}
                initialData={data}
            />
        );
    }

    return (
        <Suspense loading={isLoading} loader={<FullLoader />}>
            <LayoutDashboard>
                <div className="container-fluid mt-5">
                    <div className="table-container">
                        <div className="search-container mb-3">
                            {/* Search input */}
                            <div className="input-icon">
                                <input type="text" className="form-control" placeholder="Search..." />
                                <span className="input-icon-addon">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="icon icon-tabler icon-tabler-search"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                        <circle cx="10" cy="10" r="7" />
                                        <line x1="21" y1="21" x2="15" y2="15" />
                                    </svg>
                                </span>
                            </div>

                            {/* Create button */}
                            <button className="btn btn-primary" onClick={handleCreate}>Create</button>
                        </div>

                        {/* Table */}
                        <div className="table">
                            <table className="table table-hover table-vcenter">
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Location</th>
                                        <th>Users</th>
                                        <th className="text-end">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fetchData?.data?.map((property, index) => (
                                        <React.Fragment key={index}>
                                            <tr>
                                                <td>
                                                    {property.name}
                                                </td>
                                                <td>{property.street_address}</td>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <span className="avatar rounded-circle">SA</span>
                                                    </div>
                                                </td>
                                                <td className="text-end">
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="default" id="dropdownMenuButton1">
                                                            Actions
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item href="#" onClick={(e) => handleUpdate(e, property)}>Edit</Dropdown.Item>
                                                            <Dropdown.Item href="#">Select User</Dropdown.Item>
                                                            <Dropdown.Item href="#" onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleDelete(property.id)
                                                            }}>
                                                                Remove
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        {/* Pagination */}
                        <div className="pagination-container">
                            <nav aria-label="Page navigation">
                                <ul className="pagination">
                                    <li className="page-item active">
                                        <a className="page-link bg-primary" href="#">
                                            1
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </LayoutDashboard>
        </Suspense>
    );
};

export default PropertyManagementPage;
