import React, { createContext, useContext, useState, useCallback } from 'react';

// Membuat context untuk loading
const LoadingContext = createContext();

// Provider untuk context loading
export const LoadingProvider = ({ children }) => {
    const [loadingCount, setLoadingCount] = useState(0);
    const [loadingType, setLoadingType] = useState(null); // Menyimpan tipe loading (full atau component)

    // Memulai loading dengan tipe tertentu, default-nya adalah 'full'
    const startLoading = useCallback((type = 'full') => {
        setLoadingCount(prevCount => prevCount + 1);
        setLoadingType(type);  // Mengatur tipe loading, default-nya adalah 'full'
    }, []);

    // Menghentikan loading
    const stopLoading = useCallback(() => {
        setLoadingCount(prevCount => Math.max(prevCount - 1, 0));
    }, []);

    const isLoading = loadingCount > 0;

    return (
        <LoadingContext.Provider value={{ isLoading, loadingType, startLoading, stopLoading }}>
            {children}
        </LoadingContext.Provider>
    );
};

// Hook untuk mengakses context loading
export const useLoading = () => {
    const context = useContext(LoadingContext);
    if (!context) {
        throw new Error('useLoading must be used within a LoadingProvider');
    }
    return context;
};