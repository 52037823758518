import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useFormChangeHandler } from "hooks/useFormChangeHandler";
import { useSubmitHandler } from "hooks/useSubmitHandler";
import { useCreateData, useUpdateData } from "hooks/useApiHooks";
import PermissionPage from "./permission-page";

const SyncPermissionsPage = ({ initialData, onSuccess, onError }) => {
    const CREATE_URL = '/role';

    // State untuk menyimpan form data. Jika initialData tersedia, isi form untuk update
    const [formData, setFormData] = useState({
        name: initialData?.display_name || '',
    });
    const [permissions, setPermissions] = useState([]);

    // API hook untuk update & sync permissions
    const { updateData: updateData } = useUpdateData(`${CREATE_URL}/${initialData?.id}`);
    const { createData: updatePermissions } = useCreateData(`/role/${initialData?.id}/sync-permissions`);

    // Gunakan hook untuk menangani perubahan form
    const handleChange = useFormChangeHandler(setFormData);

    // Gunakan hook untuk menangani pengiriman form
    const { handleSubmit, isSubmitting, formError } = useSubmitHandler(
        updateData,
        onSuccess,
        onError
    );

    const { handleSubmit: handlePermissionChange } = useSubmitHandler(updatePermissions, onSuccess, onError);

    const handleBeforeSubmit = (e) => {
        e.preventDefault();
        const { is_permission_edit, ...rest } = formData;

        handleSubmit(rest, e);

        if (is_permission_edit) {
            const mapData = { permissions: permissions }
            handlePermissionChange(mapData, e);
        }
    };

    useEffect(() => {
        // Jika data produk berubah (untuk update), isi ulang form data
        if (initialData) {
            setFormData({
                name: initialData.display_name || '',
            });
        }
    }, [initialData]);

    return (
        <form onSubmit={handleBeforeSubmit} style={{ height: '100%' }}>
            <div className="container-fluid mt-4">
                <div className="card mb-4">
                    <div className="card-header">
                        <h5 className="mb-0 fs-4">Hak Akses</h5>
                    </div>
                    <div className="card-body">
                        <div className="action-sheet-content flex-grow-1">
                            {/* Input untuk nama */}
                            <div className="form-group mb-3">
                                <label htmlFor="name" className="form-label">Nama</label>
                                <input
                                    type="text"
                                    className={clsx("form-control", formError?.name && 'is-invalid')}
                                    id="name"
                                    placeholder="Masukkan nama"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange} // Menggunakan handleChange untuk input teks
                                    disabled={isSubmitting}
                                />
                                {formError?.phone && <div className="invalid-feedback">{formError.phone}</div>}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-check form-check-inline">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        name="is_permission_edit"
                        onChange={handleChange}
                    />
                    <label className="form-check-label">Edit Akses User</label>
                </div>

                {/* Component untuk menampilkan permission */}
                <PermissionPage initialData={initialData?.permissions} isDisabled={formData.is_permission_edit} handlePermissionChange={setPermissions} permissions={permissions} />

                <div className="form-group mt-auto d-grid">
                    <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting}>
                        Update Data
                    </button>
                </div>
            </div>
        </form>
    );
};

export default SyncPermissionsPage;