import React from 'react';
import { URLS } from 'utils/constants';
import UserPage from 'pages/settings/users/user-page';
import RolesPage from 'pages/settings/roles/roles-page';
import PermissionsPage from 'pages/settings/permissions/permissions-page';

export const userSettingsRouter = [
    {
        path: URLS.DASHBOARD_ADMIN_SETTINGS_USER_MANAGEMENT,
        element: <UserPage />
    },
    {
        path: URLS.DASHBOARD_ADMIN_SETTINGS_ROLES,
        element: <RolesPage />,
    },
    {
        path: URLS.DASHBOARD_ADMIN_SETTINGS_PERMISSIONS,
        element: <PermissionsPage />,
    }
    // {
    //     path: URLS.DASHBOARD_ADMIN_USER_SETTINGS_ROLE,
    //     element: <RolesPage />,
    // },
    // {
    //     path: URLS.DASHBOARD_ADMIN_USER_SETTINGS_PERMISSION,
    //     element: <PermissionsPage />,
    // }
];