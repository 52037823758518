import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useFormChangeHandler } from "hooks/useFormChangeHandler";
import { useSubmitHandler } from "hooks/useSubmitHandler";
import { useCreateData, useUpdateData } from "hooks/useApiHooks";
import CurrencyInput from "components/form/currency-input";

const CreateLinkRatePage = ({ onSuccess, onError, ratePlanId }) => {
    const API_URL = '/link-rate-plan';

    // State untuk menyimpan form data
    const [formData, setFormData] = useState({
        rate_plan_id: ratePlanId || '',
        name: '',
        description: '',
        max_night: '2',
        min_night: '1',
        guest_include: '1',
        guest_max: '2',
        extra_adult: '1',
        extra_child: '1',
        extra_adult_rate: '',
        extra_child_rate: '',
        specific_rule: '',
        specific_amount_type: '',
        specific_value: ''
    });

    const [selectedType, setSelectedType] = useState('');

    // API hook untuk create dan update
    const { createData, loading: creatingProduct } = useCreateData(API_URL);

    // Gunakan hook untuk menangani perubahan form
    const handleChange = useFormChangeHandler(setFormData);

    // Gunakan hook untuk menangani pengiriman form
    const { handleSubmit, isSubmitting, formError } = useSubmitHandler(
        createData,
        onSuccess,
        onError
    );

    return (
        <form onSubmit={(e) => handleSubmit(formData, e)} className="d-flex flex-column" style={{ height: '100%' }}>
            <div className="action-sheet-content flex-grow-1">
                {/* Name */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="name" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Name: </label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className={clsx("form-control djitu-form-control rounded-1", formError?.name && 'is-invalid')}
                            id="name"
                            placeholder="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                        />
                        {formError?.name && <div className="invalid-feedback">{formError.name}</div>}
                    </div>
                </div>

                {/* Description */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="description" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Description: </label>
                    <div className="col-sm-8">
                        <textarea
                            className={clsx("form-control djitu-form-control rounded-1", formError?.description && 'is-invalid')}
                            id="description"
                            placeholder="Description"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '60px' }}
                        />
                        {formError?.description && <div className="invalid-feedback">{formError.description}</div>}
                    </div>
                </div>

                <h3 className="mt-6 border-bottom fw-normal">Link Rate Settings</h3>
                {/* Min Night */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="min_night" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Min Night: </label>
                    <div className="col-sm-8">
                        <input
                            type="number"
                            className={clsx("form-control djitu-form-control rounded-1", formError?.min_night && 'is-invalid')}
                            id="min_night"
                            placeholder="Min Night"
                            name="min_night"
                            value={formData.min_night}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                            onWheel={(e) => e.target.blur()}
                        />
                        {formError?.min_night && <div className="invalid-feedback">{formError.min_night}</div>}
                    </div>
                </div>

                {/* Max Night */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="max_night" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Max Night: </label>
                    <div className="col-sm-8">
                        <input
                            type="number"
                            className={clsx("form-control djitu-form-control rounded-1", formError?.max_night && 'is-invalid')}
                            id="max_night"
                            placeholder="Max Night"
                            name="max_night"
                            value={formData.max_night}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                            onWheel={(e) => e.target.blur()}
                        />
                        {formError?.max_night && <div className="invalid-feedback">{formError.max_night}</div>}
                    </div>
                </div>

                {/* Guest Include */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="guest_include" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Guest Include: </label>
                    <div className="col-sm-8">
                        <input
                            type="number"
                            className={clsx("form-control djitu-form-control rounded-1", formError?.guest_include && 'is-invalid')}
                            id="guest_include"
                            placeholder="Guest Include"
                            name="guest_include"
                            value={formData.guest_include}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                            onWheel={(e) => e.target.blur()}
                        />
                        {formError?.guest_include && <div className="invalid-feedback">{formError.guest_include}</div>}
                    </div>
                </div>

                {/* Guest Max */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="guest_max" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Guest Max: </label>
                    <div className="col-sm-8">
                        <input
                            type="number"
                            className={clsx("form-control djitu-form-control rounded-1", formError?.guest_max && 'is-invalid')}
                            id="guest_max"
                            placeholder="Guest Max"
                            name="guest_max"
                            value={formData.guest_max}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                            onWheel={(e) => e.target.blur()}
                        />
                        {formError?.guest_max && <div className="invalid-feedback">{formError.guest_max}</div>}
                    </div>
                </div>

                {/* Extra Adult */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="extra_adult" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Extra Adult: </label>
                    <div className="col-sm-8">
                        <input
                            type="number"
                            className={clsx("form-control djitu-form-control rounded-1", formError?.extra_adult && 'is-invalid')}
                            id="extra_adult"
                            placeholder="Extra Adult"
                            name="extra_adult"
                            value={formData.extra_adult}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                            onWheel={(e) => e.target.blur()}
                        />
                        {formError?.extra_adult && <div className="invalid-feedback">{formError.extra_adult}</div>}
                    </div>
                </div>

                {/* Extra Child */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="extra_child" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Extra Child: </label>
                    <div className="col-sm-8">
                        <input
                            type="number"
                            className={clsx("form-control djitu-form-control rounded-1", formError?.extra_child && 'is-invalid')}
                            id="extra_child"
                            placeholder="Extra Child"
                            name="extra_child"
                            value={formData.extra_child}
                            onChange={handleChange}
                            disabled={isSubmitting}
                            style={{ fontSize: '14px', height: '35px' }}
                            onWheel={(e) => e.target.blur()}
                        />
                        {formError?.extra_child && <div className="invalid-feedback">{formError.extra_child}</div>}
                    </div>
                </div>

                <h3 className="mt-6 border-bottom fw-normal">Link Rate Price Settings</h3>
                {/* Extra Adult Rate */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="extra_adult_rate" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Extra Adult Rate: </label>
                    <div className="col-sm-8">
                        <CurrencyInput
                            name="extra_adult_rate"
                            className={clsx("djitu-form-control rounded-1", formError?.extra_adult_rate && 'is-invalid')}
                            value={formData.extra_adult_rate} // Bind value ke formData
                            onChange={(e, rawValue) => handleChange({ target: { name: 'extra_adult_rate', value: rawValue } })} // Simpan rawValue
                            error={formError?.extra_adult_rate} // Menampilkan error jika ada
                            placeholder="Rp. 0" // Placeholder Rupiah default
                        />
                    </div>
                </div>

                {/* Extra Child Rate */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="extra_child_rate" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Extra Child Rate: </label>
                    <div className="col-sm-8">
                        <CurrencyInput
                            name="extra_child_rate"
                            className={clsx("djitu-form-control rounded-1", formError?.extra_child_rate && 'is-invalid')}
                            value={formData.extra_child_rate} // Bind value ke formData
                            onChange={(e, rawValue) => handleChange({ target: { name: 'extra_child_rate', value: rawValue } })} // Simpan rawValue
                            error={formError?.extra_child_rate} // Menampilkan error jika ada
                            placeholder="Rp. 0" // Placeholder Rupiah default
                        />
                    </div>
                </div>

                {/* Specific Rule */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="specific_rule" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Specific Rule: </label>
                    <div className="col-sm-8">
                        <select
                            name="specific_rule"
                            className={clsx("form-select djitu-form-control rounded-1", formError?.specific_rule && 'is-invalid')}
                            id="specificRule"
                            value={formData.specific_rule}
                            onChange={handleChange}
                        >
                            <option value="">Select specific rule</option>
                            <option value="increase">Increase</option>
                            <option value="decrease">Decrease</option>
                        </select>
                        {formError?.specific_rule && <div className="invalid-feedback">{formError.specific_rule}</div>}
                    </div>
                </div>

                {/* Specific Amount Type */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="specific_amount_type" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Specific Amount Type: </label>
                    <div className="col-sm-8">
                        <select
                            name="specific_amount_type"
                            className={clsx("form-select djitu-form-control rounded-1", formError?.specific_rule && 'is-invalid')}
                            id="specificAmountType"
                            value={formData.specific_amount_type}
                            onChange={(e) => {
                                setSelectedType(e.target.value);
                                handleChange(e);
                            }}
                        >
                            <option value="">Select amount type</option>
                            <option value="percent">Percent</option>
                            <option value="amount">Amount</option>
                        </select>
                        {formError?.specific_amount_type && <div className="invalid-feedback">{formError.specific_amount_type}</div>}
                    </div>
                </div>

                {/* Specific Value */}
                <div className="row mb-3 align-items-center">
                    <label htmlFor="specific_value" className="col-sm-4 col-form-label d-flex justify-content-end" style={{ fontSize: '14px' }}>Specific Value: </label>
                    <div className="col-sm-8">
                        {selectedType === 'percent' ? (
                            <>
                                <input
                                    type="number"
                                    className={clsx("form-control djitu-form-control rounded-1", formError?.specific_value && 'is-invalid')}
                                    id="specific_value"
                                    placeholder="%"
                                    name="specific_value"
                                    value={formData.specific_value}
                                    onChange={handleChange}
                                    disabled={isSubmitting}
                                    style={{ fontSize: '14px', height: '35px' }}
                                    onWheel={(e) => e.target.blur()}
                                />
                                {formError?.specific_value && <div className="invalid-feedback">{formError.specific_value}</div>}
                            </>
                        ) : (
                            <CurrencyInput
                                name="specific_value"
                                className={clsx("djitu-form-control rounded-1", formError?.specific_value && 'is-invalid')}
                                value={formData.specific_value} // Bind value ke formData
                                onChange={(e, rawValue) => handleChange({ target: { name: 'specific_value', value: rawValue } })} // Simpan rawValue
                                error={formError?.specific_value} // Menampilkan error jika ada
                                placeholder="Rp. 0" // Placeholder Rupiah default
                            />
                        )}
                    </div>
                </div>
            </div>
            {/* Submit Button */}
            <div className="form-group mt-auto d-grid">
                <button type="submit" className="btn btn-primary btn-block" disabled={isSubmitting}>
                    Create Link Rate Plan
                </button>
            </div>
        </form>
    );
};

export default CreateLinkRatePage;
