import { useLoading } from 'contexts/LoadingContext';
import { useState, useEffect } from 'react';
import { postRequest, patchRequest, deleteRequest, getRequest } from 'services/api';

// Hook untuk Fetch data (GET)
export const useFetchData = (url, reload, loadingType = 'full') => {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const { startLoading, stopLoading } = useLoading(); // Menggunakan context

    useEffect(() => {
        const fetchData = async () => {
            startLoading(loadingType); // Mulai loading
            try {
                const result = await getRequest(url);
                setData(result);  // Sesuaikan dengan struktur data yang dikembalikan API
            } catch (error) {
                setError(error.message);
            } finally {
                stopLoading(); // Stop loading setelah fetch selesai
            }
        };

        fetchData();
    }, [url, reload, startLoading, stopLoading]); // Menggunakan JSON.stringify agar tidak terjadi infinite loop

    return { data, error, loading: useLoading().isLoading };
};



// Hook untuk Create (POST)
export const useCreateData = (url) => {
    const [loading, setLoading] = useState(false);

    const createData = async (data) => {
        setLoading(true);
        try {
            const result = await postRequest(url, data);
            return { success: true, data: result };
        } catch (err) {
            const errorResponse = {
                code: err.code || null,
                message: err.errors || 'An error occurred',
            };
            return { success: false, errorResponse };
        } finally {
            setLoading(false);
        }
    };

    return { createData, loading };
};

// Hook untuk Update (PATCH)
export const useUpdateData = (url) => {
    const [loading, setLoading] = useState(false);

    const updateData = async (data) => {
        setLoading(true);
        try {
            const result = await patchRequest(url, data);
            return { success: true, data: result };
        } catch (err) {
            const errorResponse = {
                code: err.code || null,
                message: err.errors || 'An error occurred',
            };
            return { success: false, errorResponse };
        } finally {
            setLoading(false);
        }
    };

    return { updateData, loading };
};

// Hook untuk Delete (DELETE)
export const useDeleteData = (url) => {
    const { startLoading, stopLoading } = useLoading(); // Menggunakan context

    const deleteData = async (id) => {
        startLoading('full'); // Mulai loading
        try {
            const result = await deleteRequest(`${url}/${id}`);
            return { success: true, data: result };
        } catch (err) {
            const errorResponse = {
                code: err.code || null,
                message: err.errors || 'An error occurred',
            };
            return { success: false, errorResponse };
        } finally {
            stopLoading(); // Stop loading setelah fetch selesai
        }
    };

    return { deleteData, loading: useLoading().isLoading };
};